import { gql } from '@apollo/client'

export default gql`
  mutation upload($file: Upload!) {
    upload(file: $file) {
      data {
        id
        attributes {
          url
        }
      }
    }
  }
`
