import React from 'react'

import { Container, ExternalLink, Logo, Text } from 'ui'
import {
  EMAIL_ADDRESS,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  PHONE_NUMBER,
  YOUTUBE_LINK
} from 'constants/global'

import * as S from './styled'

const Footer = () => (
  <S.Wrapper>
    <Container>
      <Logo />

      <S.Info>
        <Text>
          VIAC - Inštitút pre podporu a rozvoj mládeže
          <br />
          Sídlo: Ústie nad Priehradou 41, 028 01 Trstená
          <br />
          Kancelárie: Železničiarov 253/1, 028 01 Trstená,
          <br />
          Štefánikova 2, 058 01, Poprad
          <br />
          <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
          <br />
          <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
        </Text>

        <Text>
          IČO: 42 217 202
          <br />
          DIČ: 2023 297 287
          <br />
          IBAN: SK61 7500 0000 0040 1397 5251
          <br />
          BIC (SWIFT): CEKOSKBX
          <br />
          <ExternalLink to={FACEBOOK_LINK}>
            <img src="/assets/icons/facebook.svg" alt="facebook" />
          </ExternalLink>
          <ExternalLink to={INSTAGRAM_LINK}>
            <img src="/assets/icons/instagram.svg" alt="instagram" />
          </ExternalLink>
          <ExternalLink to={YOUTUBE_LINK}>
            <img src="/assets/icons/youtube.svg" alt="youtube" />
          </ExternalLink>
        </Text>
      </S.Info>
    </Container>
  </S.Wrapper>
)

export default Footer
