import { useContext } from 'react'

import { AuthContext, AuthContextValue } from 'contexts'

type ContextMap = {
  auth: AuthContextValue
}

export default (name: keyof ContextMap): AuthContextValue => {
  return {
    auth: useContext<AuthContextValue>(AuthContext)
  }[name]
}
