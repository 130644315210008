import styled from 'styled-components'

interface IWrapper {
  isOpened?: boolean
}

export const Menu = styled.div`
  position: absolute;
  width: 100%;
  top: 85%;

  display: none;
  flex-direction: column;
  padding: ${({ theme }) => theme.sizes.s};

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.sizes.xxs};
  box-shadow: ${({ theme }) => theme.shadows.nav};

  button {
    position: relative;

    text-align: left;
    padding: ${({ theme }) => theme.sizes.s};
    ${({ theme }) => theme.fonts.bold};

    transition: background-color 300ms ease;
    border-radius: ${({ theme }) => theme.sizes.xxs};
    background-color: ${({ theme }) => theme.colors.white};

    &:hover {
      background-color: ${({ theme }) => theme.colors.footer};
    }
  }
`

export const Content = styled.div`
  position: relative;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Wrapper = styled.div<IWrapper>`
  display: inline-flex;
  text-align: left;
  cursor: pointer;
  position: relative;
  padding-right: 24px;

  &:hover > ${Menu} {
    display: flex;
  }

  /* &:hover > ${Content} {
    strong {
      background-color: ${({ theme }) => theme.colors.background};
      position: absolute;
      white-space: pre-line;
      bottom: 8px;
    }
  } */

  &:hover::after {
    transform: scale(-1, -1);
  }

  img {
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.colors.salmon};
    margin-right: ${({ theme }) => theme.sizes.xs};
    object-fit: cover;
    object-position: center;
  }

  &::after {
    content: '';
    background-image: url('/assets/icons/arrow-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    width: 8px;
    height: 6px;

    position: absolute;
    top: 22px;
    right: 0;

    transition: transform 150ms;
  }
`
