import React from 'react'
import { Form, Formik } from 'formik'
import { NavLink } from 'react-router-dom'
import { omit } from 'lodash'

import { Button, Input, Points, Text } from 'ui'
import { useForm, useToggle } from 'hooks'
import validationSchema from 'schemas/signup'

import { REGISTER } from 'apollo/mutations'
import { UsersPermissionsLoginPayload } from 'types/models/user'
import { UsersPermissionsRegisterInput } from 'types/inputs'

import * as S from './styled'
import { Success } from '../sections'

type TFields = UsersPermissionsRegisterInput & {
  consent: boolean
}

const initialValues: TFields = {
  username: '',
  name: '',
  surname: '',
  birthday: '',
  phone: '',
  email: '',
  city: '',
  street: '',
  houseNumber: '',
  postcode: '',
  password: '',
  bio: '',
  consent: false,
}

type TMutation = TData<UsersPermissionsLoginPayload, 'register'>

const SignUp = () => {
  const { visible, show } = useToggle()

  const { onSubmit, loading, error } = useForm<
    TMutation,
    { input: UsersPermissionsRegisterInput }
  >(REGISTER, show)

  if (visible)
    return (
      <Success
        title="Registrácia"
        text={
          <>
            Úspešne ste sa zaregistrovali!
            <br />
            Po overení e-mailu môžete pokračovať do Vášho účtu.
          </>
        }
      />
    )

  return (
    <>
      <S.Wrapper>
        <Text as="h1" variant="heading2" color="salmon" mb="s" align="center">
          Registrácia
        </Text>

        <Formik
          {...{ initialValues, validationSchema }}
          onSubmit={(values, formikHelpers) =>
            onSubmit(
              {
                input: { ...omit(values, ['consent']), username: values.email },
              },
              formikHelpers
            )
          }
          validateOnChange
        >
          {({ errors, touched }) => (
            <Form>
              <S.Half>
                <Input
                  type="text"
                  name="name"
                  label="Meno *"
                  disabled={loading}
                  isError={touched.name && errors.name}
                  required
                />
                <Input
                  type="text"
                  name="surname"
                  label="Priezvisko *"
                  disabled={loading}
                  isError={touched.surname && errors.surname}
                  required
                />
              </S.Half>

              <S.Half>
                <Input
                  type="date"
                  name="birthday"
                  label="Dátum narodenia *"
                  disabled={loading}
                  isError={touched.birthday && errors.birthday}
                  required
                />
                <Input
                  type="text"
                  name="phone"
                  label="Telefónne číslo *"
                  disabled={loading}
                  isError={touched.phone && errors.phone}
                  required
                />
              </S.Half>

              <Input
                type="email"
                name="email"
                label="E-mail *"
                disabled={loading}
                isError={touched.email && errors.email}
                required
              />

              <S.Half>
                <Input
                  type="text"
                  name="city"
                  label="Mesto *"
                  disabled={loading}
                  isError={touched.city && errors.city}
                  required
                />
                <Input
                  type="text"
                  name="street"
                  label="Ulica *"
                  disabled={loading}
                  isError={touched.street && errors.street}
                  required
                />
              </S.Half>

              <S.Half>
                <Input
                  type="string"
                  name="houseNumber"
                  label="Popisné číslo *"
                  disabled={loading}
                  isError={touched.houseNumber && errors.houseNumber}
                  required
                />
                <Input
                  type="string"
                  name="postcode"
                  label="PSČ *"
                  disabled={loading}
                  isError={touched.postcode && errors.postcode}
                  required
                />
              </S.Half>

              <Input
                type="password"
                name="password"
                label="Heslo *"
                disabled={loading}
                isError={touched.password && errors.password}
                required
              />

              <Input
                as="textarea"
                name="bio"
                label="Niečo o sebe"
                disabled={loading}
                isError={touched.bio && errors.bio}
              />

              <Input
                type="checkbox"
                name="consent"
                label={
                  <>
                    Súhlasím s{' '}
                    <NavLink to="/terms-and-conditions">
                      Všeobecné podmienky a ochrana osobných údajov
                    </NavLink>
                  </>
                }
                disabled={loading}
                isError={touched.consent && errors.consent}
                required
              />

              {!!error && (
                <Text as="small" variant="error">
                  {error}
                </Text>
              )}

              <Button type="submit" disabled={loading} isLoading={loading}>
                Zaregistrovať sa
              </Button>
            </Form>
          )}
        </Formik>

        <Points
          points={10}
          variant="bold"
          color="darkBlue"
          left="Za registráciu získate"
        />
      </S.Wrapper>

      <small>
        Vlastníte účet? <NavLink to="/prihlasenie">Prihláste sa!</NavLink>
      </small>
    </>
  )
}

export default React.memo(SignUp)
