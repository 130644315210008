import React from 'react'
import { Outlet } from 'react-router-dom'

import { Logo } from 'ui'

import * as S from './styled'

const Auth = () => (
  <S.Wrapper>
    <Logo />

    <Outlet />
  </S.Wrapper>
)

export default Auth
