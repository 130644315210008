import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'

import {
  Challenge,
  Contact,
  Description,
  Logos,
  News,
  Newsletter,
  References,
  // Winner,
} from './sections'
import { Background } from 'components'

import { GET_HOMEPAGE } from 'apollo/queries'
import {
  HomepageEntityResponse,
  HomepageEventsEntityResponse,
} from 'types/models/homepage'

type TQuery = TData<HomepageEntityResponse, 'homepage'> &
  TData<HomepageEventsEntityResponse, 'events'>

const Home = () => {
  const { data, loading } = useQuery<TQuery>(GET_HOMEPAGE, {
    variables: { now: DateTime.now().toISODate() },
  })

  const events = useMemo(() => data?.events?.data, [data])
  const content = useMemo(() => data?.homepage?.data?.attributes, [data])

  return (
    <>
      <Background slides={content?.carousel} loading={loading} />

      <Contact />

      <News collection={content?.events?.data} mapEvents={events} />

      <Challenge entity={content?.weeklyChallenge?.data} />

      {/* <Winner component={content?.lastWinner} /> */}

      <References collection={content?.references?.data} />

      <Logos />

      <Description />

      <Newsletter />
    </>
  )
}

export default React.memo(Home)
