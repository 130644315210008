import styled, { DefaultTheme } from 'styled-components'

interface IWrapper {
  variant?: keyof DefaultTheme['fonts']
}

export const Wrapper = styled.span<IWrapper>`
  ${({ theme, variant }) => theme.fonts[variant || 'tag']};
  font-weight: 700;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.white};

  margin-right: ${({ theme }) => theme.sizes.s};
  white-space: nowrap;

  &:before {
    content: '';
    display: inline-block;

    width: 10px;
    height: 10px;

    background-color: ${({ theme }) => theme.colors.salmon};
    border-radius: 50%;

    margin-right: 10px;
  }
`
