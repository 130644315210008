import styled from 'styled-components'

export const Tabs = styled.nav`
  background-color: ${({ theme }) => theme.colors.blue};

  & > div {
    @media (max-width: 575px) {
      display: flex;
    }
  }

  a {
    display: inline-flex;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;

    color: ${({ theme }) => theme.colors.salmon};

    padding-block: ${({ theme }) => theme.sizes.m};
    padding-inline: ${({ theme }) => theme.sizes.xxxl};

    border-top-left-radius: ${({ theme }) => theme.sizes.m};
    border-top-right-radius: ${({ theme }) => theme.sizes.m};

    @media (max-width: 575px) {
      font-size: 15px;
      line-height: 19px;
      padding-block: ${({ theme }) => theme.sizes.s};
      padding-inline: ${({ theme }) => theme.sizes.xxl};
    }

    @media (max-width: 510px) {
      padding-inline: ${({ theme }) => theme.sizes.xl};
    }
  }

  .active {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.blue};
  }
`
