import React, { useCallback } from 'react'

import { Image } from 'components'
import { ExternalLink, Section, Text } from 'ui'
import { UploadFileEntity } from 'types/models/file'

import * as S from './styled'

type TProps = {
  collection?: UploadFileEntity[]
}

const Cooperations = ({ collection }: TProps) => {
  const renderCooperations = useCallback(
    ({ id, attributes: item }: UploadFileEntity) =>
      !item ? null : (
        <ExternalLink
          key={`cooperation-${id}-${item.url}`}
          to={item.caption || '/'}
        >
          <Image
            src={item.url}
            alt={`cooperation ${item.alternativeText}`}
            width={200}
            height={180}
          />
        </ExternalLink>
      ),
    [collection]
  )

  return (
    <Section hideIf={!collection?.length} id="#spolupracovali-s-nami">
      <Text as="h2" variant="heading2" color="salmon" mb="xxl" uppercase>
        Spolupracovali s nami
      </Text>

      <S.Wrapper>{collection?.map(renderCooperations)}</S.Wrapper>
    </Section>
  )
}

export default React.memo(Cooperations)
