import styled from 'styled-components'

export const Winner = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-inline: 6%;

  &::before {
    content: '';
    background: url('/assets/icons/crown.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    width: 52px;
    height: 33px;

    top: 0;
    left: calc(50% - 26px);
    transform: translateY(3px);
  }

  img {
    margin-bottom: ${({ theme }) => theme.sizes.m};
  }
`
