import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { To, useNavigate } from 'react-router'
import Skeleton from 'react-loading-skeleton'

import Points from '../Points'
import Text from '../Text'
import { useContext } from 'hooks'
import { sumPoints } from 'utils/functions'
import { USER_PLACEHOLDER } from 'constants/global'

import { GET_ME } from 'apollo/queries'
import { UsersPermissionsUserEntityResponse } from 'types/models/user'

import * as S from './styled'

type TProps = { hide?: () => void }

type TQuery = TData<UsersPermissionsUserEntityResponse, 'getMe'>

const Profile = ({ hide }: TProps) => {
  const navigate = useNavigate()
  const { onLogout } = useContext('auth')

  const { data, loading } = useQuery<TQuery>(GET_ME)

  const content = useMemo(() => data?.getMe?.data?.attributes, [data])
  const totalPoints = useMemo(() => sumPoints(content?.points), [content])

  const to = useCallback((to: To) => () => navigate(to), [navigate])

  const handleLogout = useCallback(() => {
    onLogout().then(hide).catch(hide)
  }, [onLogout, hide])

  return (
    <S.Wrapper key={`user-${data?.getMe?.data?.id}`}>
      {loading ? (
        <Skeleton circle height={46} width={46} style={{ marginRight: 12 }} />
      ) : (
        <img
          src={content?.avatar?.data?.attributes?.url || USER_PLACEHOLDER}
          alt="your avatar"
          width={46}
          height={46}
        />
      )}

      <S.Content>
        {loading ? (
          <Skeleton height={12} width="50%" />
        ) : (
          <Points points={totalPoints} hideText />
        )}

        <Text as="strong" variant="bold" color="grey">
          {loading ? (
            <Skeleton width={100} />
          ) : (
            <>
              {content?.name} {content?.surname}
            </>
          )}
        </Text>
      </S.Content>

      <S.Menu>
        <button onClick={to('/profil')}>Profil</button>
        <button onClick={handleLogout}>Odhlásiť sa</button>
      </S.Menu>
    </S.Wrapper>
  )
}

export default React.memo(Profile)
