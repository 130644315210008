import styled from 'styled-components'

interface IWrapper {
  isError?: boolean
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;

  label {
    margin-bottom: ${({ theme }) => theme.sizes.xs};
  }

  small {
    margin-top: ${({ theme }) => theme.sizes.xxxs};
  }

  select {
    border: 1px solid
      ${({ theme, isError }) => theme.colors?.[isError ? 'red' : 'lightSalmon']};
  }

  select,
  option {
    cursor: pointer;
  }
`
