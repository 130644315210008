import React, { useCallback, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'
import { To, useNavigate } from 'react-router'

import Image from '../../Image'
import { Button, Stars, Text } from 'ui'
import { UploadFileEntity } from 'types/models/file'

import * as S from './styled'

type TProps = {
  image?: UploadFileEntity
  name?: string
  position?: string
  description?: string
  rating?: number
  color?: keyof DefaultTheme['colors']
  path?: To
}

const CarouselItem = ({
  image,
  name,
  position,
  description,
  rating,
  color = 'grey',
  path
}: TProps) => {
  const navigate = useNavigate()

  const toDetail = useCallback(() => navigate(path || ''), [navigate, path])

  const isSecondary = useMemo(() => !!path, [path])

  return (
    <S.Wrapper {...{ isSecondary }}>
      <Image
        src={
          image?.attributes?.formats?.xlarge?.url ||
          image?.attributes?.formats?.large?.url ||
          image?.attributes?.url ||
          ''
        }
        alt={`${name} image`}
        isCircle={!isSecondary}
        width={isSecondary ? 300 : 188}
        height={188}
      />

      {!!rating && <Stars value={rating} />}

      <div>
        <Text
          as="h3"
          variant="subheading"
          align="center"
          weight={700}
          uppercase
          {...(!isSecondary && { mb: 'xxxs' })}
          {...{ color }}
        >
          {name}
        </Text>

        {!!position && (
          <Text as="h4" color="salmon" align="center" weight={700} uppercase>
            {position}
          </Text>
        )}
      </div>

      <Text variant="paragraph" align="justify" {...{ color }}>
        {description}
      </Text>

      {isSecondary && <Button onClick={toDetail}>Zistiť viac</Button>}
    </S.Wrapper>
  )
}

export default React.memo(CarouselItem)
