import React, { useCallback } from 'react'
import { DateTime } from 'luxon'

import { Carousel, Section, Text } from 'ui'
import { TermEntity } from 'types/models/term'

import * as S from './styled'

type TProps = {
  collection?: TermEntity[]
}

const Terms = ({ collection }: TProps) => {
  const renderTerms = useCallback(
    ({ id, attributes: item }: TermEntity) => (
      <S.Wrapper key={`${id}-term`}>
        <Text as="span" variant="link">
          Od
        </Text>
        <Text mb="xxl">
          {item?.beginning &&
            DateTime.fromISO(item.beginning).toFormat('dd.MM.yyyy')}
        </Text>

        <Text as="span" variant="link">
          Do
        </Text>
        <Text>
          {item?.end && DateTime.fromISO(item.end).toFormat('dd.MM.yyyy')}
        </Text>
      </S.Wrapper>
    ),
    [collection]
  )

  return (
    <Section hideIf={!collection?.length}>
      <Text as="h2" variant="heading2" color="salmon" mb="xxl" uppercase>
        Aktuálne termíny
      </Text>

      <Carousel isCondensed>{collection?.map(renderTerms)}</Carousel>
    </Section>
  )
}

export default React.memo(Terms)
