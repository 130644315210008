import styled, { DefaultTheme } from 'styled-components'

interface IWrapper {
  variant?: keyof DefaultTheme['fonts']
  color?: keyof DefaultTheme['colors']
  mb?: keyof DefaultTheme['sizes']
  align?: 'left' | 'right' | 'center' | 'justify'
  uppercase?: boolean
  size?: number
  weight?: number
  height?: number
}

export const Wrapper = styled.p<IWrapper>`
  ${({ theme, variant }) => variant && theme.fonts[variant]};
  ${({ theme, color }) => color && `color: ${theme.colors[color]}`};
  ${({ theme, mb }) => !!mb && `margin-bottom: ${theme.sizes[mb]}`};
  ${({ align }) => align && `text-align: ${align};`}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ size }) => !!size && `font-size: ${size}px;`};
  ${({ height }) => !!height && `line-height: ${height}px`};
  ${({ weight }) => !!weight && `font-weight: ${weight}`};
`
