import styled from 'styled-components'

interface IWrapper {
  fullWidth?: boolean
  isCircle?: boolean
  bordered?: boolean
  maxWidth?: number
}

export const Wrapper = styled.img<IWrapper>`
  background-color: ${({ theme }) => theme.colors.salmon};
  border-radius: ${({ theme, isCircle }) =>
    isCircle ? '50%' : theme.sizes.xxs};

  ${({ theme, bordered }) => !bordered && `box-shadow: ${theme.shadows.modal};`}
  ${({ bordered }) => bordered && 'padding: clamp(3px, 6%, 10px);'}

  object-fit: cover;
  object-position: center;

  ${({ isCircle }) => isCircle && 'aspect-ratio: 1/1;'}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`
