import styled from 'styled-components'

export const Wrapper = styled.article`
  img {
    margin-inline: auto;
  }

  button {
    align-self: center;
  }
`
