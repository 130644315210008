import { THeader } from 'types/global'

export default {
  'pre-dobrovolnikov': {
    title: 'Chcem byť dobrovoľníkom',

    subcategories: {
      'dobrovolnicke-prilezitosti': {
        title: 'Dobrovoľnícke príležitosti',
        text: 'Pozri sa, kde všade môžeš dobrovoľníčiť.'
      },
      'dlhodobe-dobrovolnicke-programy': {
        title: 'Dlhodobé dobrovoľnícke programy',
        text: 'Chceš pomáhať dlhodobejšie?'
      },
      'podujatia-pre-teba': {
        title: 'Podujatia pre teba',
        text: 'Workshopy, školenia, víkendovky...'
      },
      'zapoj-sa-do-nasej-hry': {
        title: 'Naštartuj svoj DOBRO-meter!',
        text: 'Zbieraj body za dobrovoľníčenie či plnenie výziev a získaj odmeny!'
      },
      'programy-na-osobny-rozvoj': {
        title: 'Tvoj osobný rozvoj',
        text: 'Zišiel by sa ti koučing alebo chceš porásť vďaka rôznym rozvojovým programom?'
      },
      'motivacia-pribehy-dobrovolnikov': {
        title: 'Príbehy dobrovoľníkov',
        text: 'Prečítaj si, ako menia svet a dobrovoľníctvo mení ich.'
      }
    }
  },

  'pre-organizacie': {
    title: 'Pracujeme s dobrovoľníkmi',

    subcategories: {
      podujatia: {
        title: 'Podujatia',
        text: 'Školenia, konferencie, sieťovanie, inšpirácia...'
      },
      'standardy-a-znacka-kvality-prace-s-dobrovolnikmi': {
        title: 'Značka kvality práce s dobrovoľníkmi',
        text: 'Poskytneme Vám konzultácie k získavaniu Značky kvality i ku štandardom manažmentu dobrovoľníkov.'
      },
      'poradenstvo-a-koaching': {
        title: 'Poradenstvo a coaching',
        text: 'Máte otázky v súvislosti s prácou s dobrovoľníkmi?'
      },
      'nastroje-na-podporu-prace-s-dobrovolnikmi': {
        title: 'Ako na prácu s dobrovoľníkmi?',
        text: 'Zoznámte sa s aplikáciami Adgar a Bethy!'
      },
      ocenovanie: {
        title: 'Ocenenia pre dobrovoľníkov',
        text: 'Oceňovanie dobrovoľníkov Srdce na dlani.'
      },
      'pre-vasich-dobrovolnikov': {
        title: 'Benefity pre vašich dobrovoľníkov',
        text: 'Ponúkame osobný rozvoj, duchovnú formáciu či budovanie tímu pre Vašich dobrovoľníkov.'
      }
    }
  },

  'centra-ktore-potrebuju-dobrovolnikov': {
    title: 'Hľadáme dobrovoľníkov',

    subcategories: {
      'stan-sa-dobrovolnickou-organizaciou': {
        title: 'Staňte sa dobrovoľníckou organizáciou',
        text: 'Zistite, ako aktívne a dlhodobo pracovať s dobrovoľníkmi.'
      },
      'zapoj-sa-do-nasho-programu': {
        title: 'Zapojte sa cez náš program',
        text: 'Ponúkame vytvorené dobrovoľnícke programy, ktoré môžu pomôcť práve Vám.'
      },
      'podpora-na-mieru': {
        title: 'Podpora na mieru',
        text: 'Nenašli ste v ponuke to pravé? Nájdime spoločne riešenie práve pre Vás.'
      }
    }
  },

  inspiracia: {
    title: 'Inšpiruj sa',

    subcategories: {
      'dobrovolnicke-organizacie': {
        title: 'Dobrovolnícke organizácie',
        text: 'Zisti viac o organizáciách, kde môžeš dobrovoľníčiť.'
      },
      'nastroje-na-podporu': {
        title: 'Užitočné nástroje',
        text: 'Poznáš nástroje, čo ti pomôžu s plánovaním alebo plnením cieľov?'
      },
      clanky: {
        title: 'Prečítaj si',
        text: 'Čo sme zažili, prečo sa zapojiť či ako nájsť to správne miesto, kde môžeš pomáhať?'
      },
      'otestuj-sa': {
        title: 'Otestuj sa',
        text: 'Aký typ dobrovoľníka si?'
      },
      dokumenty: {
        title: 'Dokumenty',
        text: ''
      }
    }
  },

  static: {
    title: 'Kto sme',

    subcategories: {
      'nase-vlastne-programy': {
        title: 'Vlastné programy',
        text: 'Aké z ponúkaných programov prináša zmeňme.to?'
      },
      'nas-tim': {
        title: 'Náš tím',
        text: 'Spoznaj, kto stojí za zmeňme.to'
      },
      'cestou-s-nami': {
        title: 'Prečo to robíme?',
        text: 'Zisti, čomu sa venujeme a aký je náš zámer.'
      },
      'kontaktuj-nas': {
        title: 'Napíšte nám',
        text: ''
      }
    }
  }
} as THeader
