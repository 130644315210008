import React, { useCallback } from 'react'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'

import { UploadFileEntity } from 'types/models/file'

import * as S from './styled'

type TProps = {
  data: UploadFileEntity[]
}

const MAX_IMAGES = 6

const Gallery = ({ data }: TProps) => {
  const { openLightbox } = useLightbox()

  const openPreview = useCallback(
    (idx: number) => () => openLightbox(idx),
    [openLightbox]
  )

  const renderImages = useCallback(
    ({ id, attributes: item }: UploadFileEntity, idx: number) => (
      <S.Image
        key={`gallery-${id}-${item?.url}`}
        isHidden={idx > MAX_IMAGES}
        onClick={openPreview(idx)}
      >
        <img
          src={item?.url}
          alt={`image ${item?.caption}`}
          loading="lazy"
          width={150}
          height={211}
        />
      </S.Image>
    ),
    [openPreview]
  )

  return (
    <S.Wrapper isMore={data.length > MAX_IMAGES}>
      <SRLWrapper options={{ caption: { showCaption: false } }}>
        {data?.map(renderImages)}
      </SRLWrapper>
    </S.Wrapper>
  )
}

export default React.memo(Gallery)
