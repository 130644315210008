import styled from 'styled-components'

export const Wrapper = styled.section`
  z-index: 9999;
  overflow-y: auto;

  position: fixed;
  inset: 0;

  display: flex;
  place-content: center;

  background-color: ${({ theme }) => theme.colors.modal};
  padding: ${({ theme }) => theme.sizes.xxs};
`

export const Modal = styled.article`
  position: relative;
  z-index: 1000;
  margin: auto;

  display: flex;
  flex-direction: column;

  border-radius: ${({ theme }) => theme.sizes.xxs};
  background-color: ${({ theme }) => theme.colors.background};

  width: 100%;
  max-width: 674px;

  padding-inline: ${({ theme }) => theme.sizes.l};
  padding-block: ${({ theme }) => theme.sizes.section};

  & > * + * {
    margin-top: ${({ theme }) => theme.sizes.s};
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: ${({ theme }) => theme.sizes.s};
    }
  }
`

export const Close = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.sizes.l};
  right: ${({ theme }) => theme.sizes.l};
`
