import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { Header, Image } from 'components'
import { Section, Text } from 'ui'
import { ComponentStaticMember } from 'types/models/components'

import { GET_TEAM } from 'apollo/queries'
import { TeamEntityResponse } from 'types/models/team'

import * as S from './styled'
import Skeleton from 'react-loading-skeleton'

type TQuery = TData<TeamEntityResponse, 'team'>

const MemberPlaceholder = () => (
  <>
    <S.Member>
      <Skeleton circle />

      <div>
        <Skeleton height={18} width={100} style={{ marginBottom: 8 }} />
        <Skeleton height={44} width="60%" style={{ marginBottom: 16 }} />
        <Skeleton height={12} count={8} />
        <Skeleton height={12} width="80%" />
      </div>
    </S.Member>

    <S.Member isFlipped>
      <Skeleton circle />

      <div>
        <Skeleton height={18} width={100} style={{ marginBottom: 8 }} />
        <Skeleton height={44} width="60%" style={{ marginBottom: 16 }} />
        <Skeleton height={12} count={8} />
        <Skeleton height={12} width="80%" />
      </div>
    </S.Member>
  </>
)

const Team = () => {
  const { data, loading } = useQuery<TQuery>(GET_TEAM)

  const content = useMemo(() => data?.team?.data?.attributes, [data])

  const renderMembers = useCallback(
    (member: ComponentStaticMember, idx: number) => (
      <S.Member key={`team-member-${member?.id}`} isFlipped={!!(idx % 2)}>
        <Image
          src={
            member?.image?.data?.attributes?.formats?.xlarge?.url ||
            member?.image?.data?.attributes?.formats?.large?.url ||
            member?.image?.data?.attributes?.url ||
            ''
          }
          alt={member?.image?.data?.attributes?.alternativeText}
          isCircle
          width={300}
          height={300}
        />

        <div>
          <Text variant="link" weight={700} mb="xxs" color="blue" uppercase>
            {member?.position}
          </Text>

          <Text as="h2" variant="heading2" color="salmon" mb="s" uppercase>
            {member?.name}
          </Text>

          <Text variant="paragraph" align="justify">
            {member?.description}
          </Text>
        </div>
      </S.Member>
    ),
    []
  )

  return (
    <>
      <Header key={`team-${data?.team?.data?.id}`} />

      <Section col gap="xxl">
        {loading ? <MemberPlaceholder /> : content?.members?.map(renderMembers)}
      </Section>
    </>
  )
}

export default React.memo(Team)
