import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getPoints($id: ID!) {
    points(filters: { user: { id: { eq: $id } } }, sort: ["createdAt:desc"]) {
      data {
        id
        attributes {
          count
          confirmed
          description
          createdAt
          interest {
            data {
              id
              attributes {
                term {
                  data {
                    id
                    attributes {
                      beginning
                      end
                    }
                  }
                }
                event {
                  data {
                    id
                    attributes {
                      title
                      slug
                      category
                      coverImage {
                        ...FileFields
                      }
                      organization {
                        data {
                          id
                          attributes {
                            name
                            slug
                            rating
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
