import { gql } from '@apollo/client'

export default gql`
  mutation createInterest(
    $user: InterestUserInput!
    $data: InterestInput!
    $filters: InterestFiltersInput!
  ) {
    createInterest(data: $data, user: $user) {
      data {
        id
        attributes {
          user {
            data {
              id
              attributes {
                points {
                  data {
                    attributes {
                      count
                      confirmed
                    }
                  }
                }
              }
            }
          }
          event {
            data {
              id
              attributes {
                terms {
                  data {
                    id
                  }
                }
                interests(filters: $filters) {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
