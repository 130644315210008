import React from 'react'

import { ExternalLink, Section, Text } from 'ui'

import * as S from './styled'

const Logos = () => (
  <Section>
    <Text variant="large" align="center" mb="xxl">
      Tvorba tejto webovej stránky bola podporená z dotačnej schémy Ministerstva
      školstva, vedy, výskumu a športu SR Programy pre mládež na roky 2014 -
      2021, ktorú administruje IUVENTA - Slovenský inštitút mládeže.
    </Text>

    <S.Wrapper>
      <ExternalLink to="https://www.minedu.sk/">
        <img
          src="/assets/images/ministerstvo.png"
          alt="Logo Ministerstvo školstva, vedy, výskumu a športu SR"
          width={220}
          height={165}
        />
      </ExternalLink>

      <ExternalLink to="https://www.iuventa.sk">
        <img
          src="/assets/images/iuventa.png"
          alt="Logo Iuventa"
          width={220}
          height={165}
        />
      </ExternalLink>

      <ExternalLink to="https://www.ozviac.sk">
        <img
          src="/assets/images/viac.png"
          alt="Logo V.I.A.C."
          width={220}
          height={165}
        />
      </ExternalLink>

      <ExternalLink to="https://www.zmenme.to">
        <img
          src="/assets/images/zmenmeto.png"
          alt="Logo Zmeňme.to"
          width={220}
          height={165}
        />
      </ExternalLink>
    </S.Wrapper>
  </Section>
)

export default React.memo(Logos)
