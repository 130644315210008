import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'

import { Header } from 'components'
import { Markdown, Section } from 'ui'

import { GET_ARTICLE } from 'apollo/queries'
import { ArticleRelationResponseCollection } from 'types/models/article'

type TParams = {
  slug: string
}

type TQuery = TData<ArticleRelationResponseCollection, 'articles'>

const Article = () => {
  const { slug } = useParams<TParams>()

  const { data, loading } = useQuery<TQuery>(GET_ARTICLE, {
    variables: { slug },
  })

  const root = useMemo(() => data?.articles?.data?.[0], [data])
  const content = useMemo(() => root?.attributes, [root])

  return (
    <>
      <Header
        key={content?.slug}
        title={content?.title}
        description={content?.description}
        image={
          content?.coverImage?.data?.attributes?.formats?.xlarge?.url ||
          content?.coverImage?.data?.attributes?.formats?.large?.url ||
          content?.coverImage?.data?.attributes?.url
        }
        back="/inspiracia/clanky"
      />

      {loading ? (
        <Section>
          <Skeleton height={42} width="50%" style={{ marginBottom: '18px' }} />
          <Skeleton count={8} />
          <Skeleton width="80%" />
          <Skeleton height={300} style={{ marginBlock: '18px' }} />
          <Skeleton count={8} />
        </Section>
      ) : (
        <Section hideIf={!content?.body}>
          <Markdown>{root?.attributes?.body || ''}</Markdown>
        </Section>
      )}
    </>
  )
}

export default React.memo(Article)
