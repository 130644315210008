import React, { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { useContext } from 'hooks'
import { Header } from 'components'
import { Button, CircularProgress, Section, Text } from 'ui'
import { DocumentsEntityResponse } from 'types/models/document'

import { GET_DOCUMENTS } from 'apollo/queries'

import * as S from './styled'
import { formatFileSize } from 'utils/functions'

type TQuery = TData<DocumentsEntityResponse, 'documents'>

const Documents = () => {
  const { isAuthenticated, isLoading } = useContext('auth')

  const [fetchDocuments, { data, loading }] =
    useLazyQuery<TQuery>(GET_DOCUMENTS)

  const content = useMemo(() => data?.documents?.data, [data])

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      fetchDocuments()
    }
  }, [isLoading, isAuthenticated])

  return (
    <>
      <Header title="Dokumenty" />

      {isLoading || !isAuthenticated ? (
        <Section col vCenter hCenter>
          <S.EmptyStateImage
            src="/assets/icons/categories/dokumenty.svg"
            alt=""
          />
          <Text variant="paragraph" color="grey" style={{ paddingBlock: 16 }}>
            Pre zobrazenie dokumentov sa najprv musíte prihlásiť!
          </Text>
          <Link to="/prihlasenie">
            <Button>Prihlásiť sa</Button>
          </Link>
        </Section>
      ) : loading ? (
        <Section col vCenter hCenter style={{ padding: '205px 0' }}>
          <CircularProgress />
        </Section>
      ) : content?.length ? (
        <Section>
          <S.DocumentsTable cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>Názov súboru</th>
                <th>Veľkosť súboru</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {content?.map((item) => (
                <tr key={`document-${item.id}`}>
                  <td>
                    <div>
                      {item.attributes?.file?.data?.[0]?.attributes?.name}
                    </div>
                  </td>
                  <td>
                    <div>
                      {item.attributes?.file?.data?.[0]?.attributes?.size
                        ? formatFileSize(
                            item.attributes.file.data[0].attributes.size
                          )
                        : '0 B'}
                    </div>
                  </td>
                  <td>
                    <div>
                      <a
                        href={item.attributes?.file?.data?.[0]?.attributes?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Stiahnuť
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </S.DocumentsTable>
        </Section>
      ) : (
        <Section col vCenter hCenter>
          <S.EmptyStateImage
            src="/assets/icons/categories/dokumenty.svg"
            alt=""
          />
          <Text variant="paragraph" color="grey" style={{ paddingBlock: 16 }}>
            Žiadne dokumenty na zobrazenie
          </Text>
        </Section>
      )}
    </>
  )
}

export default Documents
