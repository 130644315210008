import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { DateTime } from 'luxon'

import {
  AddEvent,
  ArticleItem,
  Filter,
  Header,
  Item,
  Map,
  TMapMarkerData,
} from 'components'
import { Button, Grid, Section, Switch, Text } from 'ui'
import { useToggle } from 'hooks'

import { GET_EVENTS } from 'apollo/queries'
import {
  EventEntity,
  EventEntityResponseCollection,
  TEventEntityWithLocation,
} from 'types/models/event'
import { ENUM_EVENT_CATEGORY } from 'types/enums'

import * as S from './styled'

type TFilter = {
  old?: boolean
  longTerm?: boolean | null
  ourOffer?: boolean
  regions: Set<string>
  areas: Set<string>
}

type TQuery = TData<EventEntityResponseCollection, 'events'>

const ItemPlaceholder = () => (
  <>
    <Skeleton height={358} />
    <Skeleton height={358} />
    <Skeleton height={358} />
  </>
)

const ArticlePlaceholder = () => (
  <>
    <S.ArticlePlaceholder>
      <div style={{ flex: 1 }}>
        <Skeleton height={45} width="75%" style={{ marginBottom: 16 }} />
        <Skeleton height={12} count={8} />
        <Skeleton height={12} width="80%" />
        <Skeleton width={114} height={47} style={{ marginTop: 24 }} />
      </div>

      <Skeleton height={365} />
    </S.ArticlePlaceholder>

    <S.ArticlePlaceholder flipped>
      <div style={{ flex: 1 }}>
        <Skeleton height={45} width="75%" style={{ marginBottom: 16 }} />
        <Skeleton height={12} count={8} />
        <Skeleton height={12} width="80%" />
        <Skeleton width={114} height={47} style={{ marginTop: 24 }} />
      </div>

      <Skeleton height={365} />
    </S.ArticlePlaceholder>
  </>
)

const Preview = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    visible: addEventModalVisible,
    show: showAddEventModal,
    hide: hideAddEventModal,
  } = useToggle()

  const [filter, setFilter] = useState<TFilter>({
    old: false,
    longTerm: null,
    ourOffer: false,
    regions: new Set(),
    areas: new Set(),
  })

  const category = useMemo(
    () => pathname.split('/')?.[2] || 'nase-vlastne-programy',
    [pathname]
  )

  const { data, loading } = useQuery<TQuery>(GET_EVENTS, {
    variables: {
      now: DateTime.now().toISODate(),
      filters: {
        category: { eq: category },
        and: [
          {
            ...(!!filter.ourOffer && { ourOffer: { eq: true } }),
          },
          {
            ...(filter.longTerm !== null && {
              longTerm: { eq: filter.longTerm },
            }),
          },
        ],
        terms: {
          or: [
            {
              end: filter.old
                ? { lt: DateTime.now().toISODate() }
                : { gte: DateTime.now().toISODate() },
            },
            !filter.old ? { id: { null: true } } : {},
          ],
        },
        ...(!!filter.regions.size && {
          organization: { region: { in: Array.from(filter.regions) } },
        }),
        ...(!!filter.areas.size && {
          or: [{ areas: { id: { in: Array.from(filter.areas) } } }],
        }),
      },
    },
  })

  const isVolunteerOpportunities = useMemo(
    () => pathname === '/pre-dobrovolnikov/dobrovolnicke-prilezitosti',
    [pathname]
  )

  const isSecondary = useMemo(
    () =>
      [
        ENUM_EVENT_CATEGORY.nastroje_na_podporu,
        ENUM_EVENT_CATEGORY.nastroje_na_podporu_prace_s_dobrovolnikmi,
      ].includes(category as ENUM_EVENT_CATEGORY),
    [category]
  )

  const markers: TMapMarkerData[] = useMemo(() => {
    return (
      data?.events?.data
        ?.filter(
          (item): item is TEventEntityWithLocation =>
            !!item.attributes?.location
        )
        .map((item) => ({
          name: item.attributes.title,
          slug: item.attributes.slug,
          location: JSON.parse(item.attributes.location),
        })) || []
    )
  }, [data])

  const onMarkerClick = useCallback((marker: TMapMarkerData) => {
    navigate(`/pre-dobrovolnikov/dobrovolnicke-prilezitosti/${marker.slug}`)
  }, [])

  const renderItems = useCallback(
    ({ attributes: item }: EventEntity) => (
      <Item key={`${item?.category}-${item?.slug}`} data={item!} />
    ),
    [data]
  )

  const renderSecondary = useCallback(
    ({ attributes: item }: EventEntity, idx: number) =>
      !item ? null : (
        <ArticleItem
          key={`${item.category}-${item.slug}`}
          title={item.title}
          description={item.description}
          coverImage={item.coverImage}
          path={`/inspiracia/${category}/${item.slug}`}
          flipped={!(idx % 2)}
        />
      ),
    [data, isSecondary]
  )

  return (
    <>
      <Header />

      <Section col={isSecondary} {...(isSecondary && { gap: 'xxxl' })}>
        {isSecondary ? (
          <>
            {loading ? (
              <ArticlePlaceholder />
            ) : (
              data?.events?.data?.map(renderSecondary)
            )}
          </>
        ) : (
          <>
            <S.Top>
              <Filter values={filter} setValues={setFilter} />

              <Switch
                nullLabel="Všetky"
                falseLabel="Jednorázová"
                trueLabel="Dlhodobá"
                value={filter.longTerm}
                onChange={(value: boolean) =>
                  setFilter({ ...filter, longTerm: value })
                }
              />

              <Switch
                falseLabel="Aktuálne"
                trueLabel="Zrealizované"
                value={filter.old}
                onChange={(value: boolean) =>
                  setFilter({ ...filter, old: value })
                }
              />

              {isVolunteerOpportunities && (
                <Button
                  onClick={showAddEventModal}
                  style={{ marginLeft: 'auto' }}
                >
                  Pridať príležitosť
                </Button>
              )}
            </S.Top>

            <Grid>
              {loading ? (
                <ItemPlaceholder />
              ) : data?.events?.data?.length ? (
                data.events.data.map(renderItems)
              ) : (
                <S.EmptyState>
                  <img src="/assets/icons/empty.svg" alt="" />
                  <Text variant="paragraph" color="grey">
                    Nenašli sa žiadne výsledky
                  </Text>
                </S.EmptyState>
              )}
            </Grid>
          </>
        )}
      </Section>

      {isVolunteerOpportunities && !!markers.length && (
        <Map {...{ markers, onMarkerClick }} />
      )}

      {addEventModalVisible && <AddEvent onClose={hideAddEventModal} />}
    </>
  )
}

export default React.memo(Preview)
