import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
}

const Container = ({ children, ...props }: Props) => (
  <S.Wrapper {...props}>{children}</S.Wrapper>
)

export default React.memo(Container)
