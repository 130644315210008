import React, { useCallback } from 'react'

import { CarouselItem } from 'components'
import { Carousel, Section, Text } from 'ui'
import { ArticleEntity } from 'types/models/article'

type TProps = {
  collection?: ArticleEntity[]
}

const Articles = ({ collection }: TProps) => {
  const renderLectors = useCallback(
    ({ attributes: item }: ArticleEntity) => (
      <CarouselItem
        key={`${item?.slug}-article`}
        image={item?.coverImage?.data}
        name={item?.title}
        description={item?.description}
        path={`/inspiracia/clanky/${item?.slug}`}
      />
    ),
    [collection]
  )

  return (
    <Section hideIf={!collection?.length}>
      <Text
        as="h2"
        variant="heading2"
        color="salmon"
        align="center"
        mb="xxl"
        uppercase
      >
        Galéria článkov
      </Text>

      <Carousel
        isCondensed
        isAutomatic
        isInfinite={(collection?.length || 0) > 3}
      >
        {collection?.map(renderLectors)}
      </Carousel>
    </Section>
  )
}

export default React.memo(Articles)
