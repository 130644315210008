import React, { useCallback } from 'react'
import { To, useNavigate } from 'react-router'

import Image from '../../Image'
import { Article } from 'types/models/article'
import { Button, Text } from 'ui'

import * as S from './styled'

type TProps = {
  path: To
  flipped?: boolean
} & Pick<Article, 'title' | 'description' | 'coverImage'>

const ArticleItem = ({
  path,
  title,
  description,
  coverImage,
  flipped
}: TProps) => {
  const navigate = useNavigate()

  const toDetail = useCallback(() => navigate(path), [path, navigate])

  return (
    <S.Wrapper {...{ flipped }}>
      <Image
        src={
          coverImage?.data?.attributes?.formats?.xlarge?.url ||
          coverImage?.data?.attributes?.formats?.large?.url ||
          coverImage?.data?.attributes?.url ||
          ''
        }
        alt={`${title} image`}
        width={365}
        height={365}
      />

      <div>
        <Text as="h2" variant="heading1" color="salmon" mb="s" uppercase>
          {title}
        </Text>

        <Text variant="paragraph" mb="m" align="justify">
          {description}
        </Text>

        <Button onClick={toDetail}>Zistiť viac</Button>
      </div>
    </S.Wrapper>
  )
}

export default React.memo(ArticleItem)
