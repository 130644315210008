import React, { useMemo } from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  isAutomatic?: boolean
  isCondensed?: boolean
  isInfinite?: boolean
}

const Carousel = ({
  children,
  isAutomatic,
  isCondensed,
  isInfinite,
}: Props) => {
  const settings = useMemo(
    () => ({
      prevArrow: <S.Arrow />,
      nextArrow: <S.Arrow isRight />,
      dots: false,
      infinite: isInfinite,
      speed: isAutomatic ? 1500 : 400,
      slidesToShow: isCondensed ? 3 : 4,
      slidesToScroll: 1,
      initialSlide: 0,
      ...(isAutomatic && {
        autoplay: true,
        autoplaySpeed: 5000,
      }),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: isCondensed ? 2 : 3,
          },
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: isCondensed ? 1 : 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }),
    [isCondensed, isAutomatic, isInfinite]
  )

  return (
    <S.StyledSlider {...{ ...settings, isCondensed }}>
      {children}
    </S.StyledSlider>
  )
}

export default React.memo(Carousel)
