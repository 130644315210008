import React, { useCallback, useMemo, useRef } from 'react'
import { useQuery } from '@apollo/client'

import { Switch, Text } from 'ui'
import { useClickOutside, useToggle } from 'hooks'
import regions from 'constants/regions'

import { GET_AREAS } from 'apollo/queries'
import { AreaRelationResponseCollection } from 'types/models/area'

import * as S from './styled'

type Item = {
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string
  // eslint-disable-next-line react/no-unused-prop-types
  value: string
}

type TFilter = {
  old?: boolean
  longTerm?: boolean | null
  ourOffer?: boolean
  regions: Set<string>
  areas: Set<string>
}

type TProps = {
  inverted?: boolean
  values: TFilter
  setValues: (value: TFilter) => void
  resetFilter: () => void
}

type TQuery = TData<AreaRelationResponseCollection, 'areas'>

const Filter = ({ values, setValues, resetFilter, inverted }: TProps) => {
  const { visible, hide, toggle } = useToggle()
  const ref = useRef<HTMLDivElement | null>(null)

  useClickOutside(ref, hide)

  const { data } = useQuery<TQuery>(GET_AREAS)

  const selectedCount = useMemo(
    () => values.areas.size + values.regions.size,
    [values]
  )

  const items = useMemo(
    () => ({
      regions: Object.entries(regions).map(([key, value]) => ({
        label: value.replace(' kraj', ''),
        value: key
      })),
      areas: data?.areas?.data?.map(({ id, attributes }) => ({
        label: attributes?.name,
        value: `${id}`
      }))
    }),
    [data]
  )

  const clear = useCallback(() => {
    resetFilter()
    hide()
  }, [hide, resetFilter])

  const renderItems = useCallback(
    ({ label, value }: Item, type: 'areas' | 'regions') => (
      <S.Item
        key={value}
        isActive={values?.[type]?.has(value)}
        inverted={inverted}
        onClick={() => {
          const newValues = values?.[type]

          if (newValues?.has(value)) newValues?.delete(value)
          else newValues.add(value)

          setValues({ ...values, [type]: newValues })
        }}
      >
        {label}
      </S.Item>
    ),
    [values, inverted, setValues]
  )

  return (
    <S.Wrapper isActive={visible} inverted={inverted} ref={ref}>
      <button onClick={toggle}>Filtrovať ({selectedCount})</button>

      {visible && (
        <S.Content inverted={inverted}>
          <button onClick={clear}>Zrušiť filtrovanie</button>

          {!inverted && (
            <>
              <Text variant="bold" mb="xxxs">
                Podľa typu:
              </Text>

              <Switch
                falseLabel="Všetky"
                trueLabel="Naša ponuka"
                value={values.ourOffer}
                onChange={(value: boolean) =>
                  setValues({ ...values, ourOffer: value })
                }
              />
            </>
          )}

          {inverted && (
            <>
              <Text
                variant="bold"
                mb="xxxs"
                color={inverted ? 'white' : 'grey'}
              >
                Podľa kraja:
              </Text>

              <S.Items>
                {items.regions?.map((item) => renderItems(item, 'regions'))}
              </S.Items>
            </>
          )}

          <Text variant="bold" mb="xxxs" color={inverted ? 'white' : 'grey'}>
            Podľa oblasti:
          </Text>

          <S.Items>
            {items.areas?.map((item) => renderItems(item, 'areas'))}
          </S.Items>
        </S.Content>
      )}
    </S.Wrapper>
  )
}

export default React.memo(Filter)
