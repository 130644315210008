import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Contact, Gallery, OrganizationInfo, Events } from '../sections'
import { HeaderInfo } from './sections'

import { Header } from 'components'
import { Markdown, Section, Video } from 'ui'

import { GET_ORGANIZATION } from 'apollo/queries'
import { OrganizationEntityResponseCollection } from 'types/models/organization'

type TParams = {
  slug: string
}

type TQuery = TData<OrganizationEntityResponseCollection, 'organizations'>

const Organization = () => {
  const { slug } = useParams<TParams>()

  const { data } = useQuery<TQuery>(GET_ORGANIZATION, {
    variables: { slug }
  })

  const root = useMemo(() => data?.organizations?.data?.[0], [data])
  const content = useMemo(() => root?.attributes, [root])

  return !content ? null : (
    <>
      <Header
        key={content.slug}
        title={content.name}
        rating={content.rating}
        description={content.description}
        icon="dobrovolnicke-organizacie"
        image={
          content.coverImage?.data?.attributes?.formats?.xlarge?.url ||
          content.coverImage?.data?.attributes?.formats?.large?.url ||
          content.coverImage?.data?.attributes?.url
        }
        back="/inspiracia/dobrovolnicke-organizacie"
      >
        <HeaderInfo region={content.region} areas={content.areas} />
      </Header>

      <Section bg="secondaryBackground" hideIf={!content.body}>
        <Markdown>{content.body ?? ''}</Markdown>
      </Section>

      <Section bg="blue" hideIf={!content.videoLink}>
        <Video src={content.videoLink} />
      </Section>

      <Events
        collection={content.events?.data}
        title="Galéria dobrovoľníckych príležitostí"
      />

      <Gallery collection={content.gallery?.data} bg="secondaryBackground" />

      <Contact email={content.email} />

      <OrganizationInfo entity={root} bg="secondaryBackground" />
    </>
  )
}

export default React.memo(Organization)
