import { Location } from 'react-router'
import {
  PointEntity,
  PointRelationResponseCollection,
} from 'types/models/point'

export const clamp = (value: number, min: number, max: number): number =>
  Math.max(Math.min(value, max), min)

export const openBlank = (url?: string) => url && window.open(url, '_blank')

export const fromLocation = (location: Location, origin = '/') =>
  (location?.state as any)?.from || origin

export const calculateLeftPosition = (target: HTMLButtonElement) => {
  const windowWidth = window.innerWidth

  const btnLeft = target.offsetLeft
  const btnWidth = target.clientWidth
  const btnCenter = btnLeft + btnWidth / 2

  const elementLeft = btnCenter - 178
  return clamp(elementLeft, 20, windowWidth - 391)
}

export const sumPoints = (points?: PointRelationResponseCollection): number =>
  points?.data?.reduce(
    (acc: number, { attributes }: PointEntity) =>
      acc + (attributes?.count || 0),
    0
  ) || 0

export const formatFileSize = (kiloBytes: number, decimals = 2) => {
  if (!+kiloBytes) return '0 B'

  const k = 1024
  const bytes = kiloBytes * k
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
