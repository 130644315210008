import styled, { css, DefaultTheme } from 'styled-components'

import { loader } from 'styles/common'

interface IWrapper {
  isSecondary?: boolean
  isWide?: boolean
  transparent?: boolean
  mb?: keyof DefaultTheme['sizes']
  mr?: keyof DefaultTheme['sizes']
}

const inverted = css<IWrapper>`
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme, isSecondary }) =>
    theme.colors?.[isSecondary ? 'blue' : 'salmon']};
  &:hover {
    background-color: transparent;
  }
`

export const Wrapper = styled.button<IWrapper>`
  background-color: ${({ theme, isSecondary }) =>
    theme.colors?.[isSecondary ? 'blue' : 'salmon']};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.sizes.xxs};

  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-align: center;

  padding: 14px ${({ theme, isWide }) => (isWide ? theme.sizes.m : '20px')};
  ${({ theme, mb }) => !!mb && `margin-bottom: ${theme.sizes[mb]}`};
  ${({ theme, mr }) => !!mr && `margin-right: ${theme.sizes[mr]}`};

  transition: background-color 300ms ease;

  &:hover {
    background-color: ${({ theme, isSecondary }) =>
      theme.colors?.[isSecondary ? 'darkBlue' : 'darkSalmon']};
  }

  ${({ transparent }) => transparent && inverted}

  ${loader}
`
