import styled from 'styled-components'

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: ${({ theme }) => theme.sizes.m};
  }
`
