import React from 'react'
import remarkGfm from 'remark-gfm'

import * as S from './styled'

type TProps = {
  children: string
}

const Markdown = ({ children }: TProps) => (
  <S.Wrapper remarkPlugins={[remarkGfm]}>{children}</S.Wrapper>
)

export default React.memo(Markdown)
