import React from 'react'

import { ExternalLink, Section, Text } from 'ui'

import * as S from './styled'

const Logos = () => (
  <Section>
    <Text variant="large" align="center" mb="xxl">
      Tvorba tejto webovej stránky bola podporená z dotačnej schémy Ministerstva
      školstva, vedy, výskumu a športu SR Programy pre mládež na roky 2021 -
      2021, ktorú administruje IUVENTA - Slovenský inštitút mládeže.
      <br />
      <br />
      Tvorba tejto webovej stránky bola podporená z dotačnej schémy Ministerstva
      školstva, vedy, výskumu, vývoja a mládeže SR dotácie v oblasti práce s
      mládežou na roky 2021 - 2028, ktorú administruje NIVAM - národný inštitút
      vzdelávania a mládeže.
    </Text>

    <S.Wrapper>
      <ExternalLink to="https://www.minedu.sk/">
        <img
          src="/assets/images/minedu.png"
          alt="Logo Ministerstvo školstva, výskumu, vývoja a mládeže Slovenskej republiky"
          width={220}
          height={165}
        />
      </ExternalLink>

      <ExternalLink to="https://www.iuventa.sk">
        <img
          src="/assets/images/nivam.png"
          alt="Logo NIVAM"
          width={220}
          height={165}
        />
      </ExternalLink>

      <ExternalLink to="https://www.ozviac.sk">
        <img
          src="/assets/images/viac.png"
          alt="Logo VIAC"
          width={220}
          height={165}
        />
      </ExternalLink>

      <ExternalLink to="https://www.zmenme.to">
        <img
          src="/assets/images/zmenmeto.png"
          alt="Logo Zmeňme.to"
          width={220}
          height={165}
        />
      </ExternalLink>
    </S.Wrapper>
  </Section>
)

export default React.memo(Logos)
