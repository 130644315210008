import styled from 'styled-components'
import { Form } from 'formik'

export const Wrapper = styled(Form)`
  max-width: 617px;
  margin-inline: auto;

  display: flex;
  flex-direction: column;

  button {
    align-self: center;
  }

  & > *:not(button) + * {
    margin-top: ${({ theme }) => theme.sizes.s};
  }
`

export const Half = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.sizes.s};

  @media (max-width: 480px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.sizes.m};
  }
`
