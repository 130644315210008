import React from 'react'

import { Text } from 'ui'

import * as S from './styled'

type TProps = {
  title: string
  text: React.ReactNode
  children?: React.ReactNode
}

const Success = ({ title, text, children }: TProps) => (
  <S.Wrapper>
    <Text as="h1" variant="heading2" color="salmon" align="center">
      {title}
    </Text>

    <img src="/assets/icons/done.svg" alt="done" width={54} height={54} />

    <Text variant="paragraph" align="center">
      {text}
    </Text>

    {children}
  </S.Wrapper>
)

export default React.memo(Success)
