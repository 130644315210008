import React from 'react'
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader
} from '@react-google-maps/api'

import { styles } from './constants'

const { GOOGLE_MAPS_API_KEY } = process.env

export type TMapMarkerData = {
  name: string
  slug: string
  location: google.maps.LatLng
}

type TMapProps = {
  markers?: TMapMarkerData[]
  onMarkerClick?: (item: TMapMarkerData) => void
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string
  // eslint-disable-next-line react/no-unused-prop-types
  style?: React.CSSProperties
}

const Map = ({ markers, onMarkerClick }: TMapProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY || ''
  })

  return isLoaded ? (
    <GoogleMap
      center={{ lat: 48.669025, lng: 19.699024 }}
      zoom={8}
      mapContainerStyle={{ width: '100%', height: '400px' }}
      options={{
        disableDefaultUI: true,
        styles
      }}
    >
      {!!markers?.length &&
        markers.map((item, idx) => (
          <MarkerF
            key={`map-marker-${idx}`}
            position={item.location}
            onClick={() => onMarkerClick?.(item)}
          >
            <InfoWindowF position={item.location}>
              <div>{item.name}</div>
            </InfoWindowF>
          </MarkerF>
        ))}
    </GoogleMap>
  ) : null
}

export default Map
