import React, { useCallback, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { omit } from 'lodash'

import { ChangePassword } from 'components'
import { Button, Input, Pick, Section, Text } from 'ui'
import { useForm, useToggle } from 'hooks'
import validationSchema from 'schemas/profile'

import { GET_USER } from 'apollo/queries'
import { REMOVE_FILE, UPDATE_ME, UPLOAD } from 'apollo/mutations'
import { UsersPermissionsUserEntityResponse } from 'types/models/user'
import { UsersPermissionsUserInput } from 'types/inputs'

import * as S from './styled'

type TQuery = TData<UsersPermissionsUserEntityResponse, 'getMe'>
type TMutation = TData<UsersPermissionsUserEntityResponse, 'updateMe'>

const Edit = () => {
  const [image, setImage] = useState<File | null>(null)
  const { visible, show, hide: onClose } = useToggle()
  const { visible: success, show: showSuccess } = useToggle()

  const { data } = useQuery<TQuery>(GET_USER)

  const content = useMemo(() => data?.getMe?.data?.attributes, [data])

  const initialValues = useMemo(
    (): UsersPermissionsUserInput => ({
      name: content?.name || '',
      surname: content?.surname || '',
      birthday: content?.birthday || '',
      email: content?.email || '',
      city: content?.city || '',
      street: content?.street || '',
      houseNumber: content?.houseNumber || '',
      postcode: content?.postcode || '',
      phone: content?.phone || '',
      bio: content?.bio || '',
      avatar: content?.avatar?.data?.attributes?.url || '',
    }),
    [content]
  )

  const [remove, { loading: removeLoading }] = useMutation(REMOVE_FILE)
  const [upload, { loading: uploadLoading }] = useMutation(UPLOAD)

  const {
    onSubmit: handleSubmit,
    loading,
    error,
  } = useForm<TMutation, { input: UsersPermissionsUserInput } & { id?: ID }>(
    UPDATE_ME,
    showSuccess
  )

  const onSubmit = useCallback(
    async (values, formikHelpers) => {
      if (
        (!!image && !!content?.avatar?.data?.id) ||
        (!!content?.avatar?.data?.id && !values.avatar)
      ) {
        await remove({ variables: { id: content?.avatar?.data?.id } })
      }

      // TODO: change name, remove image
      let newImage: Awaited<ReturnType<typeof upload>> | null = null

      if (image?.name) {
        newImage = await upload({
          variables: { file: image },
        })
      }

      const input = {
        ...omit(values, ['email', 'birthday', 'avatar']),
        ...(!!image &&
          !!newImage && { avatar: newImage?.data?.upload?.data?.id }),
      }

      await handleSubmit({ id: data?.getMe?.data?.id, input }, formikHelpers)
    },
    [handleSubmit, data, image, content]
  )

  return (
    <Section>
      {visible && <ChangePassword {...{ onClose }} />}

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue }) => (
          <S.Wrapper>
            <Text variant="paragraph">
              Ste našim členom od{' '}
              <Text as="span" weight={700} color="salmon">
                {content?.createdAt &&
                  DateTime.fromISO(content.createdAt).toFormat('dd.MM.yyyy')}
              </Text>
            </Text>

            <S.Half>
              <Input
                type="text"
                name="name"
                label="Meno *"
                disabled={loading}
                isError={touched.name && errors.name}
                required
              />

              <Input
                type="text"
                name="surname"
                label="Priezvisko *"
                disabled={loading}
                isError={touched.surname && errors.surname}
                required
              />
            </S.Half>

            <S.Half>
              <Input
                type="date"
                name="birthday"
                label="Dátum narodenia *"
                isError={touched.birthday && errors.birthday}
                required
                defaultDisabled
              />

              <Input
                type="text"
                name="phone"
                label="Telefón *"
                disabled={loading}
                isError={touched.phone && errors.phone}
                required
              />
            </S.Half>

            <Input
              type="email"
              name="email"
              label="E-mail *"
              isError={touched.email && errors.email}
              required
              defaultDisabled
            />

            <S.Half>
              <Input
                type="text"
                name="city"
                label="Mesto *"
                disabled={loading}
                isError={touched.city && errors.city}
                required
              />

              <Input
                type="text"
                name="street"
                label="Ulica *"
                disabled={loading}
                isError={touched.street && errors.street}
                required
              />
            </S.Half>

            <S.Half>
              <Input
                type="text"
                name="houseNumber"
                label="Popisné číslo *"
                disabled={loading}
                isError={touched.houseNumber && errors.houseNumber}
                required
              />

              <Input
                type="text"
                name="postcode"
                label="PSČ *"
                disabled={loading}
                isError={touched.postcode && errors.postcode}
                required
              />
            </S.Half>

            <Input
              as="textarea"
              name="bio"
              label="Niečo o sebe"
              disabled={loading}
              isError={touched.bio && errors.bio}
            />

            <Pick
              label="Profilová fotografia"
              accept="image/*"
              defaultValue={initialValues?.avatar}
              setForm={(val) => {
                setImage(val)

                if (values.avatar && val === null) {
                  setFieldValue('avatar', val)
                }
              }}
            />

            {!!error && (
              <Text as="small" variant="error">
                {error}
              </Text>
            )}

            {success && (
              <Text as="small" variant="bold" color="salmon" align="center">
                Profil bol úspešne aktualizovaný.
              </Text>
            )}

            <Button
              type="submit"
              disabled={loading}
              isLoading={loading}
              mb="xxxs"
            >
              Uložiť profil
            </Button>

            <Button
              type="button"
              disabled={loading || removeLoading || uploadLoading}
              isLoading={loading || removeLoading || uploadLoading}
              transparent
              onClick={show}
            >
              Zmeniť heslo
            </Button>
          </S.Wrapper>
        )}
      </Formik>
    </Section>
  )
}

export default React.memo(Edit)
