import React from 'react'
import { Form, Formik } from 'formik'
import { NavLink } from 'react-router-dom'

import { Button, Input, Link, Text } from 'ui'
import { useContext, useForm } from 'hooks'
import validationSchema from 'schemas/signin'

import { LOGIN } from 'apollo/mutations'
import { UsersPermissionsLoginPayload } from 'types/models/user'
import { UsersPermissionsLoginInput } from 'types/inputs'

const initialValues: UsersPermissionsLoginInput = {
  identifier: '',
  password: ''
}

type TMutation = TData<UsersPermissionsLoginPayload, 'login'>

const SignIn = () => {
  const { onLogin } = useContext('auth')

  const { onSubmit, loading, error } = useForm<
    TMutation,
    { input: UsersPermissionsLoginInput }
  >(LOGIN, (data) => onLogin(data?.login?.jwt))

  return (
    <>
      <article>
        <Text as="h1" variant="heading2" color="salmon" mb="s" align="center">
          Prihlásenie
        </Text>

        <Formik
          {...{ initialValues, validationSchema }}
          onSubmit={(values, formikHelpers) =>
            onSubmit({ input: values }, formikHelpers)
          }
          validateOnChange
        >
          {({ errors, touched }) => (
            <Form>
              <Input
                type="email"
                name="identifier"
                label="E-mail"
                disabled={loading}
                isError={touched.identifier && errors.identifier}
                required
              />

              <Input
                type="password"
                name="password"
                label="Heslo"
                disabled={loading}
                isError={touched.password && errors.password}
                required
              >
                <Link to="/zmena-hesla">Zabudli ste heslo?</Link>
              </Input>

              {!!error && (
                <Text as="small" variant="error">
                  {error}
                </Text>
              )}

              <Button type="submit" disabled={loading} isLoading={loading}>
                Prihlásiť sa
              </Button>
            </Form>
          )}
        </Formik>
      </article>

      <small>
        Nemáte účet? <NavLink to="/registracia">Zaregistrujte sa!</NavLink>
      </small>
    </>
  )
}

export default React.memo(SignIn)
