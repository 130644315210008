import { Location } from 'react-router'
import { TLocationState } from 'types/global'
import {
  PointEntity,
  PointRelationResponseCollection
} from 'types/models/point'

export const clamp = (value: number, min: number, max: number): number =>
  Math.max(Math.min(value, max), min)

export const openBlank = (url?: string) => url && window.open(url, '_blank')

export const fromLocation = (location: Location, origin = '/') => {
  return (location?.state as TLocationState)?.from || origin
}

export const calculateLeftPosition = (target: HTMLButtonElement) => {
  const windowWidth = window.innerWidth

  const btnLeft = target.offsetLeft
  const btnWidth = target.clientWidth
  const btnCenter = btnLeft + btnWidth / 2

  const elementLeft = btnCenter - 178
  return clamp(elementLeft, 20, windowWidth - 391)
}

const DEFAULT_POINTS = { confirmed: 0, unconfirmed: 0 }

export const sumPoints = (
  points?: PointRelationResponseCollection
): typeof DEFAULT_POINTS => {
  const data = points?.data || []

  return (
    data.reduce((acc, { attributes }: PointEntity) => {
      const count = attributes?.count || 0

      if (attributes?.confirmed) {
        return {
          ...acc,
          confirmed: acc.confirmed + count
        }
      }

      return {
        ...acc,
        unconfirmed: acc.unconfirmed + count
      }
    }, DEFAULT_POINTS) || DEFAULT_POINTS
  )
}

export const formatFileSize = (kiloBytes: number, decimals = 2) => {
  if (!+kiloBytes) return '0 B'

  const k = 1024
  const bytes = kiloBytes * k
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
