import React from 'react'

import { LOGO } from 'constants/global'

import * as S from './styled'

type TProps = {
  dark?: boolean
}

const Logo = ({ dark }: TProps) => (
  <S.Wrapper to="/" $dark={dark}>
    <img src={LOGO} alt="DobrovolnictvoZA.sk" />
    {!dark && <span>dobrovolnictvoza.sk</span>}
  </S.Wrapper>
)

export default React.memo(Logo)
