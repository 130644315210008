import styled from 'styled-components'

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  padding: ${({ theme }) => theme.sizes.xxxl} 2%;

  background: ${({ theme }) => theme.colors.blueBackground};

  & > small {
    margin-top: ${({ theme }) => theme.sizes.section};
  }

  & > article {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    gap: ${({ theme }) => theme.sizes.s};
    margin-top: ${({ theme }) => theme.sizes.xl};

    width: 100%;
    max-width: 550px;

    background: ${({ theme }) => theme.colors.background};
    box-shadow: ${({ theme }) => theme.shadows.modal};
    border-radius: ${({ theme }) => theme.sizes.xxs};

    padding: ${({ theme }) => theme.sizes.xxl} ${({ theme }) => theme.sizes.l};

    & > p {
      align-self: center;
    }

    & > form {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > * + * {
        margin-top: ${({ theme }) => theme.sizes.m};
      }
    }
  }

  & > small {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};

    & > a {
      color: ${({ theme }) => theme.colors.salmon};
      text-decoration: underline;
    }
  }
`
