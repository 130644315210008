import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface IWrapper {
  $dark?: boolean
}

export const Wrapper = styled(Link)<IWrapper>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  img {
    max-width: 40px;
  }

  span {
    text-align: center;

    display: block;
    margin-left: ${({ theme }) => theme.sizes.s};
    color: ${({ theme }) => theme.colors.salmon};

    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: -0.3px;
  }
`
