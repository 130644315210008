import React, { HTMLAttributes } from 'react'
import { DefaultTheme } from 'styled-components'

import * as S from './styled'

type TProps = {
  children: React.ReactNode
  bg?: keyof DefaultTheme['colors']
  hCenter?: boolean
  vCenter?: boolean
  row?: boolean
  col?: boolean
  gap?: keyof DefaultTheme['sizes']
  flex?: 'left' | 'right' | 'both'
  hideIf?: boolean
  width?: number
} & HTMLAttributes<HTMLDivElement>

const Section = ({ children, hideIf, ...props }: TProps) =>
  !hideIf ? (
    <S.Wrapper {...props}>
      <div>{children}</div>
    </S.Wrapper>
  ) : null

export default React.memo(Section)
