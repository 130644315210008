import styled from 'styled-components'
import Slider from 'react-slick'

interface IArrow {
  isRight?: boolean
}

interface ISlider {
  isCondensed?: boolean
}

export const StyledSlider = styled(Slider)<ISlider>`
  position: relative;
  margin-top: 50px;

  article {
    display: flex !important;
    height: 100%;

    flex-direction: column;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-disabled {
    opacity: 0.3;
  }

  .slick-slide {
    padding: ${(p) => (p.isCondensed ? '70px 25px' : '70px 25px')};
    height: inherit !important;

    & > div {
      height: 100%;
    }
  }

  .slick-list {
    margin: ${(p) => (p.isCondensed ? '-70px -25px' : '-70px -25px')};
  }
`

export const Arrow = styled.button<IArrow>`
  position: absolute;
  z-index: 10;

  top: calc(50% - 21px);
  ${(p) => (p.isRight ? 'right: -21px;' : 'left: -21px;')}

  width: 42px;
  height: 42px;

  ${(p) => !p.isRight && 'transform: rotate(-180deg);'}

  &:before {
    content: '';
    background: url('/assets/icons/carousel-arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    position: absolute;
    top: calc(50% - 21px);
    left: calc(50% - 21px);

    width: 42px;
    height: 42px;
  }
`
