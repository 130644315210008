import React, { useCallback } from 'react'
import { To, useNavigate } from 'react-router'

import { Section, Text, Button } from 'ui'

import * as S from './styled'

const Contact = () => {
  const navigate = useNavigate()

  const to = useCallback((to: To) => () => navigate(to), [navigate])

  return (
    <Section bg="blue" col vCenter>
      <Text mb="s" color="white" align="center">
        Sme otvorení v spoluprácach s organizáciami!
      </Text>

      <Button mb="s" onClick={to('/kontaktuj-nas')}>
        Kontaktuj nás!
      </Button>

      <S.NavLink to="/cestou-s-nami#spolupracovali-s-nami">
        Spolupracujúce organizácie
      </S.NavLink>
    </Section>
  )
}

export default React.memo(Contact)
