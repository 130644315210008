import React, { useCallback } from 'react'

import { Image } from 'components'
import { Grid, Section, Text } from 'ui'
import { ComponentStaticAnnualReport } from 'types/models/components'

import * as S from './styled'

type TProps = {
  collection?: ComponentStaticAnnualReport[]
}

const AnnualReports = ({ collection }: TProps) => {
  const renderReports = useCallback(
    (item: ComponentStaticAnnualReport) => (
      <S.Wrapper
        key={`report-${item?.id}-${item?.year}`}
        href={item?.document?.data?.attributes?.url || ''}
        content={item?.year}
        target="_blank"
      >
        <Image
          src={
            item?.coverImage?.data?.attributes?.formats?.xlarge?.url ||
            item?.coverImage?.data?.attributes?.formats?.large?.url ||
            item?.coverImage?.data?.attributes?.url ||
            ''
          }
          alt={`report image ${item?.year}`}
          width={300}
          height={358}
        />
      </S.Wrapper>
    ),
    [collection]
  )

  return (
    <Section hideIf={!collection?.length}>
      <Text as="h2" variant="heading2" color="salmon" mb="xxl" uppercase>
        Urobili sme pre vás
      </Text>

      <Grid>{collection?.map(renderReports)}</Grid>
    </Section>
  )
}

export default React.memo(AnnualReports)
