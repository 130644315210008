import styled, { keyframes, css } from 'styled-components'

import { TStyledCSS } from 'types/global'

import { TCircularProgressVariant } from './types'

const circularRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const circularDash = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

export type TWrapperProps = {
  variant: TCircularProgressVariant
}

type TCircularProgressVariants = Record<TCircularProgressVariant, TStyledCSS>

const VARIANTS: TCircularProgressVariants = {
  determinate: css`
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0;

    svg {
      animation: ${circularRotate} 10s linear infinite;

      circle {
        transition: stroke-dashoffset 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0;
      }
    }
  `,
  indeterminate: css`
    animation: ${circularRotate} 1.4s linear infinite;

    svg circle {
      animation: ${circularDash} 1.4s ease-in-out infinite;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0;
    }
  `
}

export const Wrapper = styled.span<TWrapperProps>`
  ${(p) => VARIANTS[p.variant || 'indeterminate']}

  display: block;

  svg {
    color: ${(p) => p.theme.colors.darkBlue};
    display: block;

    circle {
      stroke: currentColor;
    }
  }
`
