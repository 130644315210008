import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Points, Stars, Tag, Text } from 'ui'
import { formatDate } from 'utils/date'
import { ENUM_EVENT_CATEGORY } from 'types/enums'
import { Event } from 'types/models/event'
import { GrowniProject } from 'types/models/growniProject'

import category from 'constants/category'

import * as S from './styled'

const isGROWNiProject = (
  data: Event | GrowniProject
): data is GrowniProject => {
  return '__typename' in data && data.__typename === 'GrowniProject'
}

const getEventUrl = (data: Event) => {
  const subcategory = ENUM_EVENT_CATEGORY[data.category]
  return `/${category[subcategory]}/${subcategory}/${data.slug}`
}

type TProps = {
  data: Event | GrowniProject
}

const Item = ({ data }: TProps) => {
  const isExternal = isGROWNiProject(data)

  const tags = useMemo(() => {
    const items = isGROWNiProject(data)
      ? data.topics?.map((item) => item.value).slice(0, 6)
      : data.areas?.data?.map((item) => item?.attributes?.name ?? '')

    return items || []
  }, [data])

  const renderTags = useCallback(
    (value: string) => <Tag key={value}>{value}</Tag>,
    []
  )

  const WrapperComponent = isExternal ? 'a' : Link

  return (
    <S.Wrapper
      as={WrapperComponent}
      to={!isExternal ? getEventUrl(data) : undefined}
      href={isExternal ? data.url : undefined}
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
    >
      <img
        src={
          isExternal
            ? data.image
            : data?.coverImage?.data?.attributes?.formats?.xlarge?.url ||
              data?.coverImage?.data?.attributes?.formats?.large?.url ||
              data?.coverImage?.data?.attributes?.url
        }
        alt={
          isExternal
            ? 'GROWNi project image'
            : data?.coverImage?.data?.attributes?.alternativeText
        }
      />

      <S.Content>
        <div>
          {!isExternal && !!data.capacity ? (
            <Text variant="bold">Voľné miesta: {data.capacity}</Text>
          ) : (
            <div />
          )}

          {!isExternal && !!data.points ? (
            <Points points={{ confirmed: data.points }} hideText />
          ) : (
            <div />
          )}
        </div>

        <div>
          <Text as="span" variant="bold" color="salmon">
            {!isExternal && !!data.terms?.data?.length && (
              <>
                {formatDate(data.terms?.data[0]?.attributes?.beginning || '')}
              </>
            )}
          </Text>

          <Text as="h1" variant="subheading">
            {data.title}
          </Text>

          {!isExternal && !!data.organization?.data && (
            <Text as="h2" variant="bold" color="lightSalmon">
              <S.OrgName>
                <span>{data.organization.data.attributes?.name}</span>

                {!!data.organization.data.attributes?.rating && (
                  <Stars
                    value={data.organization.data.attributes.rating}
                    max={3}
                    starSize={14}
                    starGap={2}
                    style={{
                      paddingTop: 1
                    }}
                  />
                )}
              </S.OrgName>
            </Text>
          )}

          {!!tags.length && <div>{tags.map(renderTags)}</div>}
        </div>
      </S.Content>
    </S.Wrapper>
  )
}

export default React.memo(Item)
