import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import SVG from 'react-inlinesvg'

import { useBodyNoScroll, useClickOutside, useDidMount } from 'hooks'

import * as S from './styled'

type ModalProps = {
  onClose: () => void
}

const rootElement = document.getElementById('root')

// You know what you're doing...
// But eslint doesn't think so...
/* eslint-disable */
const Modal =
  <T extends object>(Component: React.ComponentType<T>) =>
  ({ onClose, ...props }: ModalProps & T) => {
    const [disableScroll, enableScroll] = useBodyNoScroll()

    const ref = useRef<HTMLDivElement>(null)

    useClickOutside(ref, onClose)

    useDidMount(() => {
      disableScroll()
      return () => enableScroll()
    })

    if (!rootElement) return null

    return createPortal(
      <S.Wrapper>
        <S.Modal {...{ ref }}>
          <S.Close onClick={onClose}>
            <SVG
              src="/assets/icons/close.svg"
              title="Click to close"
              width={15}
              height={15}
            />
          </S.Close>

          <Component {...({ onClose, ...props } as T)} />
        </S.Modal>
      </S.Wrapper>,
      rootElement
    )
  }
/* eslint-enable */

export default Modal
