import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

import { AUTH_TOKEN } from 'constants/global'

const uploadLink = createUploadLink({
  uri: process.env.BACKEND_URL,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(uploadLink as any),
  cache: new InMemoryCache({
    typePolicies: {
      UsersPermissionsUser: {
        merge: true,
      },
      Organization: {
        merge: true,
      },
      Event: {
        merge: true,
      },
      Article: {
        merge: true,
      },
      Single: {
        merge: true,
      },
      UploadFile: {
        merge: true,
      },
      Area: {
        merge: true,
      },
    },
  }),
})

export default client
