import React from 'react'
import { ErrorMessage, Field, FieldAttributes } from 'formik'

import Text from '../../Text'

import * as S from './styled'

type TProps = {
  required?: boolean
  label: React.ReactNode
  isError?: boolean
  defaultDisabled?: boolean
  children?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & FieldAttributes<any>

const Input = ({
  required,
  name,
  label,
  isError,
  type,
  defaultDisabled,
  children,
  ...props
}: TProps) => (
  <S.Wrapper>
    <S.Field {...{ isError, type, defaultDisabled }}>
      <S.Top {...{ type }}>
        <label htmlFor={name}>
          {required && <S.RequiredAsterisk>*</S.RequiredAsterisk>} {label}
        </label>

        {children}
      </S.Top>

      <Field
        id={name}
        disabled={defaultDisabled}
        {...{ name, type, ...props }}
      />
    </S.Field>

    {isError && (
      <Text as="small" variant="error">
        <ErrorMessage {...{ name }} />
      </Text>
    )}
  </S.Wrapper>
)

export default React.memo(Input)
