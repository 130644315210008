import * as Yup from 'yup'

export default Yup.object().shape({
  email: Yup.string()
    .email('Nesprávny formát emailovej adresy!')
    .required('Prosím zadajte emailovú adresu!'),
  body: Yup.string()
    .max(500, 'Dĺžka otázky môže byť maximálne 500 znakov!')
    .required('Prosím zadajte otázku!')
})
