import * as Yup from 'yup'

export default Yup.object().shape({
  note: Yup.string(),
  event: Yup.number(),
  term: Yup.number().required('Prosím vyberte termín.'),
  _isAuthenticated: Yup.boolean().default(false),
  name: Yup.string().when('_isAuthenticated', {
    is: false,
    then: Yup.string().required('Prosím zadajte meno!')
  }),
  surname: Yup.string().when('_isAuthenticated', {
    is: false,
    then: Yup.string().required('Prosím zadajte priezvisko!')
  }),
  birthday: Yup.string().when('_isAuthenticated', {
    is: false,
    then: Yup.string().required('Prosím zadajte dátum narodenia!')
  }),
  phone: Yup.string().when('_isAuthenticated', {
    is: false,
    then: Yup.string().required('Prosím zadajte telefonné číslo!')
  }),
  email: Yup.string().when('_isAuthenticated', {
    is: false,
    then: Yup.string()
      .email('Nesprávny formát emailovej adresy!')
      .required('Prosím zadajte emailovú adresu!')
  })
})
