import React from 'react'
import { RouteObject } from 'react-router'

// Layouts
import { Auth, Main } from 'layouts'

// Auth pages
import {
  SignIn,
  SignUp,
  ChangePassword,
  ForgotPassword,
  Confirmed,
} from 'pages'

// Static pages
import { Home, Profile, About, Team, Contact, Documents } from 'pages'

// Previews
import { Preview, Articles, Organizations } from 'pages'

// Details
import { Detail, Single, Article, Organization } from 'pages'

// Tabs
import { Edit, Events } from 'pages/Profile/sections'

import { PreventAuth, RequireAuth } from 'ui'

export default [
  {
    path: '/',
    element: <Main />,
    children: [
      { path: '', element: <Home /> },
      {
        path: 'profil',
        element: RequireAuth(Profile),
        children: [
          { index: true, element: <Events />, exact: true },
          { path: 'informacie', element: <Edit />, exact: true },
        ],
      },

      { path: 'nase-vlastne-programy', element: <Single /> },

      { path: 'cestou-s-nami', element: <About /> },
      { path: 'nas-tim', element: <Team /> },
      { path: 'kontaktuj-nas', element: <Contact /> },
    ],
  },
  {
    path: 'pre-dobrovolnikov',
    element: <Main />,
    children: [
      { path: 'dobrovolnicke-prilezitosti', element: <Preview /> },
      { path: 'dobrovolnicke-prilezitosti/:slug', element: <Detail /> },

      { path: 'dlhodobe-dobrovolnicke-programy', element: <Preview /> },
      { path: 'dlhodobe-dobrovolnicke-programy/:slug', element: <Detail /> },

      { path: 'podujatia-pre-teba', element: <Preview /> },
      { path: 'podujatia-pre-teba/:slug', element: <Detail /> },

      { path: 'zapoj-sa-do-nasej-hry', element: <Single /> },

      { path: 'programy-na-osobny-rozvoj', element: <Preview /> },
      { path: 'programy-na-osobny-rozvoj/:slug', element: <Detail /> },

      { path: 'motivacia-pribehy-dobrovolnikov', element: <Preview /> },
      { path: 'motivacia-pribehy-dobrovolnikov/:slug', element: <Detail /> },
    ],
  },
  {
    path: 'pre-organizacie',
    element: <Main />,
    children: [
      { path: 'podujatia', element: <Preview /> },
      { path: 'podujatia/:slug', element: <Detail /> },

      {
        path: 'standardy-a-znacka-kvality-prace-s-dobrovolnikmi',
        element: <Single />,
      },

      { path: 'poradenstvo-a-koaching', element: <Single /> },

      {
        path: 'nastroje-na-podporu-prace-s-dobrovolnikmi',
        element: <Preview />,
      },
      {
        path: 'nastroje-na-podporu-prace-s-dobrovolnikmi/:slug',
        element: <Detail />,
      },

      { path: 'ocenovanie', element: <Single /> },

      { path: 'pre-vasich-dobrovolnikov', element: <Preview /> },
      { path: 'pre-vasich-dobrovolnikov/:slug', element: <Detail /> },
    ],
  },
  {
    path: 'centra-ktore-potrebuju-dobrovolnikov',
    element: <Main />,
    children: [
      { path: 'stan-sa-dobrovolnickou-organizaciou', element: <Single /> },

      { path: 'zapoj-sa-do-nasho-programu', element: <Preview /> },
      { path: 'zapoj-sa-do-nasho-programu/:slug', element: <Detail /> },

      { path: 'podpora-na-mieru', element: <Single /> },
    ],
  },
  {
    path: 'inspiracia',
    element: <Main />,
    children: [
      { path: 'dobrovolnicke-organizacie', element: <Organizations /> },
      { path: 'dobrovolnicke-organizacie/:slug', element: <Organization /> },

      { path: 'nastroje-na-podporu', element: <Preview /> },
      { path: 'nastroje-na-podporu/:slug', element: <Detail /> },

      { path: 'clanky', element: <Articles /> },
      { path: 'clanky/:slug', element: <Article /> },

      { path: 'otestuj-sa', element: <Single /> },

      { path: 'dokumenty', element: <Documents /> },
    ],
  },
  {
    path: '/',
    element: PreventAuth(Auth),
    children: [
      { path: 'prihlasenie', element: <SignIn /> },
      { path: 'registracia', element: <SignUp /> },
      { path: 'potvrdeny', element: <Confirmed /> },

      { path: 'zmena-hesla', element: <ForgotPassword /> },
      { path: 'zmena-hesla/:code', element: <ChangePassword /> },
    ],
  },
] as RouteObject[]
