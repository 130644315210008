import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'

import { Point } from './sections'
import { Section, Text } from 'ui'
import { useContext } from 'hooks'

import { GET_POINTS } from 'apollo/queries'
import {
  PointEntity,
  PointRelationResponseCollection
} from 'types/models/point'

import * as S from './styled'

type TQuery = TData<PointRelationResponseCollection, 'points'>

const EventPlaceholder = () => (
  <>
    <Skeleton height={100} />
    <Skeleton height={100} />
    <Skeleton height={73} />
  </>
)

const Events = () => {
  const { user } = useContext('auth')

  const { data, loading } = useQuery<TQuery>(GET_POINTS, {
    variables: { id: user?.id }
  })

  const renderItems = useCallback(
    (item: PointEntity) => <Point key={`${item?.id}-point`} entity={item} />,
    []
  )

  return (
    <Section col gap="xs">
      {!!data?.points?.data?.length && (
        <S.Header>
          <div />
          <div />

          <Text variant="paragraph" color="blue">
            Počet bodov
          </Text>

          <Text variant="paragraph" color="blue">
            Organizácia
          </Text>
        </S.Header>
      )}

      {loading ? (
        <EventPlaceholder />
      ) : data?.points?.data?.length ? (
        data.points.data.map(renderItems)
      ) : (
        <S.EmptyState>
          <img src="/assets/icons/empty.svg" alt="" />
          <Text variant="paragraph" color="grey">
            Zatiaľ nemáte žiadne udalosti!
          </Text>
        </S.EmptyState>
      )}
    </Section>
  )
}

export default React.memo(Events)
