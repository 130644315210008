import styled, { css } from 'styled-components'

interface IImage {
  isHidden?: boolean
}

interface IWrapper {
  isMore?: boolean
}

export const Wrapper = styled.article<IWrapper>`
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;

    min-height: 310px;
    max-height: 523px;
    height: 100%;
    max-width: 100%;

    border-radius: ${({ theme }) => theme.sizes.xxs};
    overflow: hidden;

    @media (max-width: 377px) {
      min-height: 50px;
    }

    & > div:nth-of-type(7) {
      ${({ isMore }) => isMore && showMore}
    }
  }
`

const showMore = css`
  &::before {
    content: 'Zobraziť viac';
    z-index: 2;

    position: absolute;
    inset: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ theme }) => theme.colors.salmon}AA;
    ${({ theme }) => theme.fonts.subheading};
    color: ${({ theme }) => theme.colors.white};
  }
`

export const Image = styled.div<IImage>`
  display: flex;
  flex-basis: 23%;
  flex-grow: 1;

  ${({ isHidden }) => isHidden && 'display: none;'}

  position: relative;
  min-width: 150px;
  height: 211px;

  overflow: hidden;
  cursor: pointer;

  &:hover img {
    transform: scale(1.05);
  }

  img {
    transition: transform 100ms linear;

    height: 100%;
    width: 100%;

    object-position: center;
    object-fit: cover;
  }

  &:nth-of-type(1) {
    min-height: 310px;
  }

  &:nth-of-type(2),
  &:nth-of-type(3) {
    flex-basis: 30%;
    min-height: 310px;
    flex-grow: 2;
  }

  @media (max-width: 377px) {
    &:not(:first-child) {
      display: none;
    }

    &:first-child {
      ${showMore}
    }
  }
`
