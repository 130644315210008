import styled from 'styled-components'

interface IWrapper {
  isSecondary?: boolean
}

export const Wrapper = styled.article<IWrapper>`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: ${({ theme, isSecondary }) =>
      theme.sizes[isSecondary ? 's' : 'm']};
  }
`
