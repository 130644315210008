import React, { useCallback } from 'react'
import SVG from 'react-inlinesvg'
import { DefaultTheme } from 'styled-components'

import { ExternalLink, Section, Stars, Text } from 'ui'
import { OrganizationEntity } from 'types/models/organization'
import { ComponentPhonePhoneNumbers } from 'types/models/components'

import * as S from './styled'

type TProps = {
  entity?: OrganizationEntity
  bg?: keyof DefaultTheme['colors']
}

const OrganizationInfo = ({ entity, bg = 'background' }: TProps) => {
  const renderNumbers = useCallback(
    (item: ComponentPhonePhoneNumbers) => (
      <S.Phone
        href={`tel:${item?.phoneNumber}`}
        key={`${item?.id}-${item?.phoneNumber}`}
      >
        {item?.phoneNumber}
      </S.Phone>
    ),
    []
  )

  return (
    <Section col hideIf={!entity} {...{ bg }}>
      <Text
        as="p"
        variant="subheading"
        color={bg === 'secondaryBackground' ? 'white' : 'blue'}
        mb="xxs"
        uppercase
      >
        Informácie o organizácií
      </Text>

      <S.Link
        to={`/inspiracia/dobrovolnicke-organizacie/${entity?.attributes?.slug}`}
      >
        <S.OrgName>
          <span>{entity?.attributes?.name}</span>
          {!!entity?.attributes?.rating && (
            <Stars
              value={entity.attributes.rating}
              max={3}
              starSize={32}
              starGap={2}
              style={{
                paddingTop: 4
              }}
            />
          )}
        </S.OrgName>
      </S.Link>

      {!!entity?.attributes?.webLink && (
        <S.Web to={entity?.attributes?.webLink}>
          {entity?.attributes?.webLink?.replace(/^http(s)?:\/\//, '')}
        </S.Web>
      )}

      {entity?.attributes?.phoneNumbers?.map(renderNumbers)}

      {entity?.attributes?.address && (
        <Text variant="large" height={30}>
          {entity?.attributes?.address?.street},<br />
          {entity?.attributes?.address?.postcode}{' '}
          {entity?.attributes?.address?.city}
        </Text>
      )}

      {!!entity?.attributes?.socialLinks && (
        <S.Links inverted={bg === 'secondaryBackground'}>
          {!!entity?.attributes?.socialLinks?.facebookLink && (
            <ExternalLink to={entity?.attributes?.socialLinks?.facebookLink}>
              <SVG
                src="/assets/icons/blue-facebook.svg"
                title="facebook"
                width={40}
                height={40}
              />
            </ExternalLink>
          )}

          {!!entity?.attributes?.socialLinks?.instagramLink && (
            <ExternalLink to={entity?.attributes?.socialLinks?.instagramLink}>
              <SVG
                src="/assets/icons/blue-instagram.svg"
                title="instagram"
                width={40}
                height={40}
              />
            </ExternalLink>
          )}

          {!!entity?.attributes?.socialLinks?.youtubeLink && (
            <ExternalLink to={entity?.attributes?.socialLinks?.youtubeLink}>
              <SVG
                src="/assets/icons/blue-youtube.svg"
                title="youtube"
                width={40}
                height={40}
              />
            </ExternalLink>
          )}
        </S.Links>
      )}
    </Section>
  )
}

export default React.memo(OrganizationInfo)
