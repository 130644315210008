import React from 'react'

import { Link, Points, Text } from 'ui'
import { Event } from 'types/models/event'

type TProps = Pick<Event, 'points' | 'capacity'>

const HeaderInfo = ({ points, capacity }: TProps) => {
  return (
    <>
      {!!points && (
        <Points
          points={points}
          size="medium"
          variant="subheading"
          color="white"
        />
      )}

      {!!capacity && (
        <Text variant="subheading" color="white" weight={700}>
          Voľné miesta:{' '}
          <Text as="span" color="salmon">
            {capacity}
          </Text>
        </Text>
      )}

      {!!points && (
        <Link to="/pre-dobrovolnikov/zapoj-sa-do-nasej-hry">
          Získavaj body a využívaj benefity
        </Link>
      )}
    </>
  )
}

export default React.memo(HeaderInfo)
