import * as Yup from 'yup'

export default Yup.object().shape({
  password: Yup.string()
    .min(6, 'Heslo musí mať najmenej 6 znakov')
    .required('Prosím zadajte nové heslo!'),
  passwordConfirmation: Yup.string()
    .required('Prosím zadajte nové heslo znovu!')
    .oneOf([Yup.ref('password'), null], 'Heslá sa nezhodujú!'),
})
