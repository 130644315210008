import React from 'react'

import { Event } from 'types/models/event'
import { Points, Text } from 'ui'

import * as S from './styled'

type TProps = Pick<Event, 'points' | 'title'>

const Success = ({ points, title }: TProps) => (
  <S.Wrapper>
    <Text as="h1" variant="heading1" color="salmon">
      {title}
    </Text>

    <img src="/assets/icons/done.svg" alt="done" width={54} height={54} />

    <Text variant="paragraph" align="center">
      Žiadosť sa úspešne odoslala!
    </Text>

    {!!points && (
      <Points
        points={points}
        size="large"
        variant="subheading"
        color="darkBlue"
        left="Za túto udalosť ste získali"
      />
    )}

    <Text variant="paragraph" align="center">
      Budeme radi, ak túto príležitosť zazdielaš!
    </Text>
  </S.Wrapper>
)

export default React.memo(Success)
