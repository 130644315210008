import styled from 'styled-components'

interface ISeparator {
  text?: string
}

export const Header = styled.header`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: ${({ theme }) => theme.sizes.xs};
  }

  & > div {
    display: flex;
    gap: ${({ theme }) => theme.sizes.s};
    justify-content: space-between;
  }
`

export const Half = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizes.s};

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.sizes.m};
  }
`

export const Sign = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizes.s};
  width: 100%;
`

export const Separator = styled.div<ISeparator>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  margin-block: ${({ theme }) => theme.sizes.s};
  position: relative;

  ::before {
    content: ${({ text }) => (text ? `'${text}'` : "''")};
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.background};
    padding-inline: ${({ theme }) => theme.sizes.s};
  }
`
