import styled from 'styled-components'
import { Link as NLink } from 'react-router-dom'

import { ExternalLink } from 'ui'

interface ILinks {
  inverted?: boolean
}

export const Link = styled(NLink)`
  ${({ theme }) => theme.fonts.heading1};
  color: ${({ theme }) => theme.colors.salmon};
  margin-bottom: ${({ theme }) => theme.sizes.xxl};
  text-decoration: underline;
  text-transform: uppercase;
`

export const Phone = styled.a`
  ${({ theme }) => theme.fonts.large};
  line-height: 30px;
`

export const Links = styled.div<ILinks>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-self: flex-start;
  gap: ${({ theme }) => theme.sizes.s};
  margin-top: ${({ theme }) => theme.sizes.xs};

  #inside,
  #inside > * {
    ${({ theme, inverted }) => inverted && `fill: ${theme.colors.salmon};`}
  }
`

export const Web = styled(ExternalLink)`
  ${({ theme }) => theme.fonts.large};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.salmon};
  line-height: 30px;
`

export const OrgName = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.sizes.s};
  align-items: flex-start;
`
