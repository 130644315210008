import React from 'react'
import { To } from 'react-router'

import * as S from './styled'

type TProps = {
  to: To
  children: React.ReactNode
}

const Link = ({ to, children }: TProps) => (
  <S.Wrapper {...{ to }}>{children}</S.Wrapper>
)

export default React.memo(Link)
