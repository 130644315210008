export enum ENUM_EVENT_CATEGORY {
  dobrovolnicke_prilezitosti = 'dobrovolnicke-prilezitosti',
  dlhodobe_dobrovolnicke_programy = 'dlhodobe-dobrovolnicke-programy',
  podujatia_pre_teba = 'podujatia-pre-teba',
  programy_na_osobny_rozvoj = 'programy-na-osobny-rozvoj',
  motivacia_pribehy_dobrovolnikov = 'motivacia-pribehy-dobrovolnikov',
  podujatia = 'podujatia',
  nastroje_na_podporu_prace_s_dobrovolnikmi = 'nastroje-na-podporu-prace-s-dobrovolnikmi',
  pre_vasich_dobrovolnikov = 'pre-vasich-dobrovolnikov',
  zapoj_sa_do_nasho_programu = 'zapoj-sa-do-nasho-programu',
  nastroje_na_podporu = 'nastroje-na-podporu',
}

export enum ENUM_ORGANIZATION_REGION {
  bratislavsky = 'bratislavsky',
  trnavsky = 'trnavsky',
  trenciansky = 'trenciansky',
  nitriansky = 'nitriansky',
  zilinsky = 'zilinsky',
  banskobystricky = 'banskobystricky',
  presovsky = 'presovsky',
  kosicky = 'kosicky',
  medzinarodne = 'medzinarodne',
}

export enum ENUM_SINGLE_SINGLECATEGORY {
  standardy_a_znacka_kvality_prace_s_dobrovolnikmi = 'standardy-a-znacka-kvality-prace-s-dobrovolnikmi',
  zapoj_sa_do_nasej_hry = 'zapoj-sa-do-nasej-hry',
  poradenstvo_a_koaching = 'poradenstvo-a-koaching',
  nase_vlastne_programy = 'nase-vlastne-programy',
  ocenovanie = 'ocenovanie',
  podpora_na_mieru = 'podpora-na-mieru',
  stan_sa_dobrovolnickou_organizaciou = 'stan-sa-dobrovolnickou-organizaciou',
}
