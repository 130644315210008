import React, { ImgHTMLAttributes } from 'react'

import * as S from './styled'

type TProps = {
  fullWidth?: boolean
  isCircle?: boolean
  bordered?: boolean
  maxWidth?: number
} & ImgHTMLAttributes<HTMLImageElement>

const Image = (props: TProps) => <S.Wrapper {...props} />

export default React.memo(Image)
