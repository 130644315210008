import styled from 'styled-components'

import Slider from 'react-slick'

interface IBackground {
  image: string
  isHidden?: boolean
}

interface IArrow {
  isRight?: boolean
}

export const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 68px);
`

export const StyledSlider = styled(Slider)`
  position: relative;

  .slick-track {
    margin: 0 !important;
  }

  .slick-dots {
    position: absolute;
    bottom: ${({ theme }) => theme.sizes.xxl};

    display: flex !important;
    justify-content: center;

    list-style: none;
    width: 100%;

    .slick-active > button {
      opacity: 1;
    }

    & > li > button {
      width: ${({ theme }) => theme.sizes.xs};
      height: ${({ theme }) => theme.sizes.xs};
      margin-inline: 6px;

      background-color: ${({ theme }) => theme.colors.salmon};
      color: transparent;
      border-radius: 50%;

      opacity: 0.3;
      transition: opacity 100ms;

      &:hover {
        opacity: 1;
      }
    }
  }
`

export const Background = styled.article<IBackground>`
  position: relative;
  height: calc(100vh - 68px);
  padding-inline: 11.5%;
  width: 100%;

  background: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex !important;
  place-items: center;

  & > div {
    width: 100%;
    max-width: 600px;
    margin-inline: auto;
    z-index: 9;

    color: ${({ theme }) => theme.colors.white};
    text-align: center;

    button {
      margin-top: ${({ theme }) => theme.sizes.xxl};
    }
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
`

export const Arrow = styled.button<IArrow>`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  overflow: hidden;

  width: 14%;
  max-width: 170px;

  ${(p) => (p.isRight ? 'right: 0;' : 'left: 0;')}
  ${(p) => !p.isRight && 'transform: rotate(-180deg);'}

  &:hover::before {
    right: calc(50% - 21px);
  }

  &:before {
    content: '';
    background: url('/assets/icons/carousel-arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    position: absolute;
    top: calc(50% - 21px);
    right: -42px;

    transition: right 100ms;

    width: 42px;
    height: 42px;
  }
`

export const Placeholder = styled.article`
  position: relative;
  height: calc(100vh - 68px);
  padding-inline: 11.5%;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background};

  display: flex !important;
  place-items: center;

  & > div {
    width: 100%;
    max-width: 600px;
    margin-inline: auto;
    z-index: 9;
    text-align: center;
  }
`
