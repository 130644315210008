import styled from 'styled-components'

export const Header = styled.header`
  padding-inline: ${({ theme }) => theme.sizes.m};
  display: grid;
  grid-template-columns: 86px 2fr 1fr 2fr;
  gap: ${({ theme }) => theme.sizes.m};

  @media (max-width: 700px) {
    display: none;
  }
`

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 300px;
    aspect-ratio: 1;
    margin-bottom: ${({ theme }) => theme.sizes.s};
  }
`
