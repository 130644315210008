import React, { useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { NavLink, useSearchParams } from 'react-router-dom'

import { Button, Input, Points, Text } from 'ui'
import { useContext, useForm, useMemoDeps, useToggle } from 'hooks'
import validationSchema from 'schemas/signup'

import { REGISTER, TOKEN_REGISTER } from 'apollo/mutations'
import {
  UsersPermissionsLoginPayload,
  UsersPermissionsUserEntityResponse
} from 'types/models/user'
import { UsersPermissionsRegisterInput } from 'types/inputs'

import * as S from './styled'
import { Success } from '../sections'
import { useQuery } from '@apollo/client'
import { GET_UNCONFIRMED_USER_BY_TOKEN } from 'apollo/queries'

type TFields = UsersPermissionsRegisterInput & {
  consent: boolean
}

type TQuery = TData<
  UsersPermissionsUserEntityResponse,
  'getUnconfirmedUserByToken'
>
type TRegisterMutation = TData<UsersPermissionsLoginPayload, 'register'>
type TTokenRegisterMutation = TData<
  UsersPermissionsLoginPayload,
  'tokenRegister'
>

type TRegisterInput = { input: UsersPermissionsRegisterInput }
type TTokenRegisterInput = {
  token: string
  input: UsersPermissionsRegisterInput
}

const SignUp = () => {
  const [searchParams] = useSearchParams()

  const { onLogin } = useContext('auth')

  const { visible, show } = useToggle()

  const token = searchParams.get('t')

  const { data } = useQuery<TQuery>(GET_UNCONFIRMED_USER_BY_TOKEN, {
    variables: { token },
    skip: !token,
    fetchPolicy: 'network-only'
  })

  const {
    onSubmit: handleRegister,
    loading: registerLoading,
    error: registerError
  } = useForm<TRegisterMutation, TRegisterInput>(REGISTER, show)

  const {
    onSubmit: handleTokenRegister,
    loading: tokenRegisterLoading,
    error: tokenRegisterError
  } = useForm<TTokenRegisterMutation, TTokenRegisterInput>(
    TOKEN_REGISTER,
    (data) => onLogin(data?.tokenRegister.jwt)
  )

  const { data: dataMemo } = useMemoDeps({ data })

  const initialValues: TFields = useMemo(
    () => {
      const attributes = data?.getUnconfirmedUserByToken.data?.attributes

      return {
        username: '',
        name: attributes?.name ?? '',
        surname: attributes?.surname ?? '',
        birthday: attributes?.birthday ?? '',
        phone: attributes?.phone ?? '',
        email: attributes?.email ?? '',
        city: '',
        street: '',
        houseNumber: '',
        postcode: '',
        password: '',
        bio: '',
        consent: false
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataMemo]
  )

  const handleSubmit = useCallback(
    async (values: TFields, formikHelpers: FormikHelpers<TFields>) => {
      const input: UsersPermissionsRegisterInput = {
        username: values.email,
        email: values.email,
        password: values.password,
        name: values.name,
        surname: values.surname,
        phone: values.phone,
        birthday: values.birthday,
        city: values.city,
        street: values.street,
        houseNumber: values.houseNumber,
        postcode: values.postcode,
        bio: values.bio
      }

      return token
        ? handleTokenRegister({ token, input }, formikHelpers)
        : handleRegister({ input }, formikHelpers)
    },
    [handleRegister, handleTokenRegister, token]
  )

  if (visible)
    return (
      <Success
        title="Registrácia"
        text={
          <>
            Úspešne ste sa zaregistrovali!
            <br />
            Po overení e-mailu môžete pokračovať do Vášho účtu.
          </>
        }
      />
    )

  return (
    <>
      <S.Wrapper>
        <Text as="h1" variant="heading2" color="salmon" mb="s" align="center">
          Registrácia
        </Text>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange
        >
          {({ errors, touched }) => (
            <Form>
              <S.Half>
                <Input
                  type="text"
                  name="name"
                  label="Meno *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.name && errors.name}
                  required
                />
                <Input
                  type="text"
                  name="surname"
                  label="Priezvisko *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.surname && errors.surname}
                  required
                />
              </S.Half>

              <S.Half>
                <Input
                  type="date"
                  name="birthday"
                  label="Dátum narodenia *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.birthday && errors.birthday}
                  required
                />
                <Input
                  type="text"
                  name="phone"
                  label="Telefónne číslo *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.phone && errors.phone}
                  required
                />
              </S.Half>

              <Input
                type="email"
                name="email"
                label="E-mail *"
                disabled={registerLoading || tokenRegisterLoading || token}
                defaultDisabled={token}
                isError={touched.email && errors.email}
                required
              />

              <S.Half>
                <Input
                  type="text"
                  name="city"
                  label="Mesto *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.city && errors.city}
                  required
                />
                <Input
                  type="text"
                  name="street"
                  label="Ulica *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.street && errors.street}
                  required
                />
              </S.Half>

              <S.Half>
                <Input
                  type="string"
                  name="houseNumber"
                  label="Popisné číslo *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.houseNumber && errors.houseNumber}
                  required
                />
                <Input
                  type="string"
                  name="postcode"
                  label="PSČ *"
                  disabled={registerLoading || tokenRegisterLoading}
                  isError={touched.postcode && errors.postcode}
                  required
                />
              </S.Half>

              <Input
                type="password"
                name="password"
                label="Heslo *"
                disabled={registerLoading || tokenRegisterLoading}
                isError={touched.password && errors.password}
                required
              />

              <Input
                as="textarea"
                name="bio"
                label="Niečo o sebe"
                disabled={registerLoading || tokenRegisterLoading}
                isError={touched.bio && errors.bio}
              />

              <Input
                type="checkbox"
                name="consent"
                label={
                  <>
                    Súhlasím s{' '}
                    <NavLink to="/terms-and-conditions">
                      Všeobecné podmienky a ochrana osobných údajov
                    </NavLink>
                  </>
                }
                disabled={registerLoading || tokenRegisterLoading}
                isError={touched.consent && errors.consent}
                required
              />

              {(!!registerError || !!tokenRegisterError) && (
                <Text as="small" variant="error">
                  {registerError || tokenRegisterError}
                </Text>
              )}

              <Button
                type="submit"
                disabled={registerLoading || tokenRegisterLoading}
                isLoading={registerLoading || tokenRegisterLoading}
              >
                Zaregistrovať sa
              </Button>
            </Form>
          )}
        </Formik>

        <Points
          points={{ confirmed: 10 }}
          variant="bold"
          color="darkBlue"
          left="Za registráciu získate"
        />
      </S.Wrapper>

      <small>
        Vlastníte účet? <NavLink to="/prihlasenie">Prihláste sa!</NavLink>
      </small>
    </>
  )
}

export default React.memo(SignUp)
