import React from 'react'
import { DefaultTheme } from 'styled-components'

import { Gallery as GalleryUI } from 'components'
import { Section, Text } from 'ui'
import { UploadFileEntity } from 'types/models/file'

type TProps = {
  collection?: UploadFileEntity[]
  bg?: keyof DefaultTheme['colors']
}

const Gallery = ({ collection, bg = 'background' }: TProps) => (
  <Section col hideIf={!collection?.length} {...{ bg }}>
    <Text as="h2" variant="heading2" color="salmon" mb="xxl" uppercase>
      Galéria fotiek
    </Text>

    <GalleryUI data={collection!} />
  </Section>
)

export default React.memo(Gallery)
