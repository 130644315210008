import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${({ theme }) => theme.sizes.xxl};

  width: 100%;
  height: 100%;

  /* one item */
  & > *:first-child:nth-last-child(1) {
    max-width: 50%;
    margin-inline: auto;

    @media (max-width: 800px) {
      max-width: 100%;
    }
  }
`
