import React from 'react'
import { Section, Text } from 'ui'

const Description = () => (
  <Section bg="blue">
    <Text variant="large" color="white" align="center">
      <Text as="span" weight={700}>
        Žilinské dobrovoľnícke centrum
      </Text>{' '}
      je súčasťou
    </Text>

    <Text variant="large" color="salmon" align="center">
      <Text as="span" weight={700}>
        VIAC
      </Text>{' '}
      - Inštitút pre podporu a rozvoj mládeže.
    </Text>
  </Section>
)

export default React.memo(Description)
