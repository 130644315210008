import React, { HTMLAttributes } from 'react'
import { DefaultTheme } from 'styled-components'

import WithLoading from '../WithLoading'
import { TTextElements } from 'types/global'

import * as S from './styled'

type TProps = {
  variant?: keyof DefaultTheme['fonts']
  color?: keyof DefaultTheme['colors']
  as?: TTextElements
  children: React.ReactNode
  mb?: keyof DefaultTheme['sizes']
  align?: 'left' | 'right' | 'center' | 'justify'
  uppercase?: boolean
  size?: number
  height?: number
  weight?: number
} & HTMLAttributes<HTMLParagraphElement>

const Text = ({ as = 'p', children, ...props }: TProps) => (
  <S.Wrapper {...{ as, ...props }}>{children}</S.Wrapper>
)

export default React.memo(WithLoading(Text))
