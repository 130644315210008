import React, { useEffect } from 'react'

import { Button, Container, Points } from 'ui'
import { EventEntity } from 'types/models/event'

import * as S from './styled'

type TProps = {
  entity?: EventEntity
  show: () => void
}

const InterestFixed = ({ entity, show }: TProps) => {
  useEffect(() => {
    document.body.style.paddingBottom = '126px'

    return () => {
      document.body.style.paddingBottom = '0px'
    }
  }, [entity])

  return (
    <S.Wrapper>
      <Container>
        <Button onClick={show}>Mám záujem</Button>

        <Points
          points={{ confirmed: entity?.attributes?.points }}
          size="large"
          variant="subheading"
          color="darkBlue"
          left="Za túto udalosť získate"
        />
      </Container>
    </S.Wrapper>
  )
}

export default React.memo(InterestFixed)
