import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { NavLink } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router'

import { Success } from '../sections'
import { Button, Input, Text } from 'ui'
import { useForm, useToggle } from 'hooks'
import validationSchema from 'schemas/forgotPassword'

import { FORGOT_PASSWORD } from 'apollo/mutations'
import { UsersPermissionsPasswordPayload } from 'types/models/user'

import * as S from './styled'

type TFields = {
  email: string
}

const initialValues: TFields = {
  email: '',
}

type TMutation = TData<UsersPermissionsPasswordPayload, 'forgotPassword'>

const ForgotPassword = () => {
  const { visible, show } = useToggle()

  const navigate = useNavigate()
  const { search } = useLocation()

  useEffect(() => {
    const code = new URLSearchParams(search).get('code')
    if (code) navigate(`/zmena-hesla/${code}`)
  }, [search])

  const { onSubmit, loading, error } = useForm<TMutation, TFields>(
    FORGOT_PASSWORD,
    show
  )

  if (visible)
    return (
      <Success
        title="Zmena hesla"
        text="Na zadaný e-mail sme Vám poslali link na zmenu hesla."
      />
    )

  return (
    <>
      <S.Wrapper>
        <Text as="h1" variant="heading2" color="salmon" mb="s" align="center">
          Zmena hesla
        </Text>

        <Formik
          {...{ initialValues, validationSchema, onSubmit }}
          validateOnChange
        >
          {({ errors, touched }) => (
            <Form>
              <Input
                type="email"
                name="email"
                label="E-mail"
                disabled={loading}
                isError={touched.email && errors.email}
                required
              />

              {!!error && (
                <Text as="small" variant="error">
                  {error}
                </Text>
              )}

              <Button type="submit" disabled={loading} isLoading={loading}>
                Zmeniť heslo
              </Button>
            </Form>
          )}
        </Formik>
      </S.Wrapper>

      <small>
        Pamätáte si heslo? <NavLink to="/prihlasenie">Prihláste sa!</NavLink>
      </small>
    </>
  )
}

export default React.memo(ForgotPassword)
