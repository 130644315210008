import { gql } from '@apollo/client'

import { FILE_FIELDS, REFERENCE_COLLECTION_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}
  ${REFERENCE_COLLECTION_FIELDS}

  query getHomepage($now: Date!) {
    events {
      data {
        id
        attributes {
          title
          location
          slug
        }
      }
    }
    homepage {
      data {
        id
        attributes {
          references {
            ...ReferenceCollectionFields
          }
          carousel {
            id
            image {
              ...FileFields
            }
            title
            description
            link
          }
          weeklyChallenge {
            data {
              id
              attributes {
                title
                slug
                description
                link
                points
                image {
                  ...FileFields
                }
              }
            }
          }
          events(sort: ["createdAt:desc"]) {
            data {
              id
              attributes {
                category
                title
                slug
                description
                points
                capacity
                location
                terms(
                  filters: { beginning: { gte: $now } }
                  pagination: { limit: 1 }
                ) {
                  data {
                    id
                    attributes {
                      beginning
                    }
                  }
                }
                coverImage {
                  data {
                    id
                    attributes {
                      url
                      alternativeText
                      formats
                    }
                  }
                }
                organization {
                  data {
                    id
                    attributes {
                      name
                      rating
                    }
                  }
                }
                areas(sort: ["name:asc"]) {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          lastWinner {
            id
            description
            user {
              data {
                id
                attributes {
                  name
                  surname
                  avatar {
                    ...FileFields
                  }
                  points {
                    data {
                      attributes {
                        count
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
