import styled from 'styled-components'
import { Link as NLink } from 'react-router-dom'

export const Wrapper = styled.article`
  display: grid;
  grid-template-columns: 86px 1fr;
  gap: ${({ theme }) => theme.sizes.m};

  @media (max-width: 700px) {
    grid-template-columns: 1fr 2fr;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
  }

  background-color: ${({ theme }) => theme.colors.salmon};
  border-radius: ${({ theme }) => theme.sizes.xxs};
  padding: ${({ theme }) => theme.sizes.m};

  img {
    border-radius: ${({ theme }) => theme.sizes.xxs};
    margin-right: ${({ theme }) => theme.sizes.m};
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: ${({ theme }) => theme.sizes.m};
  align-content: center;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.sizes.s};
  }

  & > div {
    display: flex;
    flex-direction: column;

    color: ${({ theme }) => theme.colors.white};

    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const Link = styled(NLink)`
  ${({ theme }) => theme.fonts.subheading};
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.sizes.xxxs};
`

export const OrgName = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.sizes.xxs};
  align-items: flex-start;
`
