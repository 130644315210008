import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getSingle($category: String!) {
    singles(filters: { singleCategory: { eq: $category } }) {
      data {
        id
        attributes {
          title
          singleCategory
          description
          body
          videoLink
          webLink
          gallery {
            data {
              id
              attributes {
                url
              }
            }
          }
          contactUs {
            id
            email
            name
          }
          references {
            data {
              id
              attributes {
                rating
                name
                position
                description
                avatar {
                  ...FileFields
                }
              }
            }
          }
          articles {
            data {
              id
              attributes {
                title
                description
                slug
                body
              }
            }
          }
        }
      }
    }
  }
`
