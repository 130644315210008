import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import SimpleReactLightbox from 'simple-react-lightbox'

import client from 'apollo'
import App from './App'

import { AuthProvider } from 'contexts'

import { GlobalStyles, theme } from 'styles'

import 'slick-carousel/slick/slick.css'
import 'react-loading-skeleton/dist/skeleton.css'

// TODO: snackbars

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ApolloProvider {...{ client }}>
    <ThemeProvider {...{ theme }}>
      <BrowserRouter>
        <AuthProvider>
          <SimpleReactLightbox>
            <App />
          </SimpleReactLightbox>
        </AuthProvider>
      </BrowserRouter>
      <GlobalStyles />
    </ThemeProvider>
  </ApolloProvider>
)
