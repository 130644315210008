import React from 'react'
import { Outlet } from 'react-router'

import { Navigation, Footer } from 'components'

import * as S from './styled'

const Main = () => (
  <S.Wrapper>
    <Navigation />

    <S.Content id="main">
      <Outlet />
    </S.Content>

    <Footer />
  </S.Wrapper>
)

export default Main
