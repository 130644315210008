import React, { useCallback } from 'react'

import { Button, Text } from 'ui'
import { ComponentCarouselImage } from 'types/models/components'
import { openBlank } from 'utils/functions'

import * as S from './styled'
import Skeleton from 'react-loading-skeleton'

type Props = {
  slides?: ComponentCarouselImage[]
  loading?: boolean
}

const settings = {
  prevArrow: <S.Arrow />,
  nextArrow: <S.Arrow isRight />,
  dots: true,
  infinite: true,
  speed: 1500,
  autoplay: true,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  pauseOnHover: false
}

const Background = ({ slides, loading }: Props) => {
  const renderSlides = useCallback(
    (item: ComponentCarouselImage, idx: number) => (
      <S.Background
        key={`slide-${idx}`}
        image={
          item?.image?.data?.attributes?.formats?.xlarge?.url ||
          item?.image?.data?.attributes?.formats?.large?.url ||
          item?.image?.data?.attributes?.url ||
          ''
        }
      >
        <div>
          <Text as="h1" variant="heading1" mb="m" uppercase>
            {item.title}
          </Text>

          <Text variant="large">{item?.description}</Text>

          {!!item?.link && (
            <Button onClick={() => openBlank(item?.link)}>Zistiť viac</Button>
          )}
        </div>
      </S.Background>
    ),
    []
  )

  return (
    <S.Wrapper>
      {loading ? (
        <S.Placeholder>
          <div>
            <Skeleton height={45} width="80%" style={{ marginBottom: 24 }} />
            <Skeleton count={3} />
            <Skeleton width="60%" />
            <Skeleton width={114} height={47} style={{ marginTop: 48 }} />
          </div>
        </S.Placeholder>
      ) : (
        <S.StyledSlider {...settings}>
          {slides?.map(renderSlides)}
        </S.StyledSlider>
      )}
    </S.Wrapper>
  )
}

export default React.memo(Background)
