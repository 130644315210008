import styled from 'styled-components'

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizes.xxxs};

  min-height: 180px;
  padding: ${({ theme: { sizes } }) => `${sizes.m} ${sizes.l}`};

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.sizes.xxs};
  box-shadow: ${({ theme }) => theme.shadows.term};

  p {
    font-size: 24px;
    line-height: 19px;
    font-weight: 700;
  }
`
