import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getTeam {
    team {
      data {
        id
        attributes {
          members {
            id
            name
            position
            description
            image {
              ...FileFields
            }
          }
        }
      }
    }
  }
`
