import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled(Link)`
  height: 358px;

  position: relative;
  overflow: hidden;
  padding: 10px;

  background-color: ${({ theme }) => theme.colors.salmon};
  box-shadow: ${({ theme }) => theme.shadows.modal};
  border-radius: 6px;

  color: ${({ theme }) => theme.colors.white};

  & > img {
    width: 100%;
    height: 100%;

    border-radius: ${({ theme }) => theme.sizes.xxs};
    object-fit: cover;
    object-position: center;
  }
`

export const Content = styled.article`
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  padding-bottom: ${({ theme }) => theme.sizes.xxl};

  h1,
  h2,
  p,
  span {
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 10px;

    background-color: ${({ theme }) => theme.colors.overlay};
    border-radius: ${({ theme }) => theme.sizes.xxs};
  }

  & > div {
    display: flex;

    &:first-of-type {
      justify-content: space-between;

      span {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    &:last-of-type {
      flex-direction: column;
      gap: 10px;
      z-index: 1;
    }
  }
`

export const OrgName = styled.div`
  display: flex;
  column-gap: 6px;
  align-items: flex-start;
`
