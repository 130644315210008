import styled from 'styled-components'

export const Wrapper = styled.article`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 66px;

  a {
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover img {
      filter: brightness(1) grayscale(0);
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: none;

    transition:
      filter 0s ease 0s,
      opacity 400ms ease 0s;
    filter: brightness(0.97) grayscale(100%);
    opacity: 0.5;
  }
`
