import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getAbout {
    about {
      data {
        id
        attributes {
          description
          image {
            ...FileFields
          }
          videoLink
          annualReports(sort: ["year:desc"]) {
            id
            year
            document {
              data {
                id
                attributes {
                  url
                }
              }
            }
            coverImage {
              ...FileFields
            }
          }
          cooperations {
            data {
              id
              attributes {
                url
                alternativeText
                caption
              }
            }
          }
        }
      }
    }
  }
`
