import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 126px;
  text-align: center;

  @media (max-width: 800px) {
    gap: ${({ theme }) => theme.sizes.section};
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: ${({ theme }) => theme.sizes.l};
  }

  article {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.sizes.xs};

    min-height: 148px;
    padding: ${({ theme }) => theme.sizes.m};

    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.sizes.xxs};
    box-shadow: ${({ theme }) => theme.shadows.card};

    &:last-of-type {
      p {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`
