import { ENUM_ORGANIZATION_REGION } from 'types/enums'

type TRegions = {
  [key in ENUM_ORGANIZATION_REGION]: string
}

export default {
  [ENUM_ORGANIZATION_REGION.bratislavsky]: 'Bratislavský kraj',
  [ENUM_ORGANIZATION_REGION.trnavsky]: 'Trnavský kraj',
  [ENUM_ORGANIZATION_REGION.trenciansky]: 'Trenčiansky kraj',
  [ENUM_ORGANIZATION_REGION.nitriansky]: 'Nitriansky kraj',
  [ENUM_ORGANIZATION_REGION.zilinsky]: 'Žilinský kraj',
  [ENUM_ORGANIZATION_REGION.banskobystricky]: 'Banskobystrický kraj',
  [ENUM_ORGANIZATION_REGION.presovsky]: 'Prešovský kraj',
  [ENUM_ORGANIZATION_REGION.kosicky]: 'Košický kraj',
  [ENUM_ORGANIZATION_REGION.medzinarodne]: 'Medzinárodné',
} as TRegions
