import { useState, useCallback } from 'react'
import { DocumentNode } from 'graphql'
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client'

const NETWORK_ERRORS = {
  BAD_USER_INPUT: 'Prosím vyplňte všetky hodnoty.',
  DEFAULT: 'Niečo sa pokazilo, skúste to znovu.',
}

const GRAPHQL_ERRORS = {
  'Email is already taken': {
    field: 'email',
    message: 'Emailová adresa už je zaregistrovaná.',
  },
  'This email does not exist': {
    field: 'email',
    message: 'Emailová adresa neexistuje.',
  },
  'Invalid identifier or password': {
    field: 'identifier',
    message: 'Nesprávna emailová adresa alebo heslo.',
  },
  'Old password does not match': {
    field: 'currentPassword',
    message: 'Aktuálne heslo nie je správne.',
  },
  'Passwords does not match': {
    field: 'confirmPassword',
    message: 'Heslá sa nezhodujú.',
  },
  DEFAULT: {
    field: 'email',
    message: 'Niečo sa pokazilo, skúste to znovu.',
  },
}

type UseForm<K> = {
  onSubmit: (variables: K, { setFieldError }: any) => Promise<void>
  loading: boolean
  error: string | null
}

export default <T, K extends OperationVariables | undefined>(
  mutation: DocumentNode | TypedDocumentNode<T, OperationVariables>,
  cb?: (data: T | null | undefined) => void,
  options?: MutationFunctionOptions<
    T,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >
): UseForm<K> => {
  const [error, setError] = useState<string | null>(null)

  const [fn, { loading }] = useMutation<T>(mutation)

  const onSubmit = useCallback(
    async (variables: K, { setFieldError }) => {
      setError(null)

      fn({ variables, ...options })
        .then(({ data }) => cb?.(data))
        .catch((err) => {
          const network = err?.networkError?.result
          const graphql = err?.graphQLErrors

          if (network?.length) {
            const picked =
              NETWORK_ERRORS?.[network?.[0]?.extensions?.code || 'DEFAULT']
            setError(picked)
          } else if (graphql?.length) {
            const picked = GRAPHQL_ERRORS?.[graphql?.[0]?.message || 'DEFAULT']
            setFieldError(picked?.field, picked?.message)
          } else {
            console.error(JSON.stringify(err, null, 2))
          }
        })
    },
    [fn, cb, mutation]
  )

  return { onSubmit, loading, error }
}
