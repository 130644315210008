import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import Skeleton from 'react-loading-skeleton'

import { Section } from 'ui'
import { Header } from 'components'
import { AnnualReports, Cooperations, Description, Video } from './sections'

import { GET_ABOUT } from 'apollo/queries'
import { AboutEntityResponse } from 'types/models/about'

type TQuery = TData<AboutEntityResponse, 'about'>

const scrollToSection = (hash: string) => {
  const section = document.getElementById(hash)
  const scroll = () => section?.scrollIntoView({ behavior: 'smooth' })
  setTimeout(scroll, 200)
}

const About = () => {
  const { data, loading } = useQuery<TQuery>(GET_ABOUT)

  const { hash } = useLocation()

  const content = useMemo(() => data?.about?.data?.attributes, [data])

  useEffect(() => {
    if (hash) scrollToSection(hash)
  }, [hash])

  return (
    <>
      <Header key={`about-us-${data?.about?.data?.id}`} />

      <Description model={content} loading={loading} />

      {loading ? (
        <Section bg="blue">
          <Skeleton height={380} />
        </Section>
      ) : (
        <Video src={content?.videoLink} />
      )}

      <AnnualReports collection={content?.annualReports} />

      <Cooperations collection={content?.cooperations?.data} />
    </>
  )
}

export default React.memo(About)
