import styled from 'styled-components'

export const Link = styled.a`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.salmon};
  text-decoration: underline;
`

export const Info = styled.div`
  @media (max-width: 580px) {
    text-align: center;

    & + & {
      margin-top: ${({ theme }) => theme.sizes.l};
    }
  }
`
