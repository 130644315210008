import React from 'react'

import * as S from './styled'

type TProps = {
  to: string
  children: React.ReactNode
  classNames?: string
}

const ExternalLink = ({ to, children, ...props }: TProps) => (
  <S.Wrapper href={to} target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </S.Wrapper>
)

export default React.memo(ExternalLink)
