import React, { useCallback } from 'react'
import { DefaultTheme } from 'styled-components'

import { Item } from 'components'
import { Grid, Section, Text } from 'ui'
import { EventEntity } from 'types/models/event'

type TProps = {
  collection?: EventEntity[]
  title: string
  bg?: keyof DefaultTheme['colors']
}

const Events = ({ collection, title, bg = 'background' }: TProps) => {
  const renderItems = useCallback(
    ({ attributes: item }: EventEntity) =>
      item ? (
        <Item key={`${item?.category}-${item?.slug}`} data={item} />
      ) : null,
    []
  )

  return (
    <Section hideIf={!collection?.length} {...{ bg }}>
      <Text
        as="h2"
        variant="heading2"
        color="salmon"
        mb="xxl"
        align="center"
        uppercase
      >
        {title}
      </Text>

      <Grid>{collection?.map(renderItems)}</Grid>
    </Section>
  )
}

export default React.memo(Events)
