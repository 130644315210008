import styled from 'styled-components'

interface IWrapper {
  isActive?: boolean
  inverted?: boolean
}

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  width: 100%;
  max-width: 200px;

  & > button {
    position: relative;

    background-color: ${({ theme, inverted }) =>
      theme.colors[inverted ? 'blue' : 'white']};
    border: 1px solid ${({ theme }) => theme.colors.salmon};
    border-radius: ${({ theme }) => theme.sizes.xxs};

    width: 100%;
    padding: 14px 18px;
    text-align: left;

    color: ${({ theme, inverted }) =>
      theme.colors[inverted ? 'white' : 'grey']};
    ${({ theme }) => theme.fonts.bold};

    &::after {
      content: '';

      position: absolute;
      right: 20px;
      top: calc(50% - 3px);

      background: url('/assets/icons/arrow-down.svg');
      background-position: center;

      width: 8px;
      height: 6px;

      transition: transform 150ms linear;
      ${({ isActive }) => isActive && 'transform: scale(-1, -1);'}
    }
  }
`

export const Content = styled.div<IWrapper>`
  z-index: 99;
  position: absolute;
  top: 100%;
  left: 0;

  width: 76vw;
  max-width: 1000px;
  padding: 20px;

  background-color: ${({ theme, inverted }) =>
    theme.colors[inverted ? 'blue' : 'background']};
  border-radius: ${({ theme }) => theme.sizes.xxs};
  box-shadow: ${({ theme }) => theme.shadows.filter};

  & > button {
    position: absolute;
    right: 20px;

    ${({ theme }) => theme.fonts.bold};
    color: ${({ theme }) => theme.colors.salmon};
  }

  div + p {
    margin-top: ${({ theme }) => theme.sizes.s};
  }
`

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const Item = styled.button<IWrapper>`
  align-self: flex-start;
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.colors.salmon};
  border-radius: ${({ theme }) => theme.sizes.xxs};

  display: block;
  padding: 14px 18px;

  background-color: ${({ theme, isActive, inverted }) =>
    theme.colors[isActive ? 'salmon' : inverted ? 'blue' : 'white']};
  color: ${({ theme, isActive, inverted }) =>
    theme.colors[isActive ? 'white' : inverted ? 'white' : 'grey']};

  text-align: left;
  ${({ theme }) => theme.fonts.bold};
`
