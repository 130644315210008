import React, { useCallback, useMemo, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { omit } from 'lodash'

import { Success } from './sections'
import { Button, Input, Modal, Pick, Points, Select, Text } from 'ui'
import { useContext, useForm, useToggle } from 'hooks'
import { EventEntity } from 'types/models/event'
import validationSchema from 'schemas/interest'
import { toInterval } from 'utils/date'

import { CREATE_INTEREST, UPLOAD } from 'apollo/mutations'
import {
  InterestFiltersInput,
  InterestInput,
  InterestUserInput
} from 'types/inputs'
import { InterestEntityResponse } from 'types/models/interest'
import { TermEntity } from 'types/models/term'

import * as S from './styled'
import { useLocation, useNavigate } from 'react-router'

type TProps = {
  entity?: EventEntity
  terms?: TermEntity[]
  onClose: () => void
}

type TMutation = TData<InterestEntityResponse, 'createInterest'>

type TFormValues = InterestInput &
  InterestUserInput & { _isAuthenticated?: boolean }

type TCreateInterest = {
  data: InterestInput
  user: InterestUserInput
  filters: InterestFiltersInput
}

const DEFAULT_DATE = new Date().toISOString().substring(0, 10)

const Interest = ({ entity, terms }: TProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { visible, show } = useToggle()
  const [image, setImage] = useState<File | null>()

  const { isAuthenticated, user } = useContext('auth')

  const initialValues = useMemo((): TFormValues => {
    return {
      note: '',
      event: entity?.id,
      term: terms?.[0]?.id,
      appendix: undefined,
      email: '',
      name: '',
      surname: '',
      phone: '',
      birthday: '',
      _isAuthenticated: isAuthenticated
    }
  }, [entity?.id, isAuthenticated, terms])

  const changedTerms = useMemo(
    () =>
      terms?.map(({ id, attributes: item }: TermEntity) => ({
        value: id,
        label: item ? toInterval(item) : ''
      })),
    [terms]
  )

  const [upload, { loading: uploadLoading }] = useMutation(UPLOAD)

  const { onSubmit, loading, error } = useForm<TMutation, TCreateInterest>(
    CREATE_INTEREST,
    show
  )

  const handleSubmit = useCallback(
    async (values: TFormValues, formikHelpers: FormikHelpers<TFormValues>) => {
      let newFile: Awaited<ReturnType<typeof upload>> | null = null
      if (image) newFile = await upload({ variables: { file: image } })

      const filters: InterestFiltersInput = user?.id
        ? { user: { id: { eq: user.id } } }
        : { user: { email: { eq: values.email } } }

      await onSubmit(
        {
          user: {
            name: values.name,
            surname: values.surname,
            phone: values.phone,
            birthday: values.birthday || DEFAULT_DATE,
            email: values.email
          },
          data: {
            event: values.event,
            term: values.term,
            note: values.note,
            ...(!!newFile && {
              appendix: newFile?.data?.upload?.data?.id
            })
          },
          filters
        },
        formikHelpers
      )
    },
    [image, upload, user?.id, onSubmit]
  )

  if (visible)
    return (
      <Success
        isAuthenticated={isAuthenticated}
        points={entity?.attributes?.points}
        title={entity?.attributes?.title || ''}
      />
    )

  return (
    <>
      <S.Header>
        <div>
          <Text as="h1" variant="subheading" color="darkBlue">
            Mám záujem
          </Text>

          {isAuthenticated && (
            <Text as="small" variant="link" color="darkBlue">
              Ste prihlásený ako:{' '}
              <Text as="span" color="salmon">
                {user?.email}
              </Text>
            </Text>
          )}
        </div>

        <Text as="h2" variant="heading1" color="salmon">
          {entity?.attributes?.title}
        </Text>

        <Points
          points={{ confirmed: entity?.attributes?.points }}
          size="large"
          variant="subheading"
          color="darkBlue"
          left="Za túto udalosť získate"
        />

        {entity?.attributes?.additionalInformations?.place && (
          <Text variant="bold">
            Miesto:{' '}
            <Text as="span" color="salmon">
              {entity?.attributes?.additionalInformations?.place}
            </Text>
          </Text>
        )}
      </S.Header>

      <Text variant="paragraph" align="justify">
        {entity?.attributes?.description}
      </Text>

      <Formik
        {...{ initialValues, validationSchema }}
        onSubmit={handleSubmit}
        validateOnChange
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            {!isAuthenticated && (
              <S.Sign>
                <Button
                  isWide
                  disabled={loading || uploadLoading}
                  isLoading={loading || uploadLoading}
                  onClick={() =>
                    navigate('/prihlasenie', {
                      state: {
                        from: omit(location, 'state'),
                        modal: 'interest'
                      }
                    })
                  }
                >
                  Prihlásiť sa
                </Button>

                <S.Separator text="alebo" />

                <S.Half>
                  <Input
                    type="text"
                    name="name"
                    label="Meno *"
                    disabled={loading}
                    isError={touched.name && errors.name}
                    required
                  />
                  <Input
                    type="text"
                    name="surname"
                    label="Priezvisko *"
                    disabled={loading}
                    isError={touched.surname && errors.surname}
                    required
                  />
                </S.Half>

                <S.Half>
                  <Input
                    type="date"
                    name="birthday"
                    label="Dátum narodenia *"
                    disabled={loading}
                    isError={touched.birthday && errors.birthday}
                    required
                  />
                  <Input
                    type="text"
                    name="phone"
                    label="Telefónne číslo *"
                    disabled={loading}
                    isError={touched.phone && errors.phone}
                    required
                  />
                </S.Half>

                <Input
                  type="email"
                  name="email"
                  label="E-mail *"
                  disabled={loading}
                  isError={touched.email && errors.email}
                  required
                />

                <S.Separator />
              </S.Sign>
            )}

            <Select
              name="term"
              label="Vyberte si termín"
              options={changedTerms}
              disabled={loading}
              isError={touched.term && errors.term}
            />

            <Input
              as="textarea"
              name="note"
              label="Poznámka"
              disabled={loading}
              isError={touched.note && errors.note}
            />

            <Pick
              label="Pridať prílohu"
              description={entity?.attributes?.note}
              setForm={setImage}
            />

            {!!error && (
              <Text as="small" variant="error">
                {error}
              </Text>
            )}

            <Button
              type="submit"
              disabled={loading || uploadLoading}
              isLoading={loading || uploadLoading}
            >
              Mám záujem
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Modal<TProps>(Interest)
