import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Navigate, useRoutes } from 'react-router-dom'

import routes from 'config/routes'

const App = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return useRoutes([
    ...routes,
    {
      path: '*',
      element: <Navigate to="/" />,
    },
  ])
}

export default App
