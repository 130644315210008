import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Image } from 'components'
import { Markdown, Section, Text } from 'ui'
import { About } from 'types/models/about'

type TProps = {
  model?: About
  loading?: boolean
}

const Description = ({ model, loading }: TProps) => (
  <Section flex="both" width={300} row gap="l">
    <div>
      <Text as="h2" variant="heading2" color="salmon" mb="s" uppercase>
        Naša vízia
      </Text>

      {loading ? (
        <>
          <Skeleton count={4} />
          <Skeleton width="80%" style={{ marginBottom: 16 }} />
          <Skeleton count={4} />
          <Skeleton width="80%" />
        </>
      ) : (
        <Markdown>{model?.description || ''}</Markdown>
      )}
    </div>

    <div>
      <Image
        src={
          model?.image?.data?.attributes?.formats?.xlarge?.url ||
          model?.image?.data?.attributes?.formats?.large?.url ||
          model?.image?.data?.attributes?.url ||
          ''
        }
        alt={model?.image?.data?.attributes?.caption}
        width={479}
        height={341}
        fullWidth
      />
    </div>
  </Section>
)

export default React.memo(Description)
