import styled from 'styled-components'

interface IWrapper {
  image?: string
  bordered?: boolean
}

export const Wrapper = styled.header<IWrapper>`
  width: 100%;

  background: ${({ theme, image }) =>
    `linear-gradient(${theme.colors.blue}${image ? 'DC' : 'FF'}, ${
      theme.colors.blue
    }${image ? 'CC' : 'FF'})${image ? `, url(${image})` : ''} `};

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding: ${({ theme }) => theme.sizes.section} 0;

  & > div {
    display: flex;
    gap: ${({ theme }) => theme.sizes.l};

    & > div {
      width: 100%;
    }

    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  h1 {
    display: flex;

    > a {
      display: inline-flex;
      padding: ${({ theme: { sizes } }) => `${sizes.xs} ${sizes.s}`};
      margin-left: ${({ theme }) => `-${theme.sizes.s}`};
    }
  }

  & > div > img {
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.salmon};
    ${({ theme, bordered }) =>
      bordered && `border: 10px solid ${theme.colors.salmon};`};
    object-fit: cover;
    object-position: center;
  }
`

export const TitleWithRating = styled.div`
  display: inline-flex;
  column-gap: ${({ theme }) => theme.sizes.xs};
  align-items: flex-start;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.l};
  margin-top: ${({ theme }) => theme.sizes.s};

  flex-wrap: wrap;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: ${({ theme }) => theme.sizes.s};
  }
`
