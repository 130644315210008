import React from 'react'
import { DefaultTheme } from 'styled-components'

import * as S from './styled'

type TProps = {
  children: React.ReactNode
  variant?: keyof DefaultTheme['fonts']
}

const Tag = ({ children, ...props }: TProps) => (
  <S.Wrapper {...props}>{children}</S.Wrapper>
)

export default React.memo(Tag)
