import React, { useMemo } from 'react'

import { Section, Text } from 'ui'

import * as S from './styled'

const Newsletter = () => {
  const src = useMemo(
    () =>
      `https://www.salesmanago.com/mscf/iuacz9dkehxwt59b/default/Chcem_odoberat_newsletter.htm?${Date.now()}`,
    []
  )

  return (
    <Section col vCenter>
      <Text
        as="h2"
        variant="heading2"
        color="salmon"
        align="center"
        mb="xxl"
        uppercase
      >
        Newsletter
      </Text>

      <S.Wrapper {...{ src }} />
    </Section>
  )
}

export default React.memo(Newsletter)
