import styled from 'styled-components'

export const EmptyStateImage = styled.img`
  width: 304px;
  aspect-ratio: 1;
  filter: grayscale(1);
`

export const DocumentsTable = styled.table`
  width: 100%;

  th {
    ${({ theme }) => theme.fonts.paragraph};

    padding: 10px 20px;
    color: ${({ theme }) => theme.colors.blue};
    text-align: left;
  }

  tr td {
    padding-bottom: 12px;

    &:first-of-type div {
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      div {
        border-radius: 0 8px 8px 0;
        text-align: right;
      }
    }

    div {
      padding: 20px;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.salmon};
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.5px;

      a {
        color: ${({ theme }) => theme.colors.success};
        text-decoration: underline;
      }
    }
  }
`
