import React, { ButtonHTMLAttributes } from 'react'
import { DefaultTheme } from 'styled-components'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  isSecondary?: boolean
  isWide?: boolean
  isLoading?: boolean
  transparent?: boolean
  mb?: keyof DefaultTheme['sizes']
  mr?: keyof DefaultTheme['sizes']
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({ children, ...props }: Props) => (
  <S.Wrapper {...{ ...props }}>{children}</S.Wrapper>
)

export default Button
