import React, { useCallback, useMemo, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { omit } from 'lodash'

import { Success } from './sections'
import { Button, Input, Modal, Pick, Points, Select, Text } from 'ui'
import { useContext, useForm, useToggle } from 'hooks'
import { EventEntity } from 'types/models/event'
import validationSchema from 'schemas/interest'
import { toInterval } from 'utils/date'

import { CREATE_INTEREST, UPLOAD } from 'apollo/mutations'
import { InterestInput } from 'types/inputs'
import { InterestEntityResponse } from 'types/models/interest'
import { TermEntity } from 'types/models/term'

import * as S from './styled'

type TProps = {
  entity?: EventEntity
  terms?: TermEntity[]
  onClose: () => void
}

type TMutation = TData<InterestEntityResponse, 'createInterest'>

const Interest = ({ entity, terms }: TProps) => {
  const { visible, show } = useToggle()
  const [image, setImage] = useState<File | null>()

  const { user } = useContext('auth')

  const initialValues = useMemo(
    (): InterestInput => ({
      note: '',
      event: entity?.id,
      user: user?.id,
      term: terms?.[0]?.id,
      appendix: undefined!,
    }),
    [entity, terms]
  )

  const changedTerms = useMemo(
    () =>
      terms?.map(({ id, attributes: item }: TermEntity) => ({
        value: id,
        label: toInterval(item!),
      })),
    [terms]
  )

  const [upload, { loading: uploadLoading }] = useMutation(UPLOAD)

  const { onSubmit, loading, error } = useForm<
    TMutation,
    { data: InterestInput; user?: number }
  >(CREATE_INTEREST, show)

  const handleSubmit = useCallback(
    async (
      values: InterestInput,
      formikHelpers: FormikHelpers<InterestInput>
    ) => {
      let newFile: Awaited<ReturnType<typeof upload>> | null = null
      if (image) newFile = await upload({ variables: { file: image } })

      await onSubmit(
        {
          user: values?.user,
          data: {
            ...omit(values, ['appendix']),
            ...(!!newFile && {
              appendix: newFile?.data?.upload?.data?.id,
            }),
          },
        },
        formikHelpers
      )
    },
    [onSubmit, image]
  )

  if (visible)
    return (
      <Success
        points={entity?.attributes?.points}
        title={entity?.attributes?.title || ''}
      />
    )

  return (
    <>
      <S.Header>
        <div>
          <Text as="h1" variant="subheading" color="darkBlue">
            Mám záujem
          </Text>

          <Text as="small" variant="link" color="darkBlue">
            Ste prihlásený ako:{' '}
            <Text as="span" color="salmon">
              {user?.email}
            </Text>
          </Text>
        </div>

        <Text as="h2" variant="heading1" color="salmon">
          {entity?.attributes?.title}
        </Text>

        <Points
          points={entity?.attributes?.points || 0}
          size="large"
          variant="subheading"
          color="darkBlue"
          left="Za túto udalosť získate"
        />

        {entity?.attributes?.additionalInformations?.place && (
          <Text variant="bold">
            Miesto:{' '}
            <Text as="span" color="salmon">
              {entity?.attributes?.additionalInformations?.place}
            </Text>
          </Text>
        )}
      </S.Header>

      <Text variant="paragraph" align="justify">
        {entity?.attributes?.description}
      </Text>

      <Formik
        {...{ initialValues, validationSchema }}
        onSubmit={handleSubmit}
        validateOnChange
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            <Select
              name="term"
              label="Vyberte si termín"
              options={changedTerms}
              disabled={loading}
              isError={touched.term && errors.term}
            />

            <Input
              as="textarea"
              name="note"
              label="Poznámka"
              disabled={loading}
              isError={touched.note && errors.note}
            />

            <Pick
              label="Pridať prílohu"
              description={entity?.attributes?.note}
              setForm={setImage}
            />

            {!!error && (
              <Text as="small" variant="error">
                {error}
              </Text>
            )}

            <Button
              type="submit"
              disabled={loading || uploadLoading}
              isLoading={loading || uploadLoading}
            >
              Mám záujem
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Modal<TProps>(Interest)
