import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router'

import { Success } from '../sections'
import { Button, Input, Text } from 'ui'
import { useContext, useForm } from 'hooks'
import validationSchema from 'schemas/resetPassword'

import { RESET_PASSWORD } from 'apollo/mutations'
import { UsersPermissionsLoginPayload } from 'types/models/user'

import * as S from './styled'

type TParams = {
  code: string
}

type TFields = {
  password: string
  passwordConfirmation: string
}

const initialValues: TFields = {
  password: '',
  passwordConfirmation: ''
}

type TMutation = TData<UsersPermissionsLoginPayload, 'resetPassword'>

const ChangePassword = () => {
  const { code } = useParams<TParams>()
  const { onLogin } = useContext('auth')

  const [jwt, setJwt] = useState<string | undefined>(undefined)

  const { onSubmit, loading, error } = useForm<
    TMutation,
    TFields & { code: string }
  >(RESET_PASSWORD, (data) => setJwt(data?.resetPassword?.jwt))

  if (jwt) {
    return (
      <Success title="Zmena hesla" text="Vaše heslo ste úspešne zmenili.">
        <Button onClick={() => onLogin(jwt)}>Prihlásiť sa</Button>
      </Success>
    )
  }

  return (
    <S.Wrapper>
      <Text as="h1" variant="heading2" color="salmon" mb="s" align="center">
        Zmena hesla
      </Text>

      <Formik
        {...{ initialValues, validationSchema }}
        onSubmit={(values, formikHelpers) =>
          onSubmit({ ...values, code: code || '' }, formikHelpers)
        }
        validateOnChange
      >
        {({ errors, touched }) => (
          <Form>
            <Input
              type="password"
              name="password"
              label="Nové heslo"
              disabled={loading}
              isError={touched.password && errors.password}
              required
            />

            <Input
              type="password"
              name="passwordConfirmation"
              label="Nové heslo znovu"
              disabled={loading}
              isError={
                touched.passwordConfirmation && errors.passwordConfirmation
              }
              required
            />

            {!!error && (
              <Text as="small" variant="error">
                {error}
              </Text>
            )}

            <Button type="submit" disabled={loading} isLoading={loading}>
              Zmeniť heslo
            </Button>
          </Form>
        )}
      </Formik>
    </S.Wrapper>
  )
}

export default ChangePassword
