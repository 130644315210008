import React from 'react'

import Image from '../Image'
import { Stars, Text } from 'ui'
import { Reference as TReference } from 'types/models/reference'

import * as S from './styled'

type TProps = {
  data: TReference
}

const Reference = ({ data }: TProps) => (
  <S.Wrapper>
    <Image
      src={
        data?.avatar?.data?.attributes?.formats?.xlarge?.url ||
        data?.avatar?.data?.attributes?.formats?.large?.url ||
        data?.avatar?.data?.attributes?.url ||
        ''
      }
      alt={`${data?.name} avatar`}
      isCircle
      width={188}
      height={188}
    />

    <Stars value={data?.rating} />

    <div>
      <Text
        as="h3"
        variant="subheading"
        align="center"
        weight={700}
        mb="xxxs"
        uppercase
      >
        {data?.name}
      </Text>

      <Text as="h4" color="salmon" align="center" weight={700} uppercase>
        {data?.position}
      </Text>
    </div>

    <Text variant="paragraph" align="justify">
      {data?.description}
    </Text>
  </S.Wrapper>
)

export default React.memo(Reference)
