import styled from 'styled-components'

export const Wrapper = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  padding-block: ${({ theme }) => theme.sizes.s};
  background-color: ${({ theme }) => theme.colors.background};

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.sizes.s};
  }

  button {
    max-width: 340px;
    width: 100%;
  }
`
