import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getArticles {
    articles(sort: ["createdAt:desc"]) {
      data {
        id
        attributes {
          title
          description
          body
          slug
          coverImage {
            ...FileFields
          }
          createdAt
        }
      }
    }
  }
`
