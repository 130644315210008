import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getUser {
    getMe {
      data {
        id
        attributes {
          name
          surname
          birthday
          phone
          email
          city
          street
          houseNumber
          postcode
          bio
          avatar {
            ...FileFields
          }
          createdAt
        }
      }
    }
  }
`
