import styled from 'styled-components'

export const Wrapper = styled.iframe`
  display: block;
  margin: 0;
  padding: 0;

  min-height: 400px;
  height: 100%;

  max-width: 100%;
  width: 100%;

  overflow: hidden;
  border: none;

  @media (max-width: 420px) {
    min-height: 440px;
  }

  @media (max-width: 320px) {
    min-height: 480px;
  }
`
