import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getEvent($slug: String!, $id: ID, $now: Date!) {
    events(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          title
          slug
          category
          description
          points
          capacity
          body
          videoLink
          webLink
          note
          coverImage {
            ...FileFields
          }
          gallery {
            data {
              id
              attributes {
                url
              }
            }
          }
          additionalInformations {
            id
            place
            minAge
            maxAge
          }
          contactUs {
            id
            email
            name
          }
          lectors {
            data {
              id
              attributes {
                name
                description
                image {
                  ...FileFields
                }
              }
            }
          }
          upcoming: terms(
            filters: { beginning: { gte: $now } }
            sort: ["beginning:asc"]
          ) {
            data {
              id
              attributes {
                beginning
                end
              }
            }
          }
          terms(sort: ["beginning:asc"]) {
            data {
              id
              attributes {
                beginning
                end
              }
            }
          }
          references {
            data {
              id
              attributes {
                rating
                name
                position
                description
                avatar {
                  ...FileFields
                }
              }
            }
          }
          articles {
            data {
              id
              attributes {
                title
                description
                slug
                body
                coverImage {
                  ...FileFields
                }
              }
            }
          }
          interests(filters: { user: { id: { eq: $id } } }) {
            data {
              id
            }
          }
          organization {
            data {
              id
              attributes {
                name
                slug
                webLink
                email
                rating
                phoneNumbers {
                  id
                  phoneNumber
                }
                address {
                  id
                  city
                  street
                  postcode
                }
                socialLinks {
                  id
                  facebookLink
                  instagramLink
                  youtubeLink
                }
                events(
                  filters: { category: { eq: "dobrovolnicke-prilezitosti" } }
                  sort: ["createdAt:desc"]
                ) {
                  data {
                    id
                    attributes {
                      title
                      slug
                      category
                      capacity
                      points
                      coverImage {
                        ...FileFields
                      }
                      organization {
                        data {
                          id
                          attributes {
                            name
                            rating
                          }
                        }
                      }
                      areas {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
