import React, { useCallback, useEffect, useMemo } from 'react'
import { useLocation, Link, To } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { Container, Stars, Text } from 'ui'
import { CATEGORIES_ICONS, USER_PLACEHOLDER } from 'constants/global'
import header from 'constants/header'

import * as S from './styled'

type TProps = {
  title?: string
  rating?: number
  description?: string
  icon?: string
  image?: string
  bordered?: boolean
  back?: To
  loading?: boolean
  children?: React.ReactNode
}

const Header = ({
  title,
  rating,
  description,
  icon,
  image,
  bordered,
  back,
  loading,
  children
}: TProps) => {
  const { pathname } = useLocation()

  const [category, subcategory] = useMemo(() => {
    const parts = pathname.split('/')
    const isStatic = !!header?.static?.subcategories[parts[1]]
    return isStatic ? ['static', parts[1]] : [parts[1], parts[2]]
  }, [pathname])

  const content = useMemo(
    () => header?.[category]?.subcategories?.[subcategory],
    [category, subcategory]
  )

  const avatarSrc = useMemo(() => {
    if (bordered) return icon ?? USER_PLACEHOLDER
    return `${CATEGORIES_ICONS}/${icon || subcategory}.svg`
  }, [bordered, icon, subcategory])

  useEffect(() => {
    const main = document.getElementById('main')

    if (subcategory === 'dobrovolnicke-organizacie')
      main?.classList.add('inverted')

    return () => {
      main?.classList.remove('inverted')
    }
  }, [subcategory])

  const ArrowBack = useCallback(
    () =>
      back ? (
        <Link to={back}>
          <img
            src="/assets/icons/arrow-back.svg"
            alt="Go back"
            width={12}
            height={18}
          />
        </Link>
      ) : null,
    [back]
  )

  const Avatar = useCallback(
    () => (
      <img
        src={avatarSrc || subcategory}
        alt={`${title} icon`}
        width={back ? 54 : 128}
        height={back ? 54 : 128}
      />
    ),
    [avatarSrc, title, back, subcategory]
  )

  return (
    <S.Wrapper {...{ image, bordered }}>
      <Container>
        {loading ? <Skeleton circle width={54} height={54} /> : <Avatar />}

        <div>
          <Text as="h1" variant="heading2" color="salmon" mb="xxs" uppercase>
            <ArrowBack />
            {loading ? (
              <Skeleton width="70%" highlightColor="#FFFFFF" />
            ) : (
              <S.TitleWithRating>
                {title ?? content?.title}
                {rating && (
                  <Stars
                    value={rating}
                    max={3}
                    starGap={2}
                    style={{ paddingTop: 5 }}
                  />
                )}
              </S.TitleWithRating>
            )}
          </Text>

          <Text variant="paragraph" color="white">
            {loading ? (
              <SkeletonTheme highlightColor="#FFFFFF">
                <Skeleton height={12} width="70%" />
                <Skeleton height={12} width="60%" />
              </SkeletonTheme>
            ) : (
              (description ?? content?.text)
            )}
          </Text>

          {!!children && <S.Footer>{children}</S.Footer>}
        </div>
      </Container>
    </S.Wrapper>
  )
}

export default React.memo(Header)
