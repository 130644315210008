import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { DateTime } from 'luxon'

import {
  AddEvent,
  ArticleItem,
  Filter,
  Header,
  Item,
  Map,
  TMapMarkerData
} from 'components'
import { Button, Grid, Section, Switch, Text } from 'ui'
import { useMemoDeps, useToggle } from 'hooks'

import { GET_EVENTS } from 'apollo/queries'
import { EventEntity, EventEntityResponseCollection } from 'types/models/event'
import {
  ENUM_COMPONENTGROWNIDURATIONGROWNIDURATION_VALUE,
  ENUM_EVENT_CATEGORY
} from 'types/enums'
import {
  GrowniProjectEntity,
  GrowniProjectEntityResponseCollection
} from 'types/models/growniProject'

import * as S from './styled'

type TFilter = {
  old?: boolean
  longTerm?: boolean | null
  ourOffer?: boolean
  regions: Set<string>
  areas: Set<string>
}

const isGrowiProject = (
  item: EventEntity | GrowniProjectEntity
): item is GrowniProjectEntity => {
  if (!item.attributes) return false
  return 'externalCreatedAt' in item.attributes
}

const isWithLocation = (
  item: EventEntity | GrowniProjectEntity
): item is EventEntity => {
  if (!item.attributes) return false
  return 'location' in item.attributes && !!item.attributes.location
}

type TQuery = TData<EventEntityResponseCollection, 'events'> &
  TData<GrowniProjectEntityResponseCollection, 'growniProjects'>

const ItemPlaceholder = () => (
  <>
    <Skeleton height={358} />
    <Skeleton height={358} />
    <Skeleton height={358} />
  </>
)

const ArticlePlaceholder = () => (
  <>
    <S.ArticlePlaceholder>
      <div style={{ flex: 1 }}>
        <Skeleton height={45} width="75%" style={{ marginBottom: 16 }} />
        <Skeleton height={12} count={8} />
        <Skeleton height={12} width="80%" />
        <Skeleton width={114} height={47} style={{ marginTop: 24 }} />
      </div>

      <Skeleton height={365} />
    </S.ArticlePlaceholder>

    <S.ArticlePlaceholder flipped>
      <div style={{ flex: 1 }}>
        <Skeleton height={45} width="75%" style={{ marginBottom: 16 }} />
        <Skeleton height={12} count={8} />
        <Skeleton height={12} width="80%" />
        <Skeleton width={114} height={47} style={{ marginTop: 24 }} />
      </div>

      <Skeleton height={365} />
    </S.ArticlePlaceholder>
  </>
)

const Preview = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    visible: addEventModalVisible,
    show: showAddEventModal,
    hide: hideAddEventModal
  } = useToggle()

  const [filter, setFilter] = useState<TFilter>({
    old: false,
    longTerm: null,
    ourOffer: false,
    regions: new Set(),
    areas: new Set()
  })

  const resetFilter = useCallback(() => {
    setFilter((prev) => ({
      ...prev,
      ourOffer: false,
      regions: new Set(),
      areas: new Set()
    }))
  }, [])

  const category = useMemo(
    () => pathname.split('/')?.[2] || 'nase-vlastne-programy',
    [pathname]
  )

  const { data, loading } = useQuery<TQuery>(GET_EVENTS, {
    variables: {
      now: DateTime.now().toISODate(),
      eventFilters: {
        category: { eq: category },
        and: [
          {
            ...(!!filter.ourOffer && { ourOffer: { eq: true } })
          },
          {
            ...(filter.longTerm !== null && {
              longTerm: { eq: filter.longTerm }
            })
          }
        ],
        terms: {
          or: [
            {
              end: filter.old
                ? { lt: DateTime.now().toISODate() }
                : { gte: DateTime.now().toISODate() }
            },
            !filter.old ? { id: { null: true } } : {}
          ]
        },
        ...(!!filter.regions.size && {
          organization: { region: { in: Array.from(filter.regions) } }
        }),
        ...(!!filter.areas.size && {
          or: [{ areas: { id: { in: Array.from(filter.areas) } } }]
        })
      },
      growniProjectFilters: {
        id:
          filter.ourOffer ||
          category !== ENUM_EVENT_CATEGORY.dobrovolnicke_prilezitosti ||
          filter.areas.size
            ? { null: true }
            : undefined,
        ...(filter.longTerm !== null && {
          duration: {
            value: {
              contains: filter.longTerm
                ? ENUM_COMPONENTGROWNIDURATIONGROWNIDURATION_VALUE.long_term
                : ENUM_COMPONENTGROWNIDURATIONGROWNIDURATION_VALUE.short_term
            }
          }
        })
      }
    }
  })

  const { data: dataMemo } = useMemoDeps({ data })

  const events = useMemo(() => {
    const internal = data?.events.data ?? []
    const external = data?.growniProjects.data ?? []

    return [...internal, ...external].sort((a, b) => {
      const aUpdatedAt = isGrowiProject(a)
        ? a.attributes?.externalUpdatedAt
        : a.attributes?.updatedAt

      const bUpdatedAt = isGrowiProject(b)
        ? b.attributes?.externalUpdatedAt
        : b.attributes?.updatedAt

      const aCreatedAt = isGrowiProject(a)
        ? a.attributes?.externalCreatedAt
        : a.attributes?.createdAt

      const bCreatedAt = isGrowiProject(b)
        ? b.attributes?.externalCreatedAt
        : b.attributes?.createdAt

      const aFinalCreatedAt =
        aUpdatedAt ??
        aCreatedAt ??
        a.attributes?.updatedAt ??
        a.attributes?.createdAt

      const bFinalCreatedAt =
        bUpdatedAt ??
        bCreatedAt ??
        b.attributes?.updatedAt ??
        b.attributes?.createdAt

      if (!aFinalCreatedAt && !bFinalCreatedAt) return 0
      if (!aFinalCreatedAt) return 1
      if (!bFinalCreatedAt) return -1

      return (
        new Date(bFinalCreatedAt).getTime() -
        new Date(aFinalCreatedAt).getTime()
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMemo])

  const isVolunteerOpportunities = useMemo(
    () => pathname === '/pre-dobrovolnikov/dobrovolnicke-prilezitosti',
    [pathname]
  )

  const isSecondary = useMemo(
    () =>
      [
        ENUM_EVENT_CATEGORY.nastroje_na_podporu,
        ENUM_EVENT_CATEGORY.nastroje_na_podporu_prace_s_dobrovolnikmi
      ].includes(category as ENUM_EVENT_CATEGORY),
    [category]
  )

  const markers: TMapMarkerData[] = useMemo(() => {
    const withLocation = events?.filter(isWithLocation)

    const eventMarkers = withLocation.map((item) => ({
      name: item.attributes?.title ?? '',
      slug: item.attributes?.slug ?? '',
      location: JSON.parse(item.attributes?.location ?? '')
    }))

    return eventMarkers ?? []
  }, [events])

  const onMarkerClick = useCallback(
    (marker: TMapMarkerData) => {
      navigate(`/pre-dobrovolnikov/dobrovolnicke-prilezitosti/${marker.slug}`)
    },
    [navigate]
  )

  const renderItems = useCallback((item: EventEntity | GrowniProjectEntity) => {
    if (!item.attributes) return null
    return <Item key={`event-${item.id}`} data={item.attributes} />
  }, [])

  const renderSecondary = useCallback(
    ({ attributes: item }: EventEntity, idx: number) =>
      !item ? null : (
        <ArticleItem
          key={`${item.category}-${item.slug}`}
          title={item.title}
          description={item.description}
          coverImage={item.coverImage}
          path={`/inspiracia/${category}/${item.slug}`}
          flipped={!(idx % 2)}
        />
      ),
    [category]
  )

  return (
    <>
      <Header />

      <Section col={isSecondary} {...(isSecondary && { gap: 'xxxl' })}>
        {isSecondary ? (
          <>
            {loading ? (
              <ArticlePlaceholder />
            ) : (
              data?.events?.data?.map(renderSecondary)
            )}
          </>
        ) : (
          <>
            <S.Top>
              <Filter
                values={filter}
                setValues={setFilter}
                resetFilter={resetFilter}
              />

              <Switch
                nullLabel="Všetky"
                falseLabel="Jednorázová"
                trueLabel="Dlhodobá"
                value={filter.longTerm}
                onChange={(value: boolean) =>
                  setFilter({ ...filter, longTerm: value })
                }
              />

              <Switch
                falseLabel="Aktuálne"
                trueLabel="Zrealizované"
                value={filter.old}
                onChange={(value: boolean) =>
                  setFilter({ ...filter, old: value })
                }
              />

              {isVolunteerOpportunities && (
                <Button
                  onClick={showAddEventModal}
                  style={{ marginLeft: 'auto' }}
                >
                  Pridať príležitosť
                </Button>
              )}
            </S.Top>

            <Grid>
              {loading ? (
                <ItemPlaceholder />
              ) : events.length ? (
                events.map(renderItems)
              ) : (
                <S.EmptyState>
                  <img src="/assets/icons/empty.svg" alt="" />
                  <Text variant="paragraph" color="grey">
                    Nenašli sa žiadne výsledky
                  </Text>
                </S.EmptyState>
              )}
            </Grid>
          </>
        )}
      </Section>

      {isVolunteerOpportunities && !!markers.length && (
        <Map {...{ markers, onMarkerClick }} />
      )}

      {addEventModalVisible && <AddEvent onClose={hideAddEventModal} />}
    </>
  )
}

export default React.memo(Preview)
