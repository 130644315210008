import React, { useCallback } from 'react'

import { CarouselItem } from 'components'
import { Carousel, Section, Text } from 'ui'
import { LectorEntity } from 'types/models/lector'

type TProps = {
  collection?: LectorEntity[]
}

const Lectors = ({ collection }: TProps) => {
  const renderLectors = useCallback(
    ({ id, attributes: item }: LectorEntity) => (
      <CarouselItem
        key={`${item?.name}-${id}-lector`}
        image={item?.image?.data}
        name={item?.name}
        position="lektor"
        description={item?.description}
        color="white"
      />
    ),
    []
  )

  return (
    <Section bg="blue" hideIf={!collection?.length}>
      <Text
        as="h2"
        variant="heading2"
        color="salmon"
        align="center"
        mb="xxl"
        uppercase
      >
        Lektori
      </Text>

      <Carousel
        isCondensed
        isAutomatic
        isInfinite={(collection?.length || 0) > 3}
      >
        {collection?.map(renderLectors)}
      </Carousel>
    </Section>
  )
}

export default React.memo(Lectors)
