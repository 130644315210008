import React from 'react'
import { omit } from 'lodash'
import { useLocation, Navigate } from 'react-router-dom'

import { Loading } from '../sections'
import { useContext } from 'hooks'

const RequireAuth = (Component: React.ComponentType) => {
  const Wrapper = () => {
    const location = useLocation()

    const { isLoading, isAuthenticated } = useContext('auth')

    if (isLoading) return <Loading />

    return isAuthenticated ? (
      <Component />
    ) : (
      <Navigate
        to="/signin"
        state={{ from: omit(location, 'state') }}
        replace
      />
    )
  }

  return <Wrapper />
}

export default RequireAuth
