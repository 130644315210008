import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getOrganization($slug: String!) {
    organizations(filters: { slug: { eq: $slug } }, sort: ["slug:asc"]) {
      data {
        id
        attributes {
          name
          slug
          description
          rating
          body
          videoLink
          webLink
          email
          region
          phoneNumbers {
            id
            phoneNumber
          }
          coverImage {
            ...FileFields
          }
          address {
            id
            city
            street
            postcode
          }
          socialLinks {
            id
            facebookLink
            instagramLink
            youtubeLink
          }
          gallery {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          areas(sort: ["name:asc"]) {
            data {
              id
              attributes {
                name
              }
            }
          }
          events(
            filters: { category: { eq: "dobrovolnicke-prilezitosti" } }
            sort: ["createdAt:desc"]
          ) {
            data {
              id
              attributes {
                title
                slug
                category
                capacity
                points
                coverImage {
                  ...FileFields
                }
                organization {
                  data {
                    id
                    attributes {
                      name
                      rating
                    }
                  }
                }
                areas {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
