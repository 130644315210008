import React from 'react'

import * as S from './styled'

const SIZE = 44

type TRootStyle = {
  transform?: string
}

type TCircleStyle = {
  strokeDasharray?: string
  strokeDashoffset?: string
  animation?: string
}

type TCircularProgressProps = {
  disableShrink?: boolean
  size?: number | string
  thickness?: number
  value?: number
  variant?: S.TWrapperProps['variant']
  className?: string
  style?: React.CSSProperties
}

const CircularProgress = ({
  disableShrink,
  size = 40,
  thickness = 3.6,
  value = 0,
  variant = 'indeterminate',
  className,
  style
}: TCircularProgressProps) => {
  const rootStyle: TRootStyle = {}
  const circleStyle: TCircleStyle = {}

  if (variant === 'determinate') {
    const circumference = 2 * Math.PI * ((SIZE - thickness) / 2)
    rootStyle.transform = 'rotate(-90deg)'
    circleStyle.strokeDasharray = circumference.toFixed(3)
    circleStyle.strokeDashoffset = `${(
      ((100 - value) / 100) *
      circumference
    ).toFixed(3)}px`
  } else {
    if (disableShrink) circleStyle.animation = 'none'
  }

  return (
    <S.Wrapper
      {...{ variant, className, style }}
      role="progressbar"
      style={{
        width: size,
        height: size,
        ...rootStyle,
        ...style
      }}
    >
      <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <circle
          style={circleStyle}
          cx={SIZE}
          cy={SIZE}
          r={(SIZE - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        />
      </svg>
    </S.Wrapper>
  )
}

export default React.memo(CircularProgress)
