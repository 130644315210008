import styled from 'styled-components'

interface IButton {
  isActive?: boolean
}

export const Wrapper = styled.div`
  display: inline-flex;
  padding: 2px;

  background-color: ${({ theme }) => theme.colors.lightSalmon};
  border-radius: ${({ theme }) => theme.sizes.xxs};
`

export const Button = styled.button<IButton>`
  padding: 12px 18px;

  font-weight: 700;
  font-size: 15px;
  line-height: 19px;

  background-color: ${({ theme, isActive }) =>
    theme.colors[isActive ? 'salmon' : 'lightSalmon']};
  color: ${({ theme, isActive }) => theme.colors[isActive ? 'white' : 'grey']};
  border-radius: ${({ theme }) => theme.sizes.xxs};
`
