import React from 'react'
import { DefaultTheme } from 'styled-components'

import * as S from './styled'

type TProps = {
  left?: string
  points?: { confirmed?: number; unconfirmed?: number }
  color?: keyof DefaultTheme['colors']
  variant?: keyof DefaultTheme['fonts']
  size?: 'large' | 'medium' | 'small'
  mb?: keyof DefaultTheme['sizes']
  hideText?: boolean
}

const Points = ({
  left,
  points,
  size = 'small',
  hideText,
  ...props
}: TProps) => (
  <S.Wrapper {...{ size, ...props }}>
    {!!left && !hideText && `${left} `}
    <span>{points?.confirmed || 0}</span>
    {points?.unconfirmed !== undefined && (
      <small> ({points.unconfirmed})</small>
    )}
    {!hideText && ' bodov'}
  </S.Wrapper>
)

export default React.memo(Points)
