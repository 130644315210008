import { gql } from '@apollo/client'

export const FILE_FIELDS = gql`
  fragment FileFields on UploadFileEntityResponse {
    data {
      id
      attributes {
        url
        alternativeText
        caption
        formats
      }
    }
  }
`

export const REFERENCE_COLLECTION_FIELDS = gql`
  fragment ReferenceCollectionFields on ReferenceRelationResponseCollection {
    data {
      id
      attributes {
        rating
        name
        position
        description
        avatar {
          ...FileFields
        }
      }
    }
  }
`
