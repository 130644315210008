import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: ${({ theme }) => theme.sizes.xs};
  }

  & > div {
    display: flex;
    gap: ${({ theme }) => theme.sizes.s};
    justify-content: space-between;
  }
`
