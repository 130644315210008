import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

export const Wrapper = styled(ReactMarkdown)`
  ${({ theme }) => theme.fonts.paragraph};
  text-align: justify;
  display: flow-root;

  p {
    word-wrap: break-word;

    & + p {
      margin-top: 20px;
    }
  }

  img[alt='clear'] {
    clear: both;
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  * + h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    margin-top: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${({ theme }) => theme.sizes.s};
    font-weight: 700;
  }

  h1 {
    font-size: 36px;
    line-height: 44px;
  }

  h2 {
    font-size: 32px;
    line-height: 39px;
  }

  h3 {
    font-size: 28px;
    line-height: 34px;
  }

  h4 {
    font-size: 24px;
    line-height: 29px;
  }

  h5 {
    font-size: 22px;
    line-height: 27px;
  }

  h6 {
    font-size: 18px;
    line-height: 22px;
  }

  ul,
  ol {
    padding-left: ${({ theme }) => theme.sizes.m};
    margin-bottom: ${({ theme }) => theme.sizes.s};
  }

  ul.contains-task-list {
    padding-left: ${({ theme }) => theme.sizes.xxs};
    list-style: none;
  }

  code {
    white-space: pre-wrap;
  }

  img {
    max-width: 100%;
    height: auto;
    box-shadow: ${({ theme }) => theme.shadows.modal};
    border-radius: ${({ theme }) => theme.sizes.xxs};

    &[alt='left'] {
      max-width: calc(50% - 20px);
      float: left;
      margin-right: 20px;
    }

    &[alt='right'] {
      max-width: calc(50% - 20px);
      float: right;
      margin-left: 20px;
    }

    @media (max-width: 900px) {
      &[alt='right'],
      &[alt='left'] {
        max-width: 55%;
      }
    }

    @media (max-width: 700px) {
      &[alt='right'],
      &[alt='left'] {
        max-width: 65%;
      }
    }

    @media (max-width: 500px) {
      &[alt='right'],
      &[alt='left'] {
        max-width: 100%;
      }
    }
  }

  /*${(p) =>
    p
      ? `
      img {
        max-width: 100%;
        clear: both;
        border-radius: ;
        margin: 20px 0;
      }
      `
      : `
    img {
      max-width: 45%;
      float: right;
      margin: 0 0 20px 20px;
      border-radius: ;

      @media (max-width: 900px) {
        max-width: 55%;
      }

      @media (max-width: 700px) {
        max-width: 65%;
      }

      @media (max-width: 500px) {
        max-width: 100%;
      }
    }
  `}*/

  blockquote {
    position: relative;
    margin-block: ${({ theme }) => theme.sizes.s};
    padding-left: ${({ theme }) => theme.sizes.m};
    font-style: italic;

    &:before {
      content: '"';

      position: absolute;
      top: 60%;
      left: -6px;

      font-size: 50px;
      color: ${({ theme }) => theme.colors.salmon};
      font-style: italic;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.salmon};
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`
