import React, { IframeHTMLAttributes } from 'react'

import * as S from './styled'

type TProps = {
  src?: string
} & IframeHTMLAttributes<HTMLIFrameElement>

const Video = (props: TProps) => <S.Wrapper {...props} allowFullScreen />

export default React.memo(Video)
