import React from 'react'

import { FormLocation } from 'components'
import { Section, Text } from 'ui'
import { ComponentInformationsContact } from 'types/models/components'

type TProps = Partial<Pick<ComponentInformationsContact, 'email' | 'name'>>

const Contact = ({ email, name }: TProps) => (
  <Section bg="blue" col vCenter hideIf={!email}>
    <Text
      as="h2"
      variant="heading2"
      color="salmon"
      align="center"
      mb="s"
      uppercase
    >
      Kontaktuj nás
    </Text>

    {!!name && (
      <Text color="white" mb="s" align="center">
        V prípade nejasností kontaktujte:{' '}
        <Text as="span" color="salmon" weight={700}>
          {name}
        </Text>
      </Text>
    )}

    <FormLocation to={email ?? ''} location={window.location.href} />
  </Section>
)

export default React.memo(Contact)
