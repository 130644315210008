import { gql } from '@apollo/client'

export default gql`
  query getEvents($filters: EventFiltersInput, $now: Date!) {
    events(filters: $filters, sort: ["createdAt:desc"]) {
      data {
        id
        attributes {
          category
          title
          slug
          description
          points
          capacity
          location
          terms(
            filters: { beginning: { gte: $now } }
            pagination: { limit: 1 }
          ) {
            data {
              id
              attributes {
                beginning
              }
            }
          }
          areas(sort: ["name:asc"]) {
            data {
              id
              attributes {
                name
              }
            }
          }
          organization {
            data {
              id
              attributes {
                name
                rating
              }
            }
          }
          coverImage {
            data {
              id
              attributes {
                url
                alternativeText
                formats
              }
            }
          }
        }
      }
    }
  }
`
