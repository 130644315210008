import { gql } from '@apollo/client'

export default gql`
  mutation createInterest($user: ID!, $data: InterestInput!) {
    createInterest(data: $data) {
      data {
        id
        attributes {
          user {
            data {
              id
              attributes {
                points {
                  data {
                    attributes {
                      count
                    }
                  }
                }
              }
            }
          }
          event {
            data {
              id
              attributes {
                terms {
                  data {
                    id
                  }
                }
                interests(filters: { user: { id: { eq: $user } } }) {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
