import styled from 'styled-components'

interface IMember {
  isFlipped?: boolean
}

export const Member = styled.article<IMember>`
  display: flex;
  gap: ${({ theme }) => theme.sizes.xxl};
  padding-inline: ${({ theme }) => theme.sizes.section};

  flex-direction: ${({ isFlipped }) => (isFlipped ? 'row-reverse' : 'row')};

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @media (max-width: 900px) {
    padding-inline: 0;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: ${({ theme }) => theme.sizes.xl};

    p {
      text-align: center;
    }
  }

  & > span > span {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 500px) {
    & > span {
      width: 100%;
      & > span {
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
`
