export default {
  'dobrovolnicke-prilezitosti': 'pre-dobrovolnikov',
  'dlhodobe-dobrovolnicke-programy': 'pre-dobrovolnikov',
  'podujatia-pre-teba': 'pre-dobrovolnikov',
  'programy-na-osobny-rozvoj': 'pre-dobrovolnikov',
  'motivacia-pribehy-dobrovolnikov': 'pre-dobrovolnikov',

  podujatia: 'pre-organizacie',
  'nastroje-na-podporu-prace-s-dobrovolnikmi': 'pre-organizacie',
  'pre-vasich-dobrovolnikov': 'pre-organizacie',

  'stan-sa-dobrovolnickou-organizaciou': 'centra-ktore-potrebuju-dobrovolnikov',
  'zapoj-sa-do-nasho-programu': 'centra-ktore-potrebuju-dobrovolnikov',

  'dobrovolnicke-organizacie': 'inspiracia',
  'nastroje-na-podporu': 'inspiracia',
  clanky: 'inspiracia',
  'otestuj-sa': 'inspiracia',
  dokumenty: 'inspiracia',
}
