import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import {
  HeaderInfo,
  Informations,
  InterestFixed,
  Lectors,
  Terms,
  Contact
} from './sections'
import {
  Articles,
  Events,
  Gallery,
  OrganizationInfo,
  References,
  Website
} from '../sections'

import { Header, Interest } from 'components'
import { Markdown, Section, Video } from 'ui'
import { useContext, useMemoDeps, useToggle } from 'hooks'
import category from 'constants/category'
import { getNow } from 'utils/date'

import { GET_EVENT } from 'apollo/queries'
import { EventEntityResponseCollection } from 'types/models/event'
import { ENUM_EVENT_CATEGORY } from 'types/enums'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { TLocationState } from 'types/global'

type TParams = {
  slug: string
}

type TQuery = TData<EventEntityResponseCollection, 'events'>

const Detail = () => {
  const { slug } = useParams<TParams>()
  const { state, ...location } = useLocation()
  const navigate = useNavigate()

  const { isAuthenticated, user } = useContext('auth')

  const { visible, show, hide } = useToggle(
    (state as TLocationState)?.modal === 'interest'
  )

  const { data, loading } = useQuery<TQuery>(GET_EVENT, {
    variables: {
      slug,
      now: getNow(),
      id: isAuthenticated ? user?.id : null
    }
  })

  const { data: dataMemo } = useMemoDeps({ data })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const root = useMemo(() => data?.events?.data?.[0], [dataMemo])

  const backRoute = useMemo(() => {
    const rootCategory = root?.attributes?.category
    if (!rootCategory) return undefined

    const subcategory = ENUM_EVENT_CATEGORY[rootCategory]
    return `/${category?.[subcategory]}/${subcategory}`
  }, [root?.attributes?.category])

  const isInterestFixedVisible = useMemo(() => {
    return (
      !root?.attributes?.interests?.data?.length &&
      !!root?.attributes?.upcoming?.data?.length
    )
  }, [root?.attributes?.interests?.data, root?.attributes?.upcoming?.data])

  useEffect(() => {
    if ((state as TLocationState)?.modal === 'interest') {
      const currentState = state as TLocationState
      const newState = { ...currentState, modal: undefined }
      navigate(location, { replace: true, state: newState })
    }
  }, [location, navigate, state])

  return (
    <>
      <Header
        key={root?.attributes?.slug}
        back={backRoute}
        loading={loading}
        title={root?.attributes?.title}
        description={root?.attributes?.description}
        icon={
          root?.attributes?.category
            ? ENUM_EVENT_CATEGORY[root?.attributes?.category]
            : undefined
        }
        image={
          root?.attributes?.coverImage?.data?.attributes?.formats?.xlarge
            ?.url ||
          root?.attributes?.coverImage?.data?.attributes?.formats?.large?.url ||
          root?.attributes?.coverImage?.data?.attributes?.url
        }
      >
        {loading ? (
          <SkeletonTheme highlightColor="#FFFFFF">
            <Skeleton height={16} width={120} />
            <Skeleton height={16} width={100} />
            <Skeleton height={16} width={150} />
          </SkeletonTheme>
        ) : (
          <HeaderInfo
            points={root?.attributes?.points}
            capacity={root?.attributes?.capacity}
          />
        )}
      </Header>

      {loading ? (
        <Section>
          <Skeleton height={42} width="50%" style={{ marginBottom: '18px' }} />
          <Skeleton count={8} />
          <Skeleton width="80%" />
          <Skeleton height={300} style={{ marginBlock: '18px' }} />
          <Skeleton count={8} />
        </Section>
      ) : (
        <Section hideIf={!root?.attributes?.body}>
          <Markdown>{root?.attributes?.body ?? ''}</Markdown>
        </Section>
      )}

      <Section bg="blue" hideIf={!root?.attributes?.videoLink}>
        <Video src={root?.attributes?.videoLink} />
      </Section>

      <Website webLink={root?.attributes?.webLink} />

      <Lectors collection={root?.attributes?.lectors?.data} />

      <Informations component={root?.attributes?.additionalInformations} />

      <Terms collection={root?.attributes?.terms?.data} />

      <Contact
        email={root?.attributes?.contactUs?.email}
        name={root?.attributes?.contactUs?.name}
        event={root?.id}
      />

      <References collection={root?.attributes?.references?.data} />

      <Gallery collection={root?.attributes?.gallery?.data} />

      <Articles collection={root?.attributes?.articles?.data} />

      <Events
        collection={
          root?.attributes?.organization?.data?.attributes?.events?.data
        }
        title="Ďalšie udalosti organizácie"
      />

      <OrganizationInfo entity={root?.attributes?.organization?.data} />

      {visible && isInterestFixedVisible && (
        <Interest
          entity={root}
          terms={root?.attributes?.upcoming?.data}
          onClose={hide}
        />
      )}

      {isInterestFixedVisible && <InterestFixed entity={root} show={show} />}
    </>
  )
}

export default React.memo(Detail)
