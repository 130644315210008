import React from 'react'

import { Image } from 'components'
import { Button, Link, Points, Section, Text } from 'ui'
import { openBlank } from 'utils/functions'
import { WeeklyChallengeEntity } from 'types/models/weeklyChallenge'

type TProps = {
  entity?: WeeklyChallengeEntity
}

const Challenge = ({ entity }: TProps) => (
  <Section row flex="right" width={365} gap="extra" hideIf={!entity}>
    <Image
      width={365}
      height={436}
      src={
        entity?.attributes?.image?.data?.attributes?.formats?.xlarge?.url ||
        entity?.attributes?.image?.data?.attributes?.formats?.large?.url ||
        entity?.attributes?.image?.data?.attributes?.url ||
        ''
      }
      alt={`${entity?.attributes?.title} cover image`}
      fullWidth
    />

    <div>
      <Text as="p" variant="subheading" color="blue" mb="xxs" uppercase>
        Výzva týždňa
      </Text>

      <Text as="h2" variant="heading2" color="salmon" mb="s" uppercase>
        {entity?.attributes?.title}
      </Text>

      <Points
        points={{ confirmed: entity?.attributes?.points }}
        size="medium"
        variant="subheading"
        mb="s"
      />

      <Text variant="paragraph" mb="s" align="justify">
        {entity?.attributes?.description}
      </Text>

      <div>
        <Button onClick={() => openBlank(entity?.attributes?.link)} mr="m">
          Zistiť viac
        </Button>

        <Link to="/pre-dobrovolnikov/zapoj-sa-do-nasej-hry">
          Zisti ako fungujú výzvy
        </Link>
      </div>
    </div>
  </Section>
)

export default React.memo(Challenge)
