import React, { useMemo } from 'react'

import { Section, Text } from 'ui'
import { ComponentInformationsAdditionalInformations } from 'types/models/components'

import * as S from './styled'

type TProps = {
  component?: ComponentInformationsAdditionalInformations
}

const Informations = ({ component }: TProps) => {
  const age = useMemo(() => {
    if (!component) return null

    const { minAge, maxAge } = component

    if (minAge && maxAge) return 'Veková hranica'
    if (minAge) return 'Minimálny vek'
    if (maxAge) return 'Maximálny vek'

    return null
  }, [component])

  return (
    <Section col hideIf={!component}>
      <Text as="h2" variant="heading2" color="salmon" mb="xxl" uppercase>
        Dodatočné informácie
      </Text>

      <S.Wrapper>
        {!!age && (
          <article>
            <Text as="h3" variant="large">
              {age}
            </Text>

            <Text variant="heading1" color="salmon">
              {component?.minAge}
              {age === 'Veková hranica' && ' - '}
              {component?.maxAge}
            </Text>
          </article>
        )}

        {!!component?.place && (
          <article>
            <Text as="h3" variant="large">
              Miesto konania
            </Text>

            <Text variant="heading1" color="salmon">
              {component?.place}
            </Text>
          </article>
        )}
      </S.Wrapper>
    </Section>
  )
}

export default React.memo(Informations)
