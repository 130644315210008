import React from 'react'

import { Section, Video as VideoUi } from 'ui'

type TProps = {
  src?: string
}

const Video = ({ src }: TProps) => (
  <Section bg="blue" hideIf={!src}>
    <VideoUi title="zmenme.to video" src={src} width={990} height={380} />
  </Section>
)

export default React.memo(Video)
