import { gql } from '@apollo/client'

export default gql`
  mutation tokenRegister(
    $token: String!
    $input: UsersPermissionsRegisterInput!
  ) {
    tokenRegister(token: $token, input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`
