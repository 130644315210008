import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getOrganizations($filters: OrganizationFiltersInput) {
    organizations(filters: $filters, sort: ["createdAt:desc"]) {
      data {
        id
        attributes {
          name
          slug
          description
          body
          region
          rating
          events {
            data {
              id
            }
          }
          areas(sort: ["name:asc"]) {
            data {
              id
              attributes {
                name
              }
            }
          }
          coverImage {
            ...FileFields
          }
        }
      }
    }
  }
`
