import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { Link as NavLink } from 'react-router-dom'

interface ISubnavigation {
  left?: number
}

interface ILink {
  isActive?: boolean
}

interface IMobileNavigation {
  isOpened: boolean
}

// Navigation bar

export const Wrapper = styled.nav`
  position: fixed;
  inset: 0 0 auto 0;

  display: flex;
  z-index: 999;
  padding-block: 10px;

  background-color: ${({ theme }) => theme.colors.background};
`

export const Container = styled.div`
  max-width: 100%;
  width: calc(1440px + 2%);

  padding-inline: 2%;
  margin-inline: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > a {
    display: flex;
    z-index: 100;
  }

  svg #inside {
    fill: ${({ theme }) => theme.colors.logo};
  }
`

export const Hamburger = styled.a`
  cursor: pointer;
  padding: ${({ theme }) => theme.sizes.xxs};

  @media (min-width: 1361px) {
    display: none !important;
  }
`

export const Buttons = styled.div`
  display: flex;

  @media (max-width: 1361px) {
    display: block;
    margin-left: 2%;

    && > * {
      width: auto;
    }
  }

  @media (min-width: 1361px) {
    margin-left: 15px;

    && > *:first-of-type {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  & > *:first-of-type {
    margin-bottom: 15px;
  }
`

// Desktop navigation

export const Navigation = styled.div`
  display: flex;

  @media (max-width: 1361px) {
    display: none;
  }
`

export const Subnavigation = styled.div<ISubnavigation>`
  position: absolute;
  z-index: 999;
  top: 51px;
  left: ${({ left }) => left}px;

  @media (max-width: 1360px) {
    display: none;
  }

  width: 356px;
  padding: 24px 28px;

  background: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) => theme.shadows.nav};
  border-radius: ${({ theme }) => theme.sizes.xxs};

  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: ${({ theme }) => theme.sizes.s};
  }
`

export const Link = styled.button<ILink>`
  position: relative;
  display: inline-flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.grey};
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;

  padding: 8px 20px;

  &:after {
    content: '';
    background: url('/assets/icons/arrow-down.svg');
    background-position: center;

    align-self: center;
    width: 8px;
    height: 6px;
    margin-left: 15px;

    transition: transform 150ms linear;
    ${(p) => p.isActive && 'transform: scale(-1, -1);'}
  }
`

export const SubnavigationLink = styled(NavLink)`
  position: relative;
  padding: 10px;

  display: flex;

  & > img {
    margin-right: 23px;
  }

  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.grey};

  overflow: hidden;
  border-radius: ${({ theme }) => theme.sizes.xxs};
  text-align: left;

  transition: background-color 300ms ease;

  &:hover {
    &:before {
      transform: scale(11);
    }

    @media (max-width: 1360px) {
      background-color: ${({ theme }) => theme.colors.footer};
    }
  }

  &:before {
    @media (min-width: 1361px) {
      content: '';
    }

    z-index: 0;

    position: absolute;
    top: 10px;
    left: 10px;
    width: 54px;
    height: 54px;

    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.footer};

    transition: all 300ms ease;
  }

  & > img,
  div {
    z-index: 1;
  }

  h6 {
    font-size: inherit;
    font-weight: 600;
    margin-bottom: 6px;
  }

  p {
    font-size: inherit;
    font-weight: 300;
  }
`

// Mobile navigation

export const MobileNavigation = styled.div<IMobileNavigation>`
  position: absolute;
  top: 67px;
  right: 0;
  z-index: 999;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 15px;
  }

  @media (min-width: 1360px) {
    display: none !important;
  }

  width: 100%;
  height: calc(100vh - 67px);
  padding: 24px 2%;

  background: ${({ theme }) => theme.colors.background};

  transition: transform 100ms linear;
  transform-origin: top;
  transform: ${({ isOpened }) => (isOpened ? 'scaleY(1)' : 'scaleY(0)')};

  & > div > button {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & > button {
    margin-left: 25px;
    padding: 14px 28px;
  }
`

export const MobileSubnavigation = styled.div`
  display: flex;
  padding: 15px 0;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    width: 100%;
  }
`

export const AnimateHeightStyled = styled(AnimateHeight)`
  margin: 0 -2%;
`
