import styled from 'styled-components'
import SVG from 'react-inlinesvg'

interface IWrapper {
  $starGap: number
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * + * {
    margin-left: ${({ $starGap }) => $starGap}px;
  }
`

interface IStar {
  value: number
  max: number
  $starSize: number
}

export const Star = styled(SVG)<IStar>`
  width: ${({ $starSize }) => $starSize}px;
  height: ${({ $starSize }) => $starSize}px;

  #half {
    display: ${({ value, max }) => (value === max - 0.5 ? 'block' : 'none')};
  }

  #bg {
    fill: ${({ theme, value, max }) =>
      theme.colors[value >= max ? 'yellow' : 'lighterGrey']};
  }
`
