import React, { useCallback, useMemo } from 'react'
import { To } from 'react-router'

import { Points, Stars, Tag, Text } from 'ui'
import { formatDate } from 'utils/date'
import category from 'constants/category'
import { ENUM_EVENT_CATEGORY } from 'types/enums'
import { Event } from 'types/models/event'
import { AreaEntity } from 'types/models/area'

import * as S from './styled'

type TProps = {
  data: Event
}

const Item = ({ data }: TProps) => {
  const to = useMemo((): To => {
    const subcategory = ENUM_EVENT_CATEGORY[data?.category]
    return `/${category?.[subcategory]}/${subcategory}/${data?.slug}`
  }, [data, category])

  const renderItems = useCallback(
    ({ attributes: item }: AreaEntity) => (
      <Tag key={item?.name}>{item?.name}</Tag>
    ),
    [data]
  )

  return (
    <S.Wrapper {...{ to }}>
      <img
        src={
          data?.coverImage?.data?.attributes?.formats?.xlarge?.url ||
          data?.coverImage?.data?.attributes?.formats?.large?.url ||
          data?.coverImage?.data?.attributes?.url ||
          ''
        }
        alt={data?.coverImage?.data?.attributes?.alternativeText}
      />

      <S.Content>
        <div>
          {data?.capacity ? (
            <Text variant="bold">Voľné miesta: {data?.capacity}</Text>
          ) : (
            <div />
          )}

          {data?.points ? <Points points={data?.points} hideText /> : <div />}
        </div>

        <div>
          <Text as="span" variant="bold" color="salmon">
            {!!data?.terms?.data?.length &&
              formatDate(data?.terms?.data?.[0]?.attributes?.beginning || '')}
          </Text>

          <Text as="h1" variant="subheading">
            {data?.title}
          </Text>

          {!!data?.organization?.data && (
            <Text as="h2" variant="bold" color="lightSalmon">
              <S.OrgName>
                <span>{data?.organization?.data?.attributes?.name}</span>
                {!!data?.organization?.data?.attributes?.rating && (
                  <Stars
                    value={data.organization.data.attributes.rating}
                    max={3}
                    starSize={14}
                    starGap={2}
                    style={{
                      paddingTop: 1,
                    }}
                  />
                )}
              </S.OrgName>
            </Text>
          )}

          {!!data?.areas?.data?.length && (
            <div>{data?.areas?.data?.map(renderItems)}</div>
          )}
        </div>
      </S.Content>
    </S.Wrapper>
  )
}

export default React.memo(Item)
