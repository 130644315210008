import { gql } from '@apollo/client'

export default gql`
  query getAreas {
    areas(sort: ["name:asc"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`
