import styled from 'styled-components'

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  padding-top: 68px;
`

export const Content = styled.div`
  flex: 1;
  flex-direction: column;

  &.inverted {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    color: ${({ theme }) => theme.colors.white};
  }
`
