import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'

import { Loading } from '../sections'
import { useContext } from 'hooks'
import { fromLocation } from 'utils/functions'
import { TLocationState } from 'types/global'

const PreventAuth = (Component: React.ComponentType) => {
  const Wrapper = () => {
    const { state, ...location } = useLocation()

    const { isLoading, isAuthenticated } = useContext('auth')

    if (isLoading) return <Loading />

    return !isAuthenticated ? (
      <Component />
    ) : (
      <Navigate
        to={fromLocation({ state, ...location })}
        state={{ from: location, modal: (state as TLocationState)?.modal }}
        replace
      />
    )
  }

  return <Wrapper />
}

export default PreventAuth
