import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'

import { Button, Input, Modal, Text } from 'ui'
import { useForm, useToggle } from 'hooks'
import validationSchema from 'schemas/passwords'

import { CHANGE_PASSWORD } from 'apollo/mutations'
import { ChangePasswordInput } from 'types/inputs'

import * as S from './styled'

const initialValues: ChangePasswordInput = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
}

type TMutation = TData<{ ok: boolean }, 'changePassword'>

const ChangePassword = () => {
  const { visible, show } = useToggle()

  const { onSubmit, loading, error } = useForm<
    TMutation,
    { data: ChangePasswordInput }
  >(CHANGE_PASSWORD, show)

  const handleSubmit = useCallback(
    async (data, formikHelpers) => {
      await onSubmit({ data }, formikHelpers)
    },
    [onSubmit]
  )

  if (visible)
    return (
      <S.Success>
        <Text as="h1" variant="heading1" color="salmon" align="center">
          Zmena hesla
        </Text>

        <img src="/assets/icons/done.svg" alt="done" width={54} height={54} />

        <Text variant="paragraph" align="center" mb="s">
          Vaše heslo bolo úspešne zmenené.
        </Text>
      </S.Success>
    )

  return (
    <>
      <Text as="h1" variant="heading1" color="salmon" align="center">
        Zmena hesla
      </Text>

      <Formik
        {...{ initialValues, validationSchema }}
        onSubmit={handleSubmit}
        validateOnChange
      >
        {({ errors, touched }) => (
          <Form>
            <Input
              type="password"
              name="currentPassword"
              label="Aktuálne heslo"
              disabled={loading}
              isError={touched.currentPassword && errors.currentPassword}
            />

            <Input
              type="password"
              name="newPassword"
              label="Nové heslo"
              disabled={loading}
              isError={touched.newPassword && errors.newPassword}
            />

            <Input
              type="password"
              name="confirmPassword"
              label="Nové heslo znovu"
              disabled={loading}
              isError={touched.confirmPassword && errors.confirmPassword}
            />

            {!!error && (
              <Text as="small" variant="error">
                {error}
              </Text>
            )}

            <Button type="submit" disabled={loading} isLoading={loading}>
              Zmeniť heslo
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Modal(ChangePassword)
