import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getMe {
    getMe {
      data {
        id
        attributes {
          name
          surname
          avatar {
            ...FileFields
          }
          points {
            data {
              attributes {
                count
                confirmed
              }
            }
          }
        }
      }
    }
  }
`
