import { DateTime } from 'luxon'

import { Term } from 'types/models/term'

export const formatDate = (strDate: string) =>
  DateTime.fromFormat(strDate, 'yyyy-MM-dd').toFormat('dd.MM.yyyy')

export const toInterval = (term: Term) =>
  `${formatDate(term?.beginning)} - ${formatDate(term?.end)}`

export const getNow = () => DateTime.now().toFormat('yyyy-MM-dd')
