import React, { useCallback } from 'react'

import * as S from './styled'

type TProps = {
  nullLabel?: string
  falseLabel: string
  trueLabel: string
  value?: boolean | null
  onChange: (value: boolean | null) => void
}

const Switch = ({
  nullLabel,
  falseLabel,
  trueLabel,
  value,
  onChange,
}: TProps) => {
  const switchTo = useCallback(
    (value: boolean | null) => () => onChange(value),
    [onChange]
  )

  return (
    <S.Wrapper>
      {nullLabel && (
        <S.Button isActive={value === null} onClick={switchTo(null)}>
          {nullLabel}
        </S.Button>
      )}

      <S.Button isActive={value === false} onClick={switchTo(false)}>
        {falseLabel}
      </S.Button>

      <S.Button isActive={value === true} onClick={switchTo(true)}>
        {trueLabel}
      </S.Button>
    </S.Wrapper>
  )
}

export default React.memo(Switch)
