import styled from 'styled-components'

export const Wrapper = styled.article`
  p {
    text-align: center;
  }

  && > form {
    align-items: stretch;

    & > button {
      align-self: center;
    }
  }
`

export const Half = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizes.s};

  @media (max-width: 480px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.sizes.m};
  }
`
