import styled from 'styled-components'

import { Form } from 'formik'

export const Wrapper = styled(Form)`
  width: 100%;
  max-width: 416px;
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: ${({ theme }) => theme.sizes.s};
  }

  & label {
    color: ${({ theme }) => theme.colors.salmon};
  }
`
