import React, { useCallback } from 'react'

import { CarouselItem } from 'components'
import { Carousel, Section, Text } from 'ui'
import { ReferenceEntity } from 'types/models/reference'

type TProps = {
  collection?: ReferenceEntity[]
}

const References = ({ collection }: TProps) => {
  const renderReferences = useCallback(
    ({ id, attributes: item }: ReferenceEntity) => (
      <CarouselItem
        key={`${item?.name}-${id}-reference`}
        image={item?.avatar?.data}
        name={item?.name}
        position={item?.position}
        description={item?.description}
        rating={item?.rating}
      />
    ),
    [collection]
  )

  return (
    <Section hideIf={!collection?.length}>
      <Text
        as="h2"
        variant="heading2"
        color="salmon"
        align="center"
        mb="xxl"
        uppercase
      >
        Hodnotenia od účastníkov
      </Text>

      <Carousel
        isCondensed
        isAutomatic
        isInfinite={(collection?.length || 0) > 3}
      >
        {collection?.map(renderReferences)}
      </Carousel>
    </Section>
  )
}

export default React.memo(References)
