import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;

  min-height: 334px;
  width: 100%;

  position: relative;
  overflow: hidden;

  background-color: ${({ theme }) => theme.colors.salmon};
  box-shadow: ${({ theme }) => theme.shadows.modal};
  border-radius: ${({ theme }) => theme.sizes.xxs};

  img {
    border-top-left-radius: ${({ theme }) => theme.sizes.xxs};
    border-top-right-radius: ${({ theme }) => theme.sizes.xxs};
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`

export const Image = styled.div`
  position: relative;

  & > div {
    position: absolute;
    inset: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background-color: ${({ theme }) => theme.colors.overlay};
    padding-inline: ${({ theme }) => theme.sizes.m};
    padding-block: ${({ theme }) => theme.sizes.l};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.sizes.m};
`

export const OrgName = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.sizes.xs};
  align-items: flex-start;
`
