import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
import { To, useLocation, useNavigate } from 'react-router-dom'

import { Button, Logo, Profile } from 'ui'
import { useContext, useModal, useToggle } from 'hooks'
import { calculateLeftPosition, openBlank } from 'utils/functions'
import { CATEGORIES_ICONS, SUPPORT_LINK } from 'constants/global'
import header from 'constants/header'

import * as S from './styled'

type TMenu = {
  item: string
  left: number
}

const categories = Object.entries(header)

const getPath = (category?: string, subcategory?: string): To =>
  category !== 'static' ? `/${category}/${subcategory}` : `/${subcategory}`

const Navigation = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isAuthenticated } = useContext('auth')

  const {
    state: navigation,
    show: showNavigation,
    hide: hideNavigation,
  } = useModal<TMenu>()
  const {
    visible: mobileVisible,
    toggle: toggleMobileVisible,
    hide: hideMobileVisible,
  } = useToggle()
  const [mobileNavigation, setMobileNavigation] = useState<string>()

  const Buttons = useCallback(
    () => (
      <S.Buttons onMouseOver={hideNavigation}>
        <Button onClick={() => openBlank(SUPPORT_LINK)} isSecondary>
          Podporiť
        </Button>

        {isAuthenticated ? (
          <Profile hide={hideMobileVisible} />
        ) : (
          <Button onClick={() => navigate('/prihlasenie')}>Prihlásiť sa</Button>
        )}
      </S.Buttons>
    ),
    [navigate, isAuthenticated]
  )

  const handleNavigation = useCallback(
    (item: string) => (e: MouseEvent<HTMLButtonElement>) => {
      const target = e.target as HTMLButtonElement
      const left = calculateLeftPosition(target)
      showNavigation({ item, left })
    },
    [showNavigation, calculateLeftPosition]
  )

  const handleMobileNavigation = useCallback(() => {
    document.body.style.overflow = mobileVisible ? 'auto' : 'hidden'
    toggleMobileVisible()
    setMobileNavigation(undefined)
  }, [mobileVisible])

  const handleMobileSubnavigation = useCallback(
    (item: string) => () =>
      setMobileNavigation((prev) => (prev !== item ? item : undefined!)),
    []
  )

  const getSubcategories = useCallback(
    (key = '') => Object.entries(header?.[key]?.subcategories),
    [header]
  )

  const renderNavigation = useCallback(
    (item, idx: number) => (
      <S.Link
        key={`navlink-${item[0]}-${idx}`}
        isActive={navigation?.value?.item === item[0]}
        onMouseEnter={handleNavigation(item[0])}
        onClick={handleNavigation(item[0])}
      >
        {item?.[1].title}
      </S.Link>
    ),
    [navigation]
  )

  const renderSubnavigation = useCallback(
    (item, idx: number) => (
      <S.SubnavigationLink
        key={`link-${item[0]}-${idx}`}
        to={getPath(navigation.value?.item || mobileNavigation, item[0])}
        onClick={hideMobileVisible}
      >
        <img
          src={`${CATEGORIES_ICONS}/${item[0]}.svg`}
          alt={`${item[0]} icon`}
          width={54}
          height={54}
        />

        <div>
          <h6>{item?.[1]?.title}</h6>
          <p>{item?.[1]?.text}</p>
        </div>
      </S.SubnavigationLink>
    ),
    [navigation, mobileNavigation]
  )

  const renderMobileNavigation = useCallback(
    (item, idx: number) => (
      <div key={`mobilelink-${item[0]}-${idx}`}>
        <S.Link
          isActive={mobileNavigation === item[0]}
          onClick={handleMobileSubnavigation(item[0])}
        >
          {item?.[1].title}
        </S.Link>

        <S.AnimateHeightStyled
          duration={200}
          height={mobileNavigation === item[0] ? 'auto' : 0}
        >
          <S.MobileSubnavigation>
            <div>{getSubcategories(item[0])?.map(renderSubnavigation)}</div>
          </S.MobileSubnavigation>
        </S.AnimateHeightStyled>
      </div>
    ),
    [mobileNavigation, renderSubnavigation]
  )

  useEffect(() => {
    hideMobileVisible()
    setMobileNavigation(undefined!)

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [pathname])

  return (
    <S.Wrapper onMouseLeave={hideNavigation}>
      <S.Container>
        <Logo dark={!mobileVisible} />

        <S.Hamburger onClick={handleMobileNavigation}>
          <img
            src={`/assets/icons/${mobileVisible ? 'close' : 'menu'}.svg`}
            alt="menu icon"
          />
        </S.Hamburger>

        <S.Navigation>
          {categories?.map(renderNavigation)}
          <Buttons />
        </S.Navigation>
      </S.Container>

      <S.MobileNavigation isOpened={mobileVisible}>
        {categories?.map(renderMobileNavigation)}
        <Buttons />
      </S.MobileNavigation>

      {navigation.visible && (
        <S.Subnavigation left={navigation.value?.left}>
          {getSubcategories(navigation.value?.item)?.map(renderSubnavigation)}
        </S.Subnavigation>
      )}
    </S.Wrapper>
  )
}

export default React.memo(Navigation)
