import styled from 'styled-components'

export const Wrapper = styled.footer`
  border-top: 20px solid ${({ theme }) => theme.colors.salmon};
  background-color: ${({ theme }) => theme.colors.darkBlue};

  padding: ${({ theme }) => theme.sizes.m} 0;

  & > div {
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      a {
        align-self: center;
      }
    }
  }
`

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  margin-top: ${({ theme }) => theme.sizes.s};

  @media (max-width: 1000px) {
    text-align: center;
    flex-direction: column;
  }

  & > p:first-of-type {
    margin-right: 58px;

    @media (max-width: 1000px) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }

  & > p {
    flex-grow: 1;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.footer};

    @media (max-width: 660px) {
      white-space: normal;
    }

    & > a {
      color: inherit;
      line-height: inherit;
      font-size: inherit;
    }

    &:last-of-type > a {
      display: inline-flex;
      margin-top: 10px;

      & + a {
        margin-left: 10px;
      }
    }
  }
`
