import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import {
  HeaderInfo,
  Informations,
  InterestFixed,
  Lectors,
  Terms,
  Contact,
} from './sections'
import {
  Articles,
  Events,
  Gallery,
  OrganizationInfo,
  References,
  Website,
} from '../sections'

import { Header, Interest } from 'components'
import { Markdown, Section, Video } from 'ui'
import { useContext, useToggle } from 'hooks'
import category from 'constants/category'
import { getNow } from 'utils/date'

import { GET_EVENT } from 'apollo/queries'
import { EventEntityResponseCollection } from 'types/models/event'
import { ENUM_EVENT_CATEGORY } from 'types/enums'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

type TParams = {
  slug: string
}

type TQuery = TData<EventEntityResponseCollection, 'events'>

const Detail = () => {
  const { slug } = useParams<TParams>()
  const { isAuthenticated, user } = useContext('auth')

  const { visible, show, hide } = useToggle()

  const { data, loading, refetch } = useQuery<TQuery>(GET_EVENT, {
    variables: {
      slug,
      now: getNow(),
      id: isAuthenticated ? user?.id : null,
    },
  })

  const dismiss = useCallback(() => {
    refetch()
    hide()
  }, [refetch, hide])

  const root = useMemo(() => data?.events?.data?.[0], [data])
  const content = useMemo(() => root?.attributes, [root])

  const backRoute = useMemo(() => {
    const subcategory = ENUM_EVENT_CATEGORY[content?.category || '']
    return `/${category?.[subcategory]}/${subcategory}`
  }, [content])

  return (
    <>
      <Header
        key={content?.slug}
        title={content?.title}
        description={content?.description}
        icon={ENUM_EVENT_CATEGORY[content?.category || '']}
        image={
          content?.coverImage?.data?.attributes?.formats?.xlarge?.url ||
          content?.coverImage?.data?.attributes?.formats?.large?.url ||
          content?.coverImage?.data?.attributes?.url
        }
        back={backRoute}
        loading={loading}
      >
        {loading ? (
          <SkeletonTheme highlightColor="#FFFFFF">
            <Skeleton height={16} width={120} />
            <Skeleton height={16} width={100} />
            <Skeleton height={16} width={150} />
          </SkeletonTheme>
        ) : (
          <HeaderInfo points={content?.points} capacity={content?.capacity} />
        )}
      </Header>

      {loading ? (
        <Section>
          <Skeleton height={42} width="50%" style={{ marginBottom: '18px' }} />
          <Skeleton count={8} />
          <Skeleton width="80%" />
          <Skeleton height={300} style={{ marginBlock: '18px' }} />
          <Skeleton count={8} />
        </Section>
      ) : (
        <Section hideIf={!content?.body}>
          <Markdown>{content?.body || ''}</Markdown>
        </Section>
      )}

      <Section bg="blue" hideIf={!content?.videoLink}>
        <Video src={content?.videoLink} />
      </Section>

      <Website webLink={content?.webLink} />

      <Lectors collection={content?.lectors?.data} />

      <Informations component={content?.additionalInformations} />

      <Terms collection={content?.terms?.data} />

      <Contact
        email={content?.contactUs?.email}
        name={content?.contactUs?.name}
        event={root?.id}
      />

      <References collection={content?.references?.data} />

      <Gallery collection={content?.gallery?.data} />

      <Articles collection={content?.articles?.data} />

      <Events
        collection={content?.organization?.data?.attributes?.events?.data}
        title="Ďalšie udalosti organizácie"
      />

      <OrganizationInfo entity={content?.organization?.data} />

      {visible && !!content?.upcoming?.data?.length && (
        <Interest
          entity={root}
          terms={content?.upcoming?.data}
          onClose={dismiss}
        />
      )}

      {!content?.interests?.data?.length &&
        !!content?.upcoming?.data?.length && (
          <InterestFixed entity={root} show={show} toLogin={!isAuthenticated} />
        )}
    </>
  )
}

export default React.memo(Detail)
