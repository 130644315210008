import styled, { css, DefaultTheme } from 'styled-components'

import { CROWN } from 'constants/global'

interface IWrapper {
  color?: keyof DefaultTheme['colors']
  variant?: keyof DefaultTheme['fonts']
  size?: 'large' | 'medium' | 'small'
  mb?: keyof DefaultTheme['sizes']
}

const large = css`
  width: 40px;
  height: 24px;
  margin-right: ${({ theme }) => theme.sizes.xxs};
`

const medium = css`
  width: 30px;
  height: 18px;
  margin-right: ${({ theme }) => theme.sizes.xxs};
`

const small = css`
  width: 18px;
  height: 11px;
  margin-right: ${({ theme }) => theme.sizes.xxxs};
`

const sizes = {
  large,
  medium,
  small,
}

export const Wrapper = styled.p<IWrapper>`
  position: relative;
  ${({ theme, mb }) => !!mb && `margin-bottom: ${theme.sizes[mb]}`};

  ${({ theme, variant }) => !!variant && theme.fonts[variant]}
  ${({ theme, color }) => !!color && `color: ${theme.colors[color]};`}
  font-weight: 700;

  span {
    color: ${({ theme }) => theme.colors.salmon};
  }

  &:before {
    content: '';
    background: url(${CROWN});

    display: inline-block;
    ${({ size }) => sizes?.[size || 'small']}

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`
