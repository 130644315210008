import React, { useMemo } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'

import { Header } from 'components'
import { Container, Points } from 'ui'
import { sumPoints } from 'utils/functions'

import { GET_ME } from 'apollo/queries'
import { UsersPermissionsUserEntityResponse } from 'types/models/user'

import * as S from './styled'

type TQuery = TData<UsersPermissionsUserEntityResponse, 'getMe'>

const Profile = () => {
  const { data, loading } = useQuery<TQuery>(GET_ME)

  const content = useMemo(() => data?.getMe?.data?.attributes, [data])
  const totalPoints = useMemo(() => sumPoints(content?.points), [content])

  return (
    <>
      <Header
        title="Profil"
        description="Tu nájdete informácie o vás a vaše absolvované udalosti."
        icon={content?.avatar?.data?.attributes?.url}
        bordered
      >
        {loading ? (
          <Skeleton height={24} width={180} />
        ) : (
          <Points
            points={totalPoints}
            size="medium"
            variant="subheading"
            color="white"
            left="Máte"
          />
        )}
      </Header>

      <S.Tabs role="tabs">
        <Container>
          <NavLink end to="">
            Moje udalosti
          </NavLink>

          <NavLink end to="informacie">
            Môj profil
          </NavLink>
        </Container>
      </S.Tabs>

      <Outlet />
    </>
  )
}

export default React.memo(Profile)
