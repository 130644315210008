import * as Yup from 'yup'

export default Yup.object().shape({
  name: Yup.string().required('Prosím zadajte meno!'),
  surname: Yup.string().required('Prosím zadajte priezvisko!'),
  birthday: Yup.string().required('Prosím zadajte dátum narodenia!'),
  phone: Yup.string().required('Prosím zadajte telefonné číslo!'),
  email: Yup.string()
    .email('Nesprávny formát emailovej adresy!')
    .required('Prosím zadajte emailovú adresu!'),
  city: Yup.string().required('Prosím zadajte mesto!'),
  street: Yup.string().required('Prosím zadajte ulicu!'),
  houseNumber: Yup.string().required('Prosím zadajte popisné číslo!'),
  postcode: Yup.string().required('Prosím zadajte PSČ!'),
  bio: Yup.string()
})
