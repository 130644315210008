import styled from 'styled-components'

export const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  padding-bottom: ${({ theme }) => theme.sizes.xxl};
`

export const ArticlePlaceholder = styled.article<{ flipped?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.sizes.extra};

  ${({ flipped }) => flipped && 'flex-direction: row-reverse;'}

  @media (max-width: 1000px) {
    gap: ${({ theme }) => theme.sizes.section};
  }

  & > span > span {
    width: 365px;
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.sizes.xl};

    && > span > span {
      width: 100%;
    }
  }
`

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 300px;
    aspect-ratio: 1;
    margin-bottom: ${({ theme }) => theme.sizes.s};
  }
`
