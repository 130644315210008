import { gql } from '@apollo/client'

export default gql`
  query getUnconfirmedUserByToken($token: String!) {
    getUnconfirmedUserByToken(token: $token) {
      data {
        id
        attributes {
          email
          name
          surname
          birthday
          phone
        }
      }
    }
  }
`
