import React, { createContext, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import client from 'apollo'
import { fromLocation } from 'utils/functions'

import { AUTH_TOKEN } from 'constants/global'
import { ME } from 'apollo/queries'
import { UsersPermissionsMe } from 'types/models/user'
import { TLocationState } from 'types/global'

type TProps = {
  children: React.ReactNode
}

type TQuery = TData<UsersPermissionsMe, 'me'>

export type AuthContextValue = {
  user?: UsersPermissionsMe
  isAuthenticated: boolean
  isLoading: boolean
  onLogin: (token?: string) => Promise<void>
  onLogout: () => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<AuthContextValue>(undefined!)

export const AuthProvider = ({ children }: TProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    data,
    loading = true,
    error,
    refetch
  } = useQuery<TQuery>(ME, {
    fetchPolicy: 'network-only'
  })

  const handleLogin = useCallback(
    async (token = '') => {
      localStorage.setItem(AUTH_TOKEN, token)

      return refetch().then(() =>
        navigate(fromLocation(location), {
          replace: true,
          state: { modal: (location.state as TLocationState)?.modal }
        })
      )
    },
    [refetch, navigate, location]
  )

  const handleLogout = useCallback(async () => {
    localStorage.removeItem(AUTH_TOKEN)
    await client.resetStore()
  }, [])

  const value = useMemo(
    () => ({
      user: data?.me,
      isLoading: loading,
      isAuthenticated: !!data?.me && !error,
      onLogin: handleLogin,
      onLogout: handleLogout
    }),
    [data, loading, error, handleLogin, handleLogout]
  )

  return <AuthContext.Provider {...{ value }}>{children}</AuthContext.Provider>
}
