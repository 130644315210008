import styled from 'styled-components'

interface IWrapper {
  isError?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  small {
    margin-top: ${({ theme }) => theme.sizes.xxxs};
  }

  input {
    display: none;
  }
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${({ theme }) => theme.sizes.xs};

  span {
    ${({ theme }) => theme.fonts.tag};
    font-weight: 300;
  }
`

export const Placeholder = styled.div`
  justify-content: center;
`

export const Content = styled.label<IWrapper>`
  display: flex;

  min-height: 86px;
  padding-inline: ${({ theme }) => theme.sizes.s};
  padding-block: ${({ theme }) => theme.sizes.m};

  border: 1px dashed
    ${({ theme, isError }) => theme.colors?.[isError ? 'red' : 'lightSalmon']};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.sizes.xxs};

  & > div {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;

    button {
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      text-decoration: underline;
      z-index: 10;
    }

    button + button {
      margin-left: ${({ theme }) => theme.sizes.xs};
    }
  }

  img {
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.sizes.s};
    object-fit: cover;
    object-position: center;
  }
`
