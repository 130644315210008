import React, { useCallback } from 'react'

import { Button, Section, Text } from 'ui'
import { openBlank } from 'utils/functions'
import { Event } from 'types/models/event'

type TProps = Pick<Event, 'webLink'>

const Website = ({ webLink }: TProps) => {
  const open = useCallback(() => openBlank(webLink), [webLink])

  return (
    <Section bg="salmon" col vCenter hideIf={!webLink}>
      <Text
        as="h2"
        variant="heading2"
        color="white"
        align="center"
        mb="s"
        uppercase
      >
        Odkaz na web
      </Text>

      <Text variant="paragraph" color="white" align="center" mb="s">
        Bližšie informácie nájdete na tomto odkaze
      </Text>

      <Button onClick={open} isSecondary>
        Odkaz na web
      </Button>
    </Section>
  )
}

export default React.memo(Website)
