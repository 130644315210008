import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;

  max-width: 100%;
  width: calc(1440px + 2%);

  padding: 0 11.5%;
  margin: 0 auto;
`
