import { gql } from '@apollo/client'

import { FILE_FIELDS } from '../fragments'

export default gql`
  ${FILE_FIELDS}

  query getArticle($slug: String!) {
    articles(filters: { slug: { eq: $slug } }, sort: ["createdAt:desc"]) {
      data {
        id
        attributes {
          title
          slug
          description
          coverImage {
            ...FileFields
          }
          body
        }
      }
    }
  }
`
