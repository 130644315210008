import styled from 'styled-components'

interface IWrapper {
  flipped?: boolean
}

export const Wrapper = styled.article<IWrapper>`
  display: flex;
  gap: ${({ theme }) => theme.sizes.extra};

  ${({ flipped }) => flipped && 'flex-direction: row-reverse;'}

  @media (max-width: 1000px) {
    gap: ${({ theme }) => theme.sizes.section};
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.sizes.xl};

    img {
      width: 100%;
    }
  }
`
