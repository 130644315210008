import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'

import { Articles, Contact, Gallery, References, Website } from '../sections'
import { Header } from 'components'
import { Markdown, Section, Video } from 'ui'

import { GET_SINGLE } from 'apollo/queries'
import { SingleEntityResponseCollection } from 'types/models/single'

type TQuery = TData<SingleEntityResponseCollection, 'singles'>

const Single = () => {
  const { pathname } = useLocation()

  const category = useMemo(() => {
    const splitted = pathname.split('/')
    return splitted[2] || splitted[1]
  }, [pathname])

  const { data, loading } = useQuery<TQuery>(GET_SINGLE, {
    variables: { category }
  })

  const root = useMemo(() => data?.singles?.data?.[0], [data])
  const content = useMemo(() => root?.attributes, [root])

  return (
    <>
      <Header
        key={content?.singleCategory}
        title={content?.title}
        description={content?.description}
      />

      {loading ? (
        <Section>
          <Skeleton height={42} width="50%" style={{ marginBottom: '18px' }} />
          <Skeleton count={8} />
          <Skeleton width="80%" />
          <Skeleton height={300} style={{ marginBlock: '18px' }} />
          <Skeleton count={8} />
        </Section>
      ) : (
        <Section hideIf={!content?.body}>
          <Markdown>{root?.attributes?.body || ''}</Markdown>
        </Section>
      )}

      <Section bg="blue" hideIf={!content?.videoLink}>
        <Video src={content?.videoLink} />
      </Section>

      <Website webLink={content?.webLink} />

      <Contact
        email={content?.contactUs?.email}
        name={content?.contactUs?.name}
      />

      <References collection={content?.references?.data} />

      <Gallery collection={content?.gallery?.data} />

      <Articles collection={content?.articles?.data} />
    </>
  )
}

export default React.memo(Single)
