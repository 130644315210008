import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Button, Points } from 'ui'
import { Success } from '../sections'

const Confirmed = () => {
  const navigate = useNavigate()
  const toLogin = useCallback(
    () => navigate('/prihlasenie', { replace: true }),
    [navigate]
  )

  return (
    <Success
      title="Registrácia"
      text={
        <>
          Úspešne sme overili Váš e-mail.
          <br />
          Môžete sa prihlásiť do Vášho účtu.
        </>
      }
    >
      <Points
        points={10}
        variant="bold"
        color="darkBlue"
        left="Za registráciu ste získali"
      />

      <Button onClick={toLogin}>Prihlásiť sa</Button>
    </Success>
  )
}

export default React.memo(Confirmed)
