import React, { useCallback, useEffect, useRef } from 'react'
import { useFilePicker } from 'use-file-picker'

import Text from '../../Text'
import { useToggle } from 'hooks'

import * as S from './styled'

type Props = {
  label: React.ReactNode
  description?: string
  accept?: string
  defaultValue?: string
  setForm: (file: File | null) => void
}

const Pick = ({
  label,
  description,
  accept = '*',
  setForm,
  defaultValue,
}: Props) => {
  const [openFileSelector, { filesContent, errors, plainFiles, clear }] =
    useFilePicker({
      readAs: 'DataURL',
      accept,
      multiple: false,
      limitFilesConfig: { max: 1 },
      maxFileSize: 20,
    })

  const { visible, hide } = useToggle(true)

  const remove = useCallback(
    (e) => {
      e.stopPropagation()
      setForm(null)
      clear()
      hide()
    },
    [hide, clear]
  )

  const open = useCallback(
    (e) => {
      e.stopPropagation()
      openFileSelector()
    },
    [openFileSelector]
  )

  useEffect(() => {
    setForm(plainFiles?.[0] || null)
  }, [plainFiles])

  const img = useRef<HTMLImageElement | null>(null)

  const replaceImage = useCallback(() => {
    if (img?.current) img.current.src = '/assets/icons/file.svg'
  }, [img])

  return (
    <S.Wrapper>
      <S.Top>
        <label>{label}</label>

        {!!description && <span>{description}</span>}
      </S.Top>

      <S.Content isError={!!errors.length} onClick={openFileSelector}>
        {!!filesContent.length || (!!defaultValue && visible) ? (
          <div>
            <img
              src={filesContent?.[0]?.content || defaultValue}
              alt="file"
              width={47}
              height={47}
              ref={img}
              onError={replaceImage}
            />

            <div>
              <Text color="salmon" weight={500} mb="xxxs">
                {filesContent?.[0]?.name || 'aktuálna profilová fotografia'}
              </Text>

              <button type="button" onClick={open}>
                Upraviť
              </button>

              <button type="button" onClick={remove}>
                Zmazať
              </button>
            </div>
          </div>
        ) : (
          <S.Placeholder>
            <img
              src="/assets/icons/file.svg"
              alt="empty file"
              width={47}
              height={47}
            />

            <Text color="lightGrey" weight={500}>
              Nahrať súbor
            </Text>
          </S.Placeholder>
        )}
      </S.Content>

      {!!errors.length && (
        <Text as="small" variant="error">
          {errors[0].fileSizeToolarge &&
            'Súbor je príliš veľký, maximálna veľkosť 20MB.'}
          {errors[0].readerError &&
            'Vyskytol sa problém, prosím skúste to znovu.'}
          {errors[0].maxLimitExceeded &&
            'Maximálny počet súborov bol presiahnutý.'}
          {errors[0].minLimitNotReached && 'Je požadovaný aspoň jeden súbor.'}
        </Text>
      )}
    </S.Wrapper>
  )
}

export default React.memo(Pick)
