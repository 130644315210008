import React, { useCallback, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { DateTime } from 'luxon'

import { Button, CircularProgress, Input, Modal, Text } from 'ui'
import { useForm } from 'hooks'
import validationSchema from 'schemas/addEvent'

import { ADD_EVENT } from 'apollo/mutations'
import { AddEventInput } from 'types/inputs'

import * as S from './styled'

const initialValues: AddEventInput = {
  name: '',
  place: '',
  eventDate: '',
  description: '',
  specifics: '',
  volunteersAmount: 5,
  registrationEndDate: '',
  organization: '',
  contactEmail: '',
  contactPhone: ''
}

type TMutation = TData<{ ok: boolean }, 'addEvent'>

const AddEvent = () => {
  const [step, setStep] = useState<number>(0)

  const goToNext = () => setStep((prev) => prev + 1)

  const { onSubmit, loading, error } = useForm<
    TMutation,
    { data: AddEventInput }
  >(ADD_EVENT, goToNext)

  const handleSubmit = useCallback(
    async (data, formikHelpers) => {
      await onSubmit({ data }, formikHelpers)
    },
    [onSubmit]
  )

  const eventDatePlaceholder = useRef<string>(
    `${DateTime.local().toFormat('dd.MM.yyyy')} - ${DateTime.local()
      .plus({ days: 3 })
      .toFormat('dd.MM.yyyy')}`
  )
  const registrationEndDatePlaceholder = useRef<string>(
    DateTime.local().minus({ days: 3 }).toFormat('dd.MM.yyyy')
  )

  return (
    {
      0: (
        <>
          <S.FormTitle variant="subheading" color="blue">
            Pridať príležitosť
          </S.FormTitle>
          <Formik
            {...{ initialValues, validationSchema }}
            onSubmit={handleSubmit}
            validateOnChange
          >
            {({ errors, touched }) => (
              <S.FormWrapper>
                <Form>
                  <Input
                    required
                    type="text"
                    name="name"
                    label="Názov dobrovoľníckej ponuky"
                    placeholder="Dobrovoľnícka ponuka"
                    disabled={loading}
                    isError={touched.name && errors.name}
                  />
                  <Input
                    required
                    type="text"
                    name="place"
                    label="Miesto konania"
                    placeholder="Nové Zámky"
                    disabled={loading}
                    isError={touched.place && errors.place}
                  />
                  <Input
                    required
                    type="text"
                    name="eventDate"
                    label="Dátum konania"
                    placeholder={eventDatePlaceholder.current}
                    disabled={loading}
                    isError={touched.eventDate && errors.eventDate}
                  />
                  <Input
                    required
                    as="textarea"
                    type="text"
                    name="description"
                    label="Popis dobrovoľníckej činnosti"
                    placeholder="Základné informácie o činnosti"
                    disabled={loading}
                    isError={touched.description && errors.description}
                  />
                  <Input
                    type="text"
                    as="textarea"
                    name="specifics"
                    label="Špecifiká pre dobrovoľníka"
                    placeholder="Napr. minimálny vek, špeciálne skúsenosti alebo charakterové vlastnosti a pod."
                    disabled={loading}
                    isError={touched.specifics && errors.specifics}
                  />
                  <Input
                    required
                    type="number"
                    name="volunteersAmount"
                    label="Počet potrebných dobrovoľníkov"
                    disabled={loading}
                    isError={
                      touched.volunteersAmount && errors.volunteersAmount
                    }
                  />
                  <Input
                    required
                    type="text"
                    name="registrationEndDate"
                    label="Dátum prihlásenia"
                    placeholder={registrationEndDatePlaceholder.current}
                    disabled={loading}
                    isError={
                      touched.registrationEndDate && errors.registrationEndDate
                    }
                  />
                  <Input
                    required
                    type="text"
                    name="organization"
                    label="Názov organizácie"
                    placeholder="Organizácia XYZ"
                    disabled={loading}
                    isError={touched.organization && errors.organization}
                  />
                  <Input
                    required
                    type="text"
                    name="contactEmail"
                    label="Kontakt na kontaktnú osobu - email"
                    placeholder="kontaktnaosoba@example.com"
                    disabled={loading}
                    isError={touched.contactEmail && errors.contactEmail}
                  />
                  <Input
                    type="text"
                    name="contactPhone"
                    label="Kontakt na kontaktnú osobu - tel. č."
                    placeholder="+421909999999"
                    disabled={loading}
                    isError={touched.contactPhone && errors.contactPhone}
                  />

                  {!!error && (
                    <Text as="small" variant="error">
                      {error}
                    </Text>
                  )}

                  <Button type="submit" disabled={loading} isLoading={loading}>
                    Odoslať
                  </Button>
                </Form>

                {loading && (
                  <S.Loading>
                    <CircularProgress />
                  </S.Loading>
                )}
              </S.FormWrapper>
            )}
          </Formik>
        </>
      ),
      1: (
        <S.Success>
          <Text as="h1" variant="heading1" color="salmon" align="center">
            Pridanie príležitosti
          </Text>

          <img
            src={error ? '/assets/icons/deny.svg' : '/assets/icons/done.svg'}
            alt="done"
            width={54}
            height={54}
          />

          <Text variant="paragraph" align="center" mb="s">
            {error
              ? 'Vznikla chyba pri pridaní príležitosti!'
              : 'Pridanie príležitosti úspešne odoslané!'}
          </Text>
        </S.Success>
      )
    }[step] || null
  )
}

export default Modal(AddEvent)
