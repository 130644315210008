import React from 'react'

import { FormLocation, Header } from 'components'
import { Section, Text } from 'ui'
import { CONTACT_MAP, EMAIL_ADDRESS, PHONE_NUMBER } from 'constants/global'

import * as S from './styled'

const Contact = () => (
  <>
    <Header key="contact-us" />

    <Section flex="both" gap="l" row width={200}>
      <S.Info>
        <Text as="h2" variant="heading2" color="salmon" mb="s" uppercase>
          Občianske združenie
        </Text>

        <Text variant="paragraph">
          VIAC - Inštitút pre podporu a rozvoj mládeže
          <br />
          Ústie nad Priehradou 41, 028 01 Trstená
          <br />
          IČO: 42 217 202
          <br />
          DIČ: 2023 297 287
        </Text>
      </S.Info>

      <S.Info>
        <Text as="h2" variant="heading2" color="salmon" mb="s" uppercase>
          Naše kancelárie
        </Text>

        <Text variant="paragraph">
          Železničiarov 253/1, 028 01 Trstená
          <br />
          Štefánikova 2, 058 01, Poprad
          <br />
          email:&nbsp;
          <S.Link href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</S.Link>
          <br />
          tel:&nbsp;<S.Link href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</S.Link>
        </Text>
      </S.Info>
    </Section>

    <Section bg="blue">
      <FormLocation to={EMAIL_ADDRESS} location="Kontaktuj nás" />
    </Section>

    <iframe
      width="100%"
      height="300"
      title="map"
      src={CONTACT_MAP}
      scrolling="no"
    />
  </>
)

export default React.memo(Contact)
