import React, { useCallback } from 'react'
import { ErrorMessage, Field, FieldAttributes } from 'formik'

import Text from '../../Text'

import * as S from './styled'

type TOption = {
  value: string | number
  label: string
}

type TProps = {
  label: React.ReactNode
  isError?: boolean
  options?: TOption
} & FieldAttributes<any>

const Select = ({ name, label, isError, options, ...props }: TProps) => {
  const renderOptions = useCallback(
    (item: TOption) => (
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    ),
    [options]
  )

  return (
    <S.Wrapper {...{ isError }}>
      <label htmlFor={name}>{label}</label>

      <Field as="select" id={name} {...{ name, ...props }}>
        {options?.map(renderOptions)}
      </Field>

      {isError && (
        <Text as="small" variant="error">
          <ErrorMessage {...{ name }} />
        </Text>
      )}
    </S.Wrapper>
  )
}

export default React.memo(Select)
