import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'

import { Button, Container, Points } from 'ui'
import { EventEntity } from 'types/models/event'

import * as S from './styled'

type TProps = {
  entity?: EventEntity
  toLogin?: boolean
  show: () => void
}

const InterestFixed = ({ entity, show, toLogin }: TProps) => {
  const navigate = useNavigate()

  const navigateToLogin = useCallback(
    () => navigate('/prihlasenie'),
    [navigate]
  )

  useEffect(() => {
    document.body.style.paddingBottom = '126px'

    return () => {
      document.body.style.paddingBottom = '0px'
    }
  }, [entity])

  return (
    <S.Wrapper>
      <Container>
        <Button onClick={toLogin ? navigateToLogin : show}>Mám záujem</Button>

        <Points
          points={entity?.attributes?.points || 0}
          size="large"
          variant="subheading"
          color="darkBlue"
          left="Za túto udalosť získate"
        />
      </Container>
    </S.Wrapper>
  )
}

export default React.memo(InterestFixed)
