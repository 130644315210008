import React, { useCallback } from 'react'

import { Organization as TOrganization } from 'types/models/organization'
import { Stars, Tag, Text } from 'ui'
import REGIONS from 'constants/regions'
import { AreaEntity } from 'types/models/area'

import * as S from './styled'

type TProps = {
  entity?: TOrganization
}

const Organization = ({ entity }: TProps) => {
  const renderItems = useCallback(
    ({ attributes: item }: AreaEntity) => (
      <Tag key={item?.name}>{item?.name}</Tag>
    ),
    []
  )

  return (
    <S.Wrapper to={`/inspiracia/dobrovolnicke-organizacie/${entity?.slug}`}>
      <S.Image>
        <img
          src={
            entity?.coverImage?.data?.attributes?.formats?.xlarge?.url ||
            entity?.coverImage?.data?.attributes?.formats?.large?.url ||
            entity?.coverImage?.data?.attributes?.url ||
            ''
          }
          alt={entity?.coverImage?.data?.attributes?.alternativeText}
          width={300}
          height={209}
        />

        <div>
          <Text as="span" variant="bold" color="white" mb="xs">
            {REGIONS?.[entity?.region || '']}
          </Text>

          {!!entity?.areas?.data?.length && (
            <div>{entity?.areas?.data?.map(renderItems)}</div>
          )}
        </div>
      </S.Image>

      <S.Content>
        <Text as="h2" variant="subheading" color="white" mb="s">
          <S.OrgName>
            <span>{entity?.name}</span>
            {!!entity?.rating && (
              <Stars value={entity.rating} max={3} starSize={22} starGap={2} />
            )}
          </S.OrgName>
        </Text>

        <Text variant="bold" color="grey">
          Aktuálne ponuky:{' '}
          <Text as="span" color="white">
            {entity?.events?.data?.length || 0}
          </Text>
        </Text>
      </S.Content>
    </S.Wrapper>
  )
}

export default React.memo(Organization)
