import styled from 'styled-components'

interface IWrapper {
  content?: string
}

export const Wrapper = styled.a<IWrapper>`
  position: relative;

  img {
    width: 100%;
    height: 358px;
  }

  &::before {
    content: '';
    ${({ content }) => !!content && `content: "${content}";`};
    z-index: 1;

    text-align: center;
    ${({ theme }) => theme.fonts.large};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    inset: 0;

    background-color: ${({ theme }) => theme.colors.overlay};
    border-radius: ${({ theme }) => theme.sizes.xxs};
  }
`
