import styled, { css } from 'styled-components'

interface IWrapper {
  isError?: boolean
  type?: string
  defaultDisabled?: boolean
}

const checkbox = css`
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.sizes.xs};

  label {
    margin: 0;
  }

  input {
    cursor: pointer;
    width: auto;
  }
`

export const Top = styled.div<{ type?: string }>`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizes.xs};

  a {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
  }

  ${({ theme, type }) =>
    type !== 'checkbox' && `margin-bottom: ${theme.sizes.xs}`};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  small {
    margin-top: ${({ theme }) => theme.sizes.xxxs};
  }
`

export const RequiredAsterisk = styled.span`
  color: ${({ theme }) => theme.colors.red};
`

export const Field = styled.div<IWrapper>`
  display: flex;
  flex-direction: column;
  width: 100%;

  input,
  textarea {
    background-color: ${({ theme, defaultDisabled }) =>
      theme.colors[defaultDisabled ? 'lightGrey' : 'white']};
    border: 1px solid
      ${({ theme, isError }) => theme.colors?.[isError ? 'red' : 'lightSalmon']};
  }

  ${({ type }) => type === 'checkbox' && checkbox}
`
