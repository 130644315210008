import styled, { css, DefaultTheme } from 'styled-components'

interface IWrapper {
  bg?: keyof DefaultTheme['colors']
  row?: boolean
  col?: boolean
  hCenter?: boolean
  vCenter?: boolean
  gap?: keyof DefaultTheme['sizes']
  flex?: 'left' | 'right' | 'both'
  width?: number
}

export const Wrapper = styled.section<IWrapper>`
  width: 100%;
  background-color: ${({ theme, bg }) => theme.colors[bg || 'background']};
  padding-block: ${({ theme }) => theme.sizes.section};

  & > div {
    display: block;
    ${({ row }) => row && 'display: flex; flex-direction: row;'}
    ${({ col }) => col && 'display: flex; flex-direction: column;'}

    ${({ hCenter }) => hCenter && 'justify-content: center;'}
    ${({ vCenter }) => vCenter && 'align-items: center;'}

    ${({ theme, gap }) => gap && `gap: ${theme.sizes[gap]}`};

    position: relative;

    max-width: 100%;
    width: calc(1440px + 2%);

    padding: 0 11.5%;
    margin: 0 auto;

    ${({ theme, width, gap }) =>
      width &&
      css`
        @media (max-width: ${width * 2 +
          +theme.sizes?.[gap || 'xxxs'].replace('px', '') +
          150}px) {
          flex-direction: column;
          ${gap && `gap: calc(${theme.sizes[gap]} / 3)`};
        }
      `}

    & > * {
      ${({ flex }) => flex === 'both' && 'flex: 1;'}

      &:first-child {
        ${({ flex }) => flex === 'left' && 'flex: 1;'}
        ${({ flex, width }) =>
          flex === 'right' &&
          width &&
          css`
            max-width: ${width}px;
          `}

          ${({ theme, width, flex, gap }) =>
          width &&
          flex === 'right' &&
          css`
            @media (max-width: ${width * 2 +
              +theme.sizes?.[gap || 'xxxs'].replace('px', '') +
              150}px) {
              max-width: 100%;
            }
          `}
      }

      &:last-child {
        ${({ flex }) => flex === 'right' && 'flex: 1;'}

        ${({ theme, width, flex, gap }) =>
          width &&
          flex === 'left' &&
          css`
            @media (max-width: ${width * 2 +
              +theme.sizes?.[gap || 'xxxs'].replace('px', '') +
              150}px) {
              max-width: 100%;
            }
          `}
      }
    }
  }
`
