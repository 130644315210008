import React, { useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import * as S from './styled'

type TProps = {
  value: number
  max?: number
  starSize?: number
  starGap?: number
  className?: string
  style?: React.CSSProperties
}

const src = '/assets/icons/star.svg'

const Stars = ({
  value,
  max = 5,
  starSize = 27,
  starGap = 4,
  className,
  style,
}: TProps) => {
  const id = useRef(uuidv4())

  return (
    <S.Wrapper {...{ className, style }} $starGap={starGap}>
      {[...Array(max)].map((_, idx) => (
        <S.Star
          key={`stars-${id}-star-${idx}`}
          max={idx + 1}
          $starSize={starSize}
          {...{ src, value }}
        />
      ))}
    </S.Wrapper>
  )
}

export default React.memo(Stars)
