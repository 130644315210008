import React, { useMemo } from 'react'
import { To } from 'react-router'

import { Points, Stars, Text } from 'ui'
import { PointEntity } from 'types/models/point'
import { toInterval } from 'utils/date'
import category from 'constants/category'
import { ENUM_EVENT_CATEGORY } from 'types/enums'

import * as S from './styled'

type TProps = {
  entity?: PointEntity
}

const Point = ({ entity }: TProps) => {
  const interest = useMemo(
    () => entity?.attributes?.interest?.data?.attributes,
    [entity]
  )
  const event = useMemo(() => interest?.event?.data?.attributes, [interest])

  const toEvent = useMemo((): To => {
    const subcategory = ENUM_EVENT_CATEGORY[event?.category || '']
    return `/${category?.[subcategory]}/${subcategory}/${event?.slug}`
  }, [event, category])

  const toOrganization = useMemo((): To => {
    return `/inspiracia/dobrovolnicke-organizacie/${event?.organization?.data?.attributes?.slug}`
  }, [event, category])

  return (
    <S.Wrapper>
      {interest?.event?.data ? (
        <img
          src={
            event?.coverImage?.data?.attributes?.formats?.xlarge?.url ||
            event?.coverImage?.data?.attributes?.formats?.large?.url ||
            event?.coverImage?.data?.attributes?.url ||
            ''
          }
          alt="event cover image"
          width={86}
          height={86}
        />
      ) : (
        <div />
      )}

      <S.Content>
        <div>
          {interest?.event?.data ? (
            <S.Link to={toEvent}>{event?.title}</S.Link>
          ) : (
            <Text as="h2" variant="subheading" weight={700}>
              {entity?.attributes?.description}
            </Text>
          )}

          <Text variant="paragraph" weight={400}>
            {interest?.term?.data?.attributes &&
              toInterval(interest.term.data.attributes)}
          </Text>
        </div>

        <div>
          <Points
            points={entity?.attributes?.count || 0}
            size="medium"
            variant="subheading"
            hideText
          />
        </div>

        <div>
          {!!event?.organization?.data && (
            <S.Link to={toOrganization}>
              <S.OrgName>
                {event?.organization?.data?.attributes?.name}
                {event?.organization?.data?.attributes?.rating && (
                  <Stars
                    value={event.organization.data.attributes.rating}
                    max={3}
                    starSize={20}
                    starGap={2}
                  />
                )}
              </S.OrgName>
            </S.Link>
          )}
        </div>
      </S.Content>
    </S.Wrapper>
  )
}

export default React.memo(Point)
