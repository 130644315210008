import { css, DefaultTheme } from 'styled-components'

export default {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    grey: '#4F4F4F',
    red: '#FF3B3B',
    yellow: '#FFC02E',
    lightGrey: '#DADADA',
    lighterGrey: '#E6E6E6',
    darkBlue: '#141850',
    blue: '#303179',
    darkSalmon: '#CB9D13',
    salmon: '#ECB613',
    lightSalmon: '#F3D783',
    lighterSalmon: '#FBEEC8',
    modal: 'rgba(38, 40, 57, 0.785894)',
    overlay: 'rgba(0, 0, 0, 0.3)',
    placeholder: '#C4C4C4',
    footer: '#F3F3F3',
    background: '#F9FAFD',
    secondaryBackground: '#4547AC',
    logo: '#696D70',
    success: '#1ED300',
    blueBackground: 'linear-gradient(124.36deg, #3E3F8B 0%, #141850 100%)',
  } as const,

  sizes: {
    xxxs: '4px',
    xxs: '8px',
    xs: '12px',
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '40px',
    xxl: '48px',
    xxxl: '56px',
    section: '64px',
    extra: '140px',
  } as const,

  shadows: {
    term: '0px 2px 30px rgba(0, 0, 0, 0.15)',
    card: '0px 2px 73px rgba(0, 0, 0, 0.2)',
    modal: '0px 4px 30px rgba(0, 0, 0, 0.3)',
    nav: '0px 2px 73px rgba(0, 0, 0, 0.09)',
    filter: '0px 2px 20px rgba(0, 0, 0, 0.3)',
  },

  fonts: {
    heading1: css`
      font-size: clamp(28px, 3vw + 1rem, 36px);
      line-height: clamp(37px, 3vw + 1rem, 45px);
      font-weight: 700;
      color: inherit;
    `,
    heading2: css`
      font-size: clamp(28px, 3vw + 1rem, 36px);
      line-height: clamp(36px, 3vw + 1rem, 44px);
      font-weight: 500;
      color: inherit;
    `,
    subheading: css`
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: inherit;
    `,
    paragraph: css`
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
      letter-spacing: 0.5px;
      color: inherit;
    `,
    large: css`
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      color: inherit;
    `,
    link: css`
      font-size: 15px;
      line-height: 18px;
      font-weight: 300;
    `,
    bold: css`
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;
    `,
    error: css`
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: ${({ theme }) => theme.colors.red};
    `,
    tag: css`
      font-size: 14px;
      line-height: 17px;
    `,
  },
} as DefaultTheme
