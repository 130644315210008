import styled from 'styled-components'
import { transparentize } from 'polished'

import { Text } from 'ui'

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizes.m};
  align-items: center;
`

export const FormTitle = styled(Text)`
  padding-bottom: ${({ theme }) => theme.sizes.xxs};
`

export const FormWrapper = styled.div`
  position: relative;
`

export const Loading = styled.div`
  background: ${({ theme }) => transparentize(0.25, theme.colors.background)};
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
