import React, { useCallback } from 'react'

import { Tag, Text } from 'ui'
import REGIONS from 'constants/regions'
import { Organization } from 'types/models/organization'
import { AreaEntity } from 'types/models/area'

import * as S from './styled'

type TProps = Pick<Organization, 'region' | 'areas'>

const HeaderInfo = ({ region, areas }: TProps) => {
  const renderItems = useCallback(
    ({ attributes: item }: AreaEntity) => (
      <Tag variant="subheading" key={item?.name}>
        {item?.name}
      </Tag>
    ),
    [areas]
  )

  return (
    <>
      {!!region && (
        <Text variant="subheading" color="white" weight={700}>
          {REGIONS[region]}
        </Text>
      )}

      {!!areas?.data?.length && (
        <S.Areas>{areas?.data?.map(renderItems)}</S.Areas>
      )}
    </>
  )
}

export default React.memo(HeaderInfo)
