/**
 * Hook to memoize dependencies
 * @author 8BITES
 * @param deps {Record<keyof T, unknown>} Dependencies object
 * @returns {Record<keyof T, string>} Memoized dependencies object
 */
const useMemoDeps = <T>(
  deps: Record<keyof T, unknown>
): Record<keyof T, string> => {
  return Object.entries(deps).reduce(
    (acc, [key, value]) => {
      acc[key as keyof T] = JSON.stringify(value)

      return acc
    },
    {} as Record<keyof T, string>
  )
}

export default useMemoDeps
