export const PREFIX = '@DOBROVOLNICTVOZA'

export const AUTH_TOKEN = `${PREFIX}/AUTH_TOKEN`

export const CATEGORIES_ICONS = '/assets/icons/categories'
export const USER_PLACEHOLDER = '/assets/icons/user-placeholder.svg'

export const LOGO = '/assets/logo-compact.png'
export const CROWN = '/assets/icons/crown.svg'

export const SUPPORT_LINK = 'http://ozviac.sk/podporit'
export const CONTACT_MAP =
  'https://maps.google.com/maps?q=V.I.A.C.%20-%20In%C5%A1tit%C3%BAt%20pre%20podporu%20a%20rozvoj%20ml%C3%A1de%C5%BEe%20%C3%9Astie%20nad%20Priehradou%2041,%20028%2001%20Trsten%C3%A1&t=&z=15&ie=UTF8&iwloc=&output=embed'

// Contact
export const PHONE_NUMBER = '+421 904 540 309'
export const EMAIL_ADDRESS = 'dobrovolnictvo@ozviac.sk'

// Social networks
export const FACEBOOK_LINK = 'https://www.facebook.com/projektzmenme.to/'
export const INSTAGRAM_LINK = 'https://www.instagram.com/zmenme.to/'
export const YOUTUBE_LINK =
  'https://www.youtube.com/playlist?list=PL3VlRXOldXEiqIhg4JLH66nXwICpbOl7X'
