import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    outline: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: "Publica Sans", sans-serif;
    background: ${({ theme }) => theme.colors.background};

    color: ${({ theme }) => theme.colors.grey};
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    font-style: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  img {
    display: block;
  }

  iframe {
    border: 0;
    display: block;
  }

  a {
    text-decoration: none;
  }

  button {
    background-color: transparent;
    cursor: pointer;
    color: inherit;
    border: 0;
  }

  input, button, textarea, select {
    font-family: "Publica Sans", sans-serif;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    cursor: pointer;
    user-select: none;
    color: ${({ theme }) => theme.colors.grey};

    & > a {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.salmon};
      text-decoration: underline;
    }
  }

  input[type="date"] {
    max-height: 44px;
  }

  input,
  textarea,
  select {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.lightSalmon};
    border-radius: ${({ theme }) => theme.sizes.xxs};

    padding: ${({ theme }) => theme.sizes.xs};

    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.grey};

    &::placeholder {
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }

  textarea {
    min-height: 120px;
    max-height: 320px;
    resize: vertical;
  }

  .loading {
    & {
      position: relative;
      pointer-events: none;
      cursor: progress;
      width: 100%;
      min-height: 2em;

      &::before {
        content: '';
        display: inline-flex;
        opacity: 0.5;
        z-index: 100;
        position: absolute;
        border-radius: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.colors.lighterGrey};
        border-radius: 8px;
        padding: ${({ theme }) => theme.sizes.xs};
      }

      &::after {
        content: ' ';
        display: inline-flex;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        z-index: 120;

        width: 15px;
        height: 15px;

        border-radius: 50%;
        border: 3px solid ${({ theme }) => theme.colors.blue};
        border-color: ${({ theme }) => theme.colors.blue} transparent
          ${({ theme }) => theme.colors.blue} transparent;

        animation: dual-ring 1.2s linear infinite;

        @keyframes dual-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  .multiline {
    &&::before { // empty
      background:
        linear-gradient(#E6E6E6, #E6E6E6) left 0px top 0px no-repeat,
        linear-gradient(#E6E6E6, #E6E6E6) left 0px top 1.5em no-repeat,
        linear-gradient(#E6E6E6, #E6E6E6) left 0px top 3em no-repeat,
        linear-gradient(#E6E6E6, #E6E6E6) left 0px top 4.5em no-repeat;
      background-size: 100% 1em, 100% 1em, 100% 1em, 60% 1em;
    }
  }

  .skeleton {
    & {
      position: relative;
      width: 100%;
      min-height: 5.5em;
      overflow-x: hidden;

      pointer-events: none;
      user-select: none;
      cursor: progress;


      &::before {
        content: '';
        z-index: 100;
        cursor: progress;

        display: inline-flex;
        opacity: 0.4;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background:
          linear-gradient(#E6E6E6, #E6E6E6) left 0px top 0px no-repeat;
        background-size: 100% 1em;

        padding: ${({ theme }) => theme.sizes.xs};
      }

      &::after {
        content: ' ';
        z-index: 120;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: inline-flex;
        transform: translateX(-100%);

        background:
          linear-gradient(0.25turn, transparent, #F9FAFD 50%, transparent),
          linear-gradient(transparent, transparent);
        background-repeat: no-repeat;

        animation: shimmer 1.5s infinite;

        @keyframes shimmer {
          100% {
            transform: translateX(100%);
          }
        }
      }
    }
  }
`
