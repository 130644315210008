import { useState, useCallback } from 'react'

type State<T> = {
  visible: boolean
  value?: T
}

type UseModal<T> = {
  state: State<T>
  show: (value?: T) => void
  hide: () => void
  toggle: (value?: T) => void
}

export default <T>(initialValue?: T, initialVisible = false): UseModal<T> => {
  const [state, setState] = useState<State<T>>({
    visible: initialVisible,
    value: initialValue,
  })

  const show = useCallback(
    (value?: T) => setState({ visible: true, value }),
    [setState]
  )

  const hide = useCallback(
    () => setState({ visible: false, value: undefined }),
    [setState]
  )

  const toggle = useCallback(
    (value?: T) =>
      setState((prev) =>
        prev.visible
          ? { visible: false, value: undefined }
          : { visible: true, value }
      ),
    [setState]
  )

  return { state, show, hide, toggle }
}
