import * as Yup from 'yup'

export default Yup.object().shape({
  currentPassword: Yup.string().required('Prosím zadajte aktuálne heslo!'),
  newPassword: Yup.string()
    .min(6, 'Heslo musí mať najmenej 6 znakov')
    .required('Prosím zadajte nové heslo!'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Heslá sa musia zhodovať!'
  )
})
