export const PREFIX = '@DOBROVOLNICTVOZA'

export const AUTH_TOKEN = `${PREFIX}/AUTH_TOKEN`

export const CATEGORIES_ICONS = '/assets/icons/categories'
export const USER_PLACEHOLDER = '/assets/icons/user-placeholder.svg'

export const LOGO = '/assets/logo-compact.png'
export const CROWN = '/assets/icons/crown.svg'

export const SUPPORT_LINK = 'https://www.ozviac.sk/modlitebna-podpora-2'
export const CONTACT_MAP =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10391.408335646156!2d19.569836953269427!3d49.373871728742735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715c6de167689c9%3A0x5c1af5a7c8cb08d8!2sV.I.A.C.!5e0!3m2!1ssk!2ssk!4v1740485563747!5m2!1ssk!2ssk'

// Contact
export const PHONE_NUMBER = '+421 903 262 166'
export const EMAIL_ADDRESS = 'dobrovolnictvo@ozviac.sk'

// Social networks
export const FACEBOOK_LINK = 'https://www.facebook.com/projektzmenme.to/'
export const INSTAGRAM_LINK = 'https://www.instagram.com/zmenme.to/'
export const YOUTUBE_LINK =
  'https://www.youtube.com/playlist?list=PL3VlRXOldXEiqIhg4JLH66nXwICpbOl7X'
