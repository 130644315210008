import React, { useMemo, useState } from 'react'
import { Formik } from 'formik'

import { Button, Input, Text } from 'ui'
import { useContext, useForm } from 'hooks'
import validationSchema from 'schemas/form'

import { SEND } from 'apollo/mutations'
import { SendResponse } from 'types/models/email'
import { SendLocationInput } from 'types/inputs'

import * as S from './styled'

type TProps = Pick<SendLocationInput, 'to' | 'location'>

type TMutation = TData<SendResponse, 'send'>

const FormLocation = ({ to, location }: TProps) => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined)

  const { user } = useContext('auth')

  const initialValues = useMemo(
    (): SendLocationInput => ({
      email: user?.email || '',
      to: to || '',
      location: location || '',
      body: '',
    }),
    [user, to, location]
  )

  const { onSubmit, loading, error } = useForm<
    TMutation,
    { data: SendLocationInput }
  >(SEND, (data) => setSuccess(data?.send?.ok))

  return (
    <Formik
      {...{ initialValues, validationSchema }}
      onSubmit={(values, formikHelpers) => {
        onSubmit({ data: values }, formikHelpers)
      }}
      validateOnChange
      enableReinitialize
    >
      {({ errors, touched }) => (
        <S.Wrapper>
          <Input
            type="email"
            name="email"
            label="Tvoj e-mail"
            disabled={!!initialValues?.email || loading}
            defaultDisabled={!!initialValues?.email}
            isError={touched.email && errors.email}
            required
          />

          <Input
            as="textarea"
            name="body"
            label="Tvoja otázka"
            isError={touched.body && errors.body}
            disabled={loading}
            required
          />

          {!!error && (
            <Text as="small" variant="error">
              {error}
            </Text>
          )}

          <Button type="submit" disabled={loading} isLoading={loading}>
            Odoslať
          </Button>

          {success !== undefined && (
            <Text as="small" color="white">
              {success
                ? 'Vaša správa bola úspešne odoslaná.'
                : 'Vašu správu sa nepodarilo odoslať.'}
            </Text>
          )}
        </S.Wrapper>
      )}
    </Formik>
  )
}

export default React.memo(FormLocation)
