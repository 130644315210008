import { gql } from '@apollo/client'

export default gql`
  query getEvents(
    $eventFilters: EventFiltersInput
    $growniProjectFilters: GrowniProjectFiltersInput
    $now: Date!
  ) {
    events(filters: $eventFilters, sort: ["updatedAt:desc", "createdAt:desc"]) {
      data {
        id
        attributes {
          category
          title
          slug
          description
          points
          capacity
          location
          createdAt
          updatedAt
          terms(
            filters: { beginning: { gte: $now } }
            pagination: { limit: 1 }
          ) {
            data {
              id
              attributes {
                beginning
                end
              }
            }
          }
          areas(sort: ["name:asc"]) {
            data {
              id
              attributes {
                name
              }
            }
          }
          organization {
            data {
              id
              attributes {
                name
                rating
              }
            }
          }
          coverImage {
            data {
              id
              attributes {
                url
                alternativeText
                formats
              }
            }
          }
        }
      }
    }
    growniProjects(
      filters: $growniProjectFilters
      sort: [
        "externalUpdatedAt:desc"
        "externalCreatedAt:desc"
        "updatedAt:desc"
        "createdAt:desc"
      ]
    ) {
      data {
        id
        attributes {
          url
          title
          image
          duration {
            id
            value
          }
          how {
            id
            value
          }
          isoRegion
          externalCreatedAt
          externalUpdatedAt
          topics {
            id
            value
          }
        }
      }
    }
  }
`
