import React from 'react'

type WithLoadingProps = {
  isLoading?: boolean
  multiline?: boolean
}

// You know what you're doing...
// But eslint doesn't think so...
/* eslint-disable */
const WithLoading =
  <T extends object>(
    Component: React.ComponentType<T>
  ): React.FC<T & WithLoadingProps> =>
  ({ isLoading, multiline, ...props }: WithLoadingProps): JSX.Element =>
    (
      <Component
        className={`${isLoading ? 'skeleton' : ''} ${
          multiline ? 'multiline' : ''
        }`}
        {...(props as T)}
      />
    )
/* eslint-enable */

export default WithLoading
