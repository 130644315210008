import { gql } from '@apollo/client'

export default gql`
  mutation updateMe($id: ID!, $input: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $input) {
      data {
        id
        attributes {
          name
          surname
          phone
          city
          street
          houseNumber
          postcode
          bio
          avatar {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`
