import styled, { css } from 'styled-components'

import { CROWN } from 'constants/global'

export const crown = css<{ bigCrown?: boolean }>`
  &:before {
    content: '';
    background: url(${CROWN});
    display: inline-block;

    width: ${({ bigCrown }) => (bigCrown ? 30 : 18)}px;
    height: ${({ bigCrown }) => (bigCrown ? 18 : 11)}px;
    margin-right: ${({ theme }) => theme.sizes.xxxs};

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`

export const Crown = styled.strong`
  position: relative;
  display: block;
  padding-left: 21px;

  ${crown}

  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.darkBlue};

  & > span {
    color: ${({ theme }) => theme.colors.salmon};
  }
`

const spinnerLoader = css`
  & {
    position: relative;
    pointer-events: none;
    cursor: progress;

    &:before {
      content: '';
      display: inline-flex;
      background: inherit;
      opacity: 0.8;
      z-index: 100;
      position: absolute;
      border-radius: inherit;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:after {
      content: ' ';
      display: inline-flex;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      z-index: 120;

      width: 15px;
      height: 15px;

      border-radius: 50%;
      border: 3px solid ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.white} transparent
        ${({ theme }) => theme.colors.white} transparent;

      animation: dual-ring 1.2s linear infinite;

      @keyframes dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`

export const loader = css<{ isLoading: boolean }>`
  ${({ isLoading }) => isLoading && spinnerLoader}
`
