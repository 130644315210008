import React, { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'

import { Filter, Header, Organization } from 'components'
import { Grid, Section } from 'ui'

import { GET_ORGANIZATIONS } from 'apollo/queries'
import {
  OrganizationEntity,
  OrganizationEntityResponseCollection,
} from 'types/models/organization'

import * as S from './styled'

type TFilter = {
  regions: Set<string>
  areas: Set<string>
}

type TQuery = TData<OrganizationEntityResponseCollection, 'organizations'>

const Organizations = () => {
  const [filter, setFilter] = useState<TFilter>({
    regions: new Set(),
    areas: new Set(),
  })

  const { data } = useQuery<TQuery>(GET_ORGANIZATIONS, {
    variables: {
      filters: {
        ...(!!filter.regions.size && {
          region: { in: Array.from(filter.regions) },
        }),
        ...(!!filter.areas.size && {
          areas: { id: { in: Array.from(filter.areas) } },
        }),
      },
    },
  })

  const renderItems = useCallback(
    ({ attributes: item }: OrganizationEntity) => (
      <Organization key={`${item?.slug}-organization`} entity={item!} />
    ),
    [data]
  )

  return (
    <>
      <Header />

      <Section bg="secondaryBackground">
        <S.Top>
          <Filter inverted values={filter} setValues={setFilter} />
        </S.Top>

        <Grid>{data?.organizations?.data?.map(renderItems)}</Grid>
      </Section>
    </>
  )
}

export default React.memo(Organizations)
