import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { ArticleItem, Header } from 'components'
import { Section } from 'ui'

import { GET_ARTICLES } from 'apollo/queries'
import {
  ArticleRelationResponseCollection,
  ArticleEntity
} from 'types/models/article'

type TQuery = TData<ArticleRelationResponseCollection, 'articles'>

const Articles = () => {
  const { data } = useQuery<TQuery>(GET_ARTICLES)

  const renderItems = useCallback(
    ({ attributes: item }: ArticleEntity, idx: number) =>
      !item ? null : (
        <ArticleItem
          key={`${item.slug}-article`}
          title={item.title}
          description={item.description}
          coverImage={item.coverImage}
          path={`/inspiracia/clanky/${item.slug}`}
          flipped={!(idx % 2)}
        />
      ),
    []
  )

  return (
    <>
      <Header />

      <Section col gap="xxxl">
        {data?.articles?.data?.map(renderItems)}
      </Section>
    </>
  )
}

export default React.memo(Articles)
