import * as Yup from 'yup'

// https://regex101.com/r/wZ4uU6/1
const phoneRegex =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
// https://emailregex.com/
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default Yup.object().shape({
  name: Yup.string().required('Toto pole je povinné'),
  place: Yup.string().required('Toto pole je povinné'),
  eventDate: Yup.string().required('Toto pole je povinné'),
  description: Yup.string().required('Toto pole je povinné'),
  specifics: Yup.string(),
  volunteersAmount: Yup.number().required('Toto pole je povinné'),
  registrationEndDate: Yup.string().required('Toto pole je povinné'),
  organization: Yup.string().required('Toto pole je povinné'),
  contactEmail: Yup.string()
    .matches(emailRegex, 'Nesprávny formát')
    .required('Toto pole je povinné'),
  contactPhone: Yup.string().matches(phoneRegex, 'Nesprávny formát')
})
