import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'

import { Loading } from '../sections'
import { useContext } from 'hooks'
import { fromLocation } from 'utils/functions'

const PreventAuth = (Component: React.ComponentType) => {
  const Wrapper = () => {
    const location = useLocation()

    const { isLoading, isAuthenticated } = useContext('auth')

    if (isLoading) return <Loading />

    return !isAuthenticated ? (
      <Component />
    ) : (
      <Navigate
        to={fromLocation(location)}
        state={{ from: location }}
        replace
      />
    )
  }

  return <Wrapper />
}

export default PreventAuth
