import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Item } from 'components'
import { Grid, Section, Text } from 'ui'
import { EventEntity, TEventEntityWithLocation } from 'types/models/event'
import { Map, TMapMarkerData } from 'components'
import { HomepageEvent } from 'types/models/homepage'

type TProps = {
  collection?: EventEntity[]
  mapEvents?: HomepageEvent[]
}

const News = ({ collection, mapEvents }: TProps) => {
  const navigate = useNavigate()

  const renderEvents = useCallback(
    ({ attributes: item }: EventEntity) => (
      <Item key={`${item?.category}-${item?.slug}`} data={item!} />
    ),
    [collection]
  )

  const markers: TMapMarkerData[] = useMemo(() => {
    return (
      mapEvents
        ?.filter(
          (item): item is TEventEntityWithLocation =>
            !!item.attributes?.location
        )
        .map((item) => ({
          name: item.attributes.title,
          slug: item.attributes.slug,
          location: JSON.parse(item.attributes.location),
        })) || []
    )
  }, [mapEvents])

  const onMarkerClick = useCallback((marker: TMapMarkerData) => {
    navigate(`/pre-dobrovolnikov/dobrovolnicke-prilezitosti/${marker.slug}`)
  }, [])

  return (
    <>
      <Section hideIf={!collection?.length}>
        <Text
          as="h2"
          variant="heading2"
          color="salmon"
          align="center"
          mb="xxl"
          uppercase
        >
          Novinky a najnovšie príležitosti
        </Text>

        <Grid>{collection?.map(renderEvents)}</Grid>
      </Section>

      {!!markers.length && <Map {...{ markers, onMarkerClick }} />}
    </>
  )
}

export default React.memo(News)
