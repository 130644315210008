import { gql } from '@apollo/client'

export default gql`
  query getDocuments {
    documents {
      data {
        id
        attributes {
          file {
            data {
              attributes {
                name
                size
                url
              }
            }
          }
        }
      }
    }
  }
`
